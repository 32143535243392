import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';



const Marquee = () => {

    const isMdUp = useResponsive('up', 'lg');
    const marqueeRef = useRef(null);
    const [textWidth, setTextWidth] = useState(100); // Initial text width, in pixels



    const MarqueeRoot = styled(Box)({
        width: '100%',
        height: isMdUp ?  "50px" :"30px" ,
        background: 'yellow',
        // padding: '20px 0px',
        // fontSize: '50px',
        // fontWeight: '700',
        overflow: 'hidden',
        fontFamily: "Barlow, sans-serif",
        position: 'relative',

        alignItems: 'center',
        display: 'flex'
    });

    const MarqueeContent = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        whiteSpace: 'nowrap',
        position: 'absolute',
        left: 0,
        animation: 'marqueeScroll 25s linear infinite',
        '@keyframes marqueeScroll': {
            '0%': { transform: 'translateX(0)' },
            '100%': { transform: 'translateX(-100%)' },
        },
    });

    const MarqueeText = styled(Typography)({
        textTransform: 'uppercase',
        paddingRight: '32px',
        display: "flex",
        flexDirection: "row",
        fontSize: isMdUp ? "25px": "15px",
        fontWeight: '700',

    });

    const MarqueeCopies = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
        width: '0',
        flexGrow: 0,
        flexShrink: 0,
        position: 'absolute', // Position absolute for scrolling effect
        top: 0,
        left: '100%', // Position copies initially outside visible area
    });

    const RedWord = styled(Box)({
        color: "#F42E17",
        fontWeight: "700",
    })

    useEffect(() => {
        setMarqueeContents(); // Initialize on component mount
        window.addEventListener('resize', setMarqueeContents); // Update on window resize
        return () => {
            window.removeEventListener('resize', setMarqueeContents); // Clean up event listener
        };
    }, []);

    const setMarqueeContents = () => {
        const marqueeElement = marqueeRef.current;
        if (!marqueeElement) return; // Check if ref is initialized

        const textElement = marqueeElement.querySelector('.marquee__text');
        if (!textElement) return; // Check if text element is found

        const bannerWidth = marqueeElement.offsetWidth;
        const currentTextWidth = textElement.offsetWidth;
        const numberOfCopies = Math.ceil(bannerWidth / currentTextWidth) + 1;
        setTextWidth(currentTextWidth);

        const copiesContainer = marqueeElement.querySelector('.marquee__copies');
        if (!copiesContainer) return; // Check if copies container is found
        copiesContainer.innerHTML = '';

        for (let i = 0; i < numberOfCopies; i += 1) {
            const clone = textElement.cloneNode(true);
            copiesContainer.appendChild(clone);
        }
    };

    return (
        <MarqueeRoot ref={marqueeRef}
        >
            <MarqueeContent>
                <MarqueeText variant="body1" className="marquee__text">
                    ⚠&nbsp;<RedWord>请注意!!!</RedWord>&nbsp;请家长记得更新孩子的班级&nbsp;<RedWord>Attention!!!</RedWord>&nbsp;Do remind parents to update your children&lsquo;s classroom
                </MarqueeText>
                <MarqueeCopies className="marquee__copies" aria-hidden="true" />
            </MarqueeContent>
        </MarqueeRoot>
    );
};

export default Marquee;
