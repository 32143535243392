import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Box,
  Stack,
  Button,
  Divider,
  Typography,
  Unstable_Grid2 as Grid,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField, RHFSelect, RHFRadioGroup } from 'src/components/hook-form';

import { EcommerceCheckoutOrderSummary } from '../checkout';

import { EcommerceFilterDataCustomize } from '../product/filters/components';// auth
import { useAuthContext } from '../../../auth/useAuthContext';

// ----------------------------------------------------------------------

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// ----------------------------------------------------------------------

export default function EcommerceGuestInformation({
  handlePrevious,
  handleNext,
  cartProduct,
  summary,
  country,
  cartListing,
  orderID,
  extraRequestOption,
  cartType,
  handleCheckPromo,
  handlePromoCode,
  promoCode,
  validPromoData,
  setHotelGuestData,
  hotelGuestData,
  setHotelInfoDialog,
  isReload
}) {
  const cookies = new Cookies();
  const { user } = useAuthContext();

  const [openTermDialog, setOpenTermDialog] = useState(false);
  const [countryID, setCountryID] = useState(148);
  const [bookForOthers, setbookForOthers] = useState(false);
  const [checkedMalaysianItem, setCheckedMalaysianItem] = useState(false);
  const [moreRequest, setMoreRequest] = useState(false);
  const [otherRequest, setOtherRequest] = useState([]);

  const EcommerceGuestInformationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .required('Email address is required')
      .email('Email address must be a valid email address'),
    contact: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone number is required')
      .min(10, 'Phone number must be at least 10 digits'),
    countryID: Yup.number().required('Country is required'),
  });

  const EcommerceNonGuestInformationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .required('Email address is required')
      .email('Email address must be a valid email address'),
    contact: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone number is required')
      .min(10, 'Phone number must be at least 10 digits'),
    countryID: Yup.number().required('Country is required'),

    guestFirstName: Yup.string().required('Guest First name is required'),
    guestLastName: Yup.string().required('Guest Last name is required'),
    guestCountry: Yup.number().required('Guest Country is required'),
    guestContact: Yup.string('Guest Phone number is required').required(
      'Phone number must be a valid number'
    ),
  });

  const defaultValues = {
    firstName: hotelGuestData?.firstName || '',
    lastName: hotelGuestData?.lastName || '',
    email: hotelGuestData?.email || '',
    contact: hotelGuestData?.contact || '',
    countryID: hotelGuestData?.countryID || 148,

    guestContact: hotelGuestData?.guestContact || '',
    guestFirstName: hotelGuestData?.guestFirstName || '',
    guestLastName: hotelGuestData?.guestLastName || '',
    guestCountry: hotelGuestData?.guestCountry || 148,
    smokingPreference: hotelGuestData?.smokingPreference || '',
    bedPreference: hotelGuestData?.bedPreference || '',
    personalRequest: hotelGuestData?.personalRequest || '',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (hotelGuestData) {
      setCountryID(hotelGuestData.countryID);
      setbookForOthers(hotelGuestData.bookForOthersPurpose);
      setCheckedMalaysianItem(hotelGuestData.isMalaysian);
      setMoreRequest(hotelGuestData.requiredMoreRequest);
      setOtherRequest(hotelGuestData.extraRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelGuestData]);

  const methods = useForm({
    resolver: yupResolver(
      bookForOthers === false ? EcommerceGuestInformationSchema : EcommerceNonGuestInformationSchema
    ),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    cookies.set('firstName', data.firstName);
    cookies.set('lastName', data.lastName);
    cookies.set('contact', data.contact);
    cookies.set('email', data.email);
    cookies.set('countryID', data.countryID);

    localStorage.setItem('cartToOrderInfo', JSON.stringify({...data, userId: user?.UserID}));

    setHotelGuestData({
      firstName: data.firstName,
      lastName: data.lastName,
      contact: data.contact,
      email: data.email,
      countryID: data.countryID,
      bookForOthersPurpose: bookForOthers,
      guestContact: data.guestContact,
      guestFirstName: data.guestFirstName,
      guestLastName: data.guestLastName,
      guestCountry: data.guestCountry,
      smokingPreference: data.smokingPreference,
      bedPreference: data.bedPreference,
      requiredMoreRequest: moreRequest,
      extraRequest: otherRequest,
      personalRequest: data.personalRequest,
      isMalaysian: checkedMalaysianItem,
    });
    handleNext();
  };

  const getSelected = (selectedItems, item) => {
    let returnData = [];
    if (selectedItems.filter((x) => x.value === item.value).length > 0)
      returnData = selectedItems.filter((data) => data.value !== item.value);
    else returnData = [...selectedItems, item];
    return returnData;
  };

  const handleChangeRequest = (name) => {
    setOtherRequest(getSelected(otherRequest, name));
  };

  const malaysianConfirmation = () => (
    <Stack direction="row" spacing={2}>
      <Checkbox
        name="checkbox"
        disabled={orderID !== ''}
        checked={checkedMalaysianItem}
        onChange={(e, value) => setCheckedMalaysianItem(value)}
      />
      <Typography variant="subtitle2" pt={1}>
        {' '}
        I confirm that all guest are nationals or permanent residents of Malaysia and qualify for{' '}
        <Typography
          component="span"
          fontWeight="bold"
          color="darkblue"
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => setOpenTermDialog(true)}
        >
          Malaysia tourism tax
        </Typography>{' '}
        exemption
      </Typography>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={{ xs: 5, md: 8 }}>
        <Grid xs={12} md={8}>
          <Stack spacing={5} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
            <div>
              <Button
                onClick={handlePrevious}
                color="inherit"
                startIcon={<Iconify icon="carbon:chevron-left" />}
                sx={{ mb: 3 }}
              >
                Back to Cart
              </Button>
              <StepLabel title="Customer Information" step="1" />
              <Box
                rowGap={2.5}
                columnGap={2}
                mb={3}
                display="grid"
                gridTemplateColumns={{ xs: 'repeat(1, 1fr)' }}
              >
                <RHFTextField name="firstName" label="First Name *" disabled={orderID !== ''} />

                <RHFTextField name="lastName" label="Last Name *" disabled={orderID !== ''} />

                <RHFTextField name="email" label="Email Address *" disabled={orderID !== ''} />
              </Box>

              <Box
                rowGap={2.5}
                columnGap={2}
                mb={3}
                display="grid"
                gridTemplateColumns={{ xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
              >
                <RHFTextField name="contact" label="Phone Number *" disabled={orderID !== ''} />

                <RHFSelect
                  native
                  name="countryID"
                  label="Country *"
                  disabled={orderID !== ''}
                  onChange={(e, value) => {
                    setCountryID(Number(e.target.value));
                    setValue('guestCountry', e.target.value);
                  }}
                >
                  {country &&
                    country.map((option) => (
                      <option key={option.CountryId} value={option.CountryId}>
                        {option.CountryName}
                      </option>
                    ))}
                </RHFSelect>
              </Box>

              {cartType === 'hotelStep' && (
                <>
                  {countryID === 148 && bookForOthers === false && malaysianConfirmation()}
                  {!(orderID !== '' && !bookForOthers) && (
                    <Stack direction="row" spacing={2} pb={1}>
                      <Checkbox
                        name="checkbox"
                        checked={bookForOthers}
                        onChange={(e, value) => setbookForOthers(value)}
                        disabled={orderID !== ''}
                      />
                      <Typography variant="subtitle2" pt={1}>
                        {' '}
                        Make this booking for someone else{' '}
                      </Typography>
                    </Stack>
                  )}

                  {bookForOthers === true && (
                    <Stack mb={2} sx={{ backgroundColor: '#f9f9f9', borderRadius: '20px' }}>
                      <Typography variant="subtitle2" mb={1} mt={1}>
                        {' '}
                        Guest Infomation{' '}
                      </Typography>
                      <Box
                        rowGap={2.5}
                        columnGap={2}
                        mb={3}
                        display="grid"
                        gridTemplateColumns={{ xs: 'repeat(1, 1fr)' }}
                      >
                        <Typography variant="subtitle2">First Name</Typography>
                        <RHFTextField
                          name="guestFirstName"
                          label="First Name *"
                          disabled={orderID !== ''}
                        />

                        <Typography variant="subtitle2">Last Name</Typography>
                        <RHFTextField
                          name="guestLastName"
                          label="Last Name *"
                          disabled={orderID !== ''}
                        />

                        <RHFTextField
                          name="guestContact"
                          label="Contact *"
                          disabled={orderID !== ''}
                        />

                        <RHFSelect
                          native
                          name="guestCountry"
                          label="Country *"
                          disabled={orderID !== ''}
                          onChange={(e, value) => {
                            setCountryID(Number(e.target.value));
                            setValue('guestCountry', e.target.value);
                          }}
                        >
                          {country &&
                            country.map((option) => (
                              <option key={option.CountryId} value={option.CountryId}>
                                {option.CountryName}
                              </option>
                            ))}
                        </RHFSelect>
                      </Box>
                      {countryID === 148 && malaysianConfirmation()}
                    </Stack>
                  )}
                </>
              )}
            </div>
          </Stack>
          {cartType === 'hotelStep' && (
            <Stack pt={2} spacing={1} sx={{ borderTop: '1px dashed lightgrey' }}>
              <StepLabel title="Customer Special Request" step="2" />
              <Typography variant="subtitle">Let us know more what you need</Typography>
              <Typography variant="subtitle2">
                All requests are subject to resort availability.
              </Typography>

              <Stack p={2} sx={{ backgroundColor: '#f9f9f9', borderRadius: '20px' }}>
                <Box
                  rowGap={2.5}
                  columnGap={2}
                  mb={3}
                  display="grid"
                  gridTemplateColumns={{ xs: 'repeat(1, 1fr)' }}
                >
                  <RHFRadioGroup
                    isDisabled={orderID !== ''}
                    row
                    name="smokingPreference"
                    label="Do you have a smoking preference? "
                    spacing={10}
                    options={[
                      {
                        value: 'Non-Smoking',
                        label: (
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Iconify icon="ion:logo-no-smoking" ml={1} mr={1} />{' '}
                            <Typography variant="subtitle2"> Non-Smoking</Typography>{' '}
                          </Stack>
                        ),
                      },
                      {
                        value: 'Smoking',
                        label: (
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Iconify icon="ic:baseline-smoking-rooms" ml={1} mr={1} />{' '}
                            <Typography variant="subtitle2"> Smoking</Typography>{' '}
                          </Stack>
                        ),
                      },
                    ]}
                  />

                  <RHFRadioGroup
                    isDisabled={orderID !== ''}
                    row
                    name="bedPreference"
                    label="What bed configuration do you prefer? "
                    spacing={10}
                    options={[
                      {
                        value: 'Non-Smoking Room',
                        label: (
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Iconify icon="fluent:bed-24-regular" ml={1} mr={1} />{' '}
                            <Typography variant="subtitle2"> I d like a large bed</Typography>{' '}
                          </Stack>
                        ),
                      },
                      {
                        value: 'Smoking Room',
                        label: (
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Iconify icon="material-symbols:bed-outline" ml={1} mr={1} />{' '}
                            <Typography variant="subtitle2"> I d like a twin beds</Typography>{' '}
                          </Stack>
                        ),
                      },
                    ]}
                  />
                </Box>
              </Stack>

              <Stack direction="row" spacing={2}>
                {moreRequest === false ? (
                  <Iconify
                    icon="gg:add"
                    onClick={() => (orderID !== '' ? '' : setMoreRequest(true))}
                  />
                ) : (
                  <Iconify
                    icon="zondicons:minus-outline"
                    onClick={() => (orderID !== '' ? '' : setMoreRequest(false))}
                  />
                )}{' '}
                <Typography variant="subtitle2">
                  {' '}
                  Click here if you would like to have more request{' '}
                </Typography>
              </Stack>


              {moreRequest === true && (
                <Stack p={2} sx={{ border: '1px solid #f9f9f9', borderRadius: '20px' }}>
                  <Box
                    rowGap={2.5}
                    columnGap={2}
                    mb={3}
                    display="grid"
                    gridTemplateColumns={{ xs: 'repeat(1, 1fr)' }}
                  >
                    <EcommerceFilterDataCustomize
                      isDisabled={orderID !== ''}
                      filterData={otherRequest}
                      onChangeData={handleChangeRequest}
                      options={extraRequestOption}
                      sx={{ mt: 1 }}
                    />
                    <Typography variant="subtitle2">
                      Any Personal Requests? Let us know more about it
                    </Typography>
                    <RHFTextField
                      name="personalRequest"
                      label=""
                      multiline
                      rows={3}
                      disabled={orderID !== ''}
                    />
                  </Box>
                </Stack>
              )}
            </Stack>
          )}
        </Grid>

        <Grid xs={12} md={4}>
          <EcommerceCheckoutOrderSummary
            // tax={summary.tax === 0 ? 0 : summary.tax}

            tax={summary.tax === 0 ? 0 : summary.tax}
            total={summary.subtotal + summary.shipping - summary.discount + summary.tax}
            subtotal={summary.subtotal === 0 ? 0 : summary.subtotal}
            shipping={summary.shipping === 0 ? 0 : summary.shipping}
            discount={summary.discount === 0 ? 0 : summary.discount}
            handleNext={handleNext}
            products={cartProduct}
            cartListing={cartListing}
            loading={isSubmitting}
            pageType="customer"
            promoCode={promoCode}
            handleCheckPromo={handleCheckPromo}
            handlePromoCode={handlePromoCode}
            validPromoData={validPromoData}
            orderID={orderID}
            setHotelInfoDialog={setHotelInfoDialog}
            isReload={isReload}
          />
        </Grid>
      </Grid>

      <Dialog open={openTermDialog}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogTitle>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ color: 'text.secondary' }}
            >
              <Typography variant="h4"> Tourism tax </Typography>
              <Tooltip title="Close">
                <Button
                  endIcon={<Iconify icon="carbon:close-filled" />}
                  // sx={{ m: 1 }}
                  onClick={() => setOpenTermDialog(false)}
                />
              </Tooltip>
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ padding: 3 }}>
            <Box>
              <Typography sx={{ textAlign: 'justify' }}>
                The Malaysian Government has reinstated the Tourism Tax (TTX) of RM10.00 per room
                per night for all foreign passport holders for hotel stays effective from 1st
                January 2023. Malaysian nationals and permanent residents of Malaysia will continue
                to enjoy exemptions from Tourism tax, under Exemption Orders. With effect on 1st
                July 2022, Local Government Fee (Penang) of RM3 will be charged per room per night.
                The fee is not included in the room rate and is payable at the reception desk upon
                check-in.
              </Typography>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </FormProvider>
  );
}

EcommerceGuestInformation.propTypes = {
  cartProduct: PropTypes.array,
  cartListing: PropTypes.array,
  handlePrevious: PropTypes.func,
  handleNext: PropTypes.func,
  country: PropTypes.array,
  summary: PropTypes.object,
  handlePromoCode: PropTypes.func,
  handleCheckPromo: PropTypes.func,
  promoCode: PropTypes.string,
  validPromoData: PropTypes.object,
  orderID: PropTypes.string,
  setHotelGuestData: PropTypes.func,
  hotelGuestData: PropTypes.object,
  setHotelInfoDialog: PropTypes.func,
  extraRequestOption: PropTypes.array,
  cartType: PropTypes.string,
  isReload: PropTypes.bool,
};

// ----------------------------------------------------------------------

function StepLabel({ step, title }) {
  return (
    <Stack direction="row" alignItems="center" sx={{ mb: 3, typography: 'h6' }}>
      <Box
        sx={{
          mr: 1.5,
          width: 28,
          height: 28,
          flexShrink: 0,
          display: 'flex',
          typography: 'h6',
          borderRadius: '50%',
          alignItems: 'center',
          bgcolor: 'primary.main',
          justifyContent: 'center',
          color: 'primary.contrastText',
        }}
      >
        {step}
      </Box>
      {title}
    </Stack>
  );
}

StepLabel.propTypes = {
  step: PropTypes.string,
  title: PropTypes.string,
};
