import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Avatar, Divider, FormControl, Grid, MenuItem, Select, Stack, Typography } from '@mui/material';

// hooks
import useResponsive from 'src/hooks/useResponsive';
// paths
import { paths } from 'src/routes/paths';
// components
//
import ReactHorizontalDatePicker from "./ReactHorizontalDatePicker";
import './ReactHorizontalDatePicker.css';
// redux
import { setChildData, setUserCheckInDate } from '../../../../redux/slices/general';
import { clearProduct, getAvailability } from '../../../../redux/slices/product';
import { useDispatch, useSelector } from '../../../../redux/store';
// auth
import { useAuthContext } from '../../../../auth/useAuthContext';
// ----------------------------------------------------------------------

export default function ProductFilter({ sx, pullProductData, onSelectedDay, combinedDates, selectedChildData, ChildrenData, staticTop = 10, ...other }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useResponsive('up', 'md');
  const { user } = useAuthContext();
  // const { childProfile } = useSelector((data) => data.user);
  const { availability } = useSelector((data) => data.product);
  const { userCheckInDate, childData } = useSelector((data) => data.general);
  const [isSticky, setIsSticky] = useState(false);

  const sticky = isSticky && isDesktop;

  // const handleScroll = () => {
  //   if (window.scrollY > staticTop) {
  //     setIsSticky(true);
  //   } else {
  //     setIsSticky(false);
  //   }
  // };

  // const onSelectedDay = (date) => {
  //   // console.log(date)
  //   dispatch(setUserCheckInDate(date))
  //   dispatch(clearProduct())
  //   dispatch(getAvailability(
  //     moment(combinedDates).format('YYYY-MM-DD'),
  //     moment(combinedDates).format('YYYY-MM-DD'),
  //     0))
  // };

  const handlePullAvailability = (checkIn, checkOut) => {
    dispatch(getAvailability(
      moment(checkIn).format('YYYY-MM-DD'),
      moment(checkOut).format('YYYY-MM-DD'),
      0))
  }

  const handleChangeChild = (data) => {
    dispatch(setChildData(data.target.value))
    navigate(paths.eCommerce.products("Category", 0))
  };

  const checkDateDiff = () => {
    const currentDate = new Date(userCheckInDate) || new Date()
    const todayDate = new Date()

    // Calculate the difference in time
    const timeDifference = Math.abs(currentDate - todayDate);

    // Convert time difference from milliseconds to days
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return dayDifference - 1;
  }

  return (
    <Stack
      spacing={1.5}
      direction='column'
      sx={{
        borderRadius: 2,
        boxShadow: sticky ? '6' : 'none',
        padding: "!import0",
        ...sx,
      }}
      className={sticky ? 'is-sticky' : 'sidebar'}
      {...other}
    >
      <ReactHorizontalDatePicker
        selectedDay={onSelectedDay}
        currentSelected={new Date(userCheckInDate) || new Date()}
        enableScroll
        enableDays={50}
        enableDaysBefore={checkDateDiff()}
        availability={availability}
        pullProductData={pullProductData}
      />
      <Divider />

      {
        user &&
        // <Stack direction="row" justifyContent={{ xs: "space-between", md: "flex-end" }} alignItems="center" spacing={1} p={1}>
        <Grid container sx={{ justifyContent: isDesktop ? "flex-end" : "space-between", alignItems: "center", padding: 1 }}>
          <Grid item xs={5} md={isDesktop && 8} sx={{ display: "flex", justifyContent: isDesktop && "flex-end" }}>
            <Typography variant='subtitle2' >Child Name 学生名字 : </Typography>
          </Grid>
          <Grid item xs={7} md={isDesktop && 4}>
            <FormControl size="small" hiddenLabel variant="filled" fullWidth>
              <Select
                value={childData}
                onChange={handleChangeChild}
                MenuProps={{
                  PaperProps: {
                    sx: { px: 1 },
                  },
                }}
              >
                {ChildrenData.map((option) => (
                  <MenuItem key={option.ChildID} value={option.ChildID}>
                    <Stack direction='row' >
                      <Avatar src={option?.ChildProfileImage} />
                      <Stack direction='column' justifyContent="center" pl={3} >
                        <Typography variant='subtitle2'>{option.ChildName}</Typography>
                        <Typography variant='subtitle2'>{option.ClassName}</Typography>
                      </Stack>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        // </Stack>
      }
    </Stack>
  );
}

ProductFilter.propTypes = {
  sx: PropTypes.object,
  staticTop: PropTypes.number,
  pullProductData: PropTypes.func,
  onSelectedDay: PropTypes.func,
  selectedChildData: PropTypes.any,
  ChildrenData: PropTypes.array,
  combinedDates: PropTypes.object
};
