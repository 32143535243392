import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, Button, Divider } from '@mui/material';
import { alpha } from '@mui/material/styles';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// paths
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
//
import { TravelFilterTime, TravelFilterGuests, TravelFilterLocation } from './components';

import { useDispatch } from '../../../../redux/store';
import { setUserCheckInDate, setUserCheckOutDate } from '../../../../redux/slices/general';
// ----------------------------------------------------------------------

export default function TravelFilters({ sx, onPassData, staticTop = 100, ...other }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isDesktop = useResponsive('up', 'md');

  const [checkInDate, setCheckInDate] = useState(new Date());

  const [checkOutDate, setCheckOutDate] = useState(null);

  const [selectedCity, setSelectedCity] = useState({
    City: 'Bau',
    CityAlias: 'SRW',
  });

  const [guests, setGuests] = useState({
    adults: 2,
    children: 0,
  });

  const [isSticky, setIsSticky] = useState(false);

  const sticky = isSticky && isDesktop;

  const handleScroll = () => {
    if (window.scrollY > staticTop) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(
    () => {
      window.addEventListener('scroll', handleScroll);

      const newDate = new Date(checkInDate);
      newDate.setDate(checkInDate.getDate() + 1);
      setCheckOutDate(newDate);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (checkInDate) dispatch(setUserCheckInDate(checkInDate));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkInDate]
  );

  // useEffect(
  //   () => {
  //     if (checkOutDate) dispatch(setUserCheckOutDate(checkOutDate));
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [checkOutDate]
  // );

  useEffect(() => {
    if (onPassData) {
      const { city, checkIn, checkOut, guest } = onPassData;
      const date = new Date();
      const today = new Date(date);
      const nextDay = date.setDate(today.getDate() + 1);

      setSelectedCity(city || null);
      setCheckInDate(checkIn || today);
      setCheckOutDate(checkOut || nextDay);

      setGuests({
        adults: guest?.adults ? guest?.adults : 2,
        children: guest?.children ? guest?.children : 0,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPassData]);

  const handleChangeDepartureDay = (newValue) => {
    if (newValue >= checkOutDate) {
      const newDate = new Date(newValue);
      newDate.setDate(newValue.getDate() + 1);
      setCheckOutDate(newDate);
    }
    setCheckInDate(newValue);
  };

  const handleChangeCheckOutDate = (newValue) => {
    if (newValue <= checkInDate) {
      const newDate = new Date(newValue);
      newDate.setDate(newValue.getDate() - 1);
      setCheckInDate(newDate);
    }
    setCheckOutDate(newValue);
  };

  const handleChangeCity = (e, newValue) => {
    setSelectedCity(newValue);
  };

  const handleIncrementGuests = (guest) => {
    if (guest === 'children') {
      setGuests({ ...guests, children: guests.children + 1 });
    } else {
      setGuests({ ...guests, adults: guests.adults + 1 });
    }
  };

  const handleDecreaseGuests = (guest) => {
    if (guest === 'children') {
      setGuests({ ...guests, children: guests.children - 1 });
    } else {
      setGuests({ ...guests, adults: guests.adults - 1 });
    }
  };

  const handleNavigate = (city, checkIn, checkOut, guest) => {
    navigate(paths.hotel.list(78), { state: { city, checkIn, checkOut, guest } });
  };

  return (
    <Stack
      spacing={2.5}
      alignItems={{ md: 'center' }}
      direction={{ xs: 'column', md: 'row' }}
      mt={{ xs: 5, sm: 5, md: 0}}
      sx={{
        p: 2,
        borderRadius: 2,
        boxShadow: sticky ? '6' : 'none',
        ...sx,
      }}
      className={sticky ? 'is-sticky' : 'sidebar'}
      {...other}
    >
      {/* <TravelFilterLocation onChangeCity={handleChangeCity} currectSelectedCity={selectedCity} /> */}

      {/* <Divider flexItem orientation="vertical" /> */}

      <TravelFilterTime
        checkInDate={checkInDate}
        onChangeDepartureDay={handleChangeDepartureDay}
        checkOutDate={checkOutDate}
        onChangeCheckOutDate={handleChangeCheckOutDate}

      />

      <Divider flexItem orientation="vertical" />

      <TravelFilterGuests
        guests={guests}
        onDecreaseGuests={handleDecreaseGuests}
        onIncrementGuests={handleIncrementGuests}
      />

      <Button
        size="large"
        color="primary"
        variant="contained"
        sx={{
          px: 0,
          flexShrink: 0,
          minWidth: { xs: 1, md: 48 },
        }}
        onClick={() => handleNavigate(selectedCity, checkInDate, checkOutDate, guests)}
      >
        <Iconify icon="carbon:search" />
      </Button>
    </Stack>
  );
}

TravelFilters.propTypes = {
  sx: PropTypes.object,
  onPassData: PropTypes.object,
  staticTop: PropTypes.number,
};
