import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// @mui
import { LoadingButton } from '@mui/lab';
// @mui
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, Link, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles'; // form
import { useForm } from 'react-hook-form';
// hooks
import useActiveLink from 'src/hooks/useActiveLink';
import useResponsive from 'src/hooks/useResponsive';
// config
import { NAV } from 'src/config-global';
// routes
import Notification from 'src/components/Notification/Notification';
import { paths } from 'src/routes/paths';
// _mock
import _mock from 'src/_mock';
// components
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
// redux
import { setChildData } from 'src/redux/slices/general';
import { useDispatch, useSelector } from '../../../../redux/store';
// utils
import { fData } from '../../../../utils/formatNumber';
import { uploader } from '../../../../utils/helpers';
// auth
import { useAuthContext } from '../../../../auth/useAuthContext';
import { updateUserProfileImage } from '../../../../redux/slices/profile';

import FormProvider, {
  RHFUploadAvatar
} from '../../../../components/hook-form';

// ----------------------------------------------------------------------

const navigations = [
  {
    title: 'Personal Info',
    path: paths.eCommerce.account.personal,
    icon: <Iconify icon="carbon:user" />,
  },
  {
    title: 'Manage Children',
    path: paths.eCommerce.account.children,
    icon: <Iconify icon="carbon:pedestrian-child" />,
  },
  // {
  //   title: 'Addresses Book',
  //   path: paths.eCommerce.account.address,
  //   icon: <Iconify icon="carbon:user" />,
  // },
  // {
  //   title: 'Wishlist',
  //   path: paths.eCommerce.account.wishlist,
  //   icon: <Iconify icon="carbon:favorite" />,
  // },
  // {
  //   title: 'Vouchers',
  //   path: paths.eCommerce.account.vouchers,
  //   icon: <Iconify icon="carbon:cut-out" />,
  // },
  {
    title: 'Orders',
    path: paths.eCommerce.account.orders,
    icon: <Iconify icon="carbon:document" />,
  },
  // {
  //   title: 'Payment',
  //   path: paths.eCommerce.account.payment,
  //   icon: <Iconify icon="carbon:purchase" />,
  // },
];

// ----------------------------------------------------------------------

export default function EcommerceAccountMenu({ open, onClose }) {

  const { user, logout } = useAuthContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMdUp = useResponsive('up', 'md');

  const { userProfile, isLoading, profileImageAction } = useSelector((state) => state.profile);
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadedImage, setUploadImage] = useState([]);
  const [notification, setNotification] = useState({ open: false, msg: "", type: "success", });

  const UserSchema = Yup.object().shape({
    userprofile: Yup.string().required('Profile Image is required to upload'),
  });

  const defaultValues = useMemo(() => ({
    userprofile: userProfile?.UserProfileImage || '',
  }), [userProfile]);

  const methods = useForm({
    resolver: yupResolver(UserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const handleLogout = async () => {
    try {
      const resp = await logout(user?.UserID)
      if (resp === false) {
        setNotification({ open: true, msg: "There is error to log out, please try again later", type: "error" })
      } else {
        setChildData(null)
        navigate("/")
      }
    } catch (error) {
      console.error(error);
    }

  }


  useEffect(() => {
    if (userProfile) {
      setValue("userProfile", userProfile.UserProfileImage)

      setOpenDialog(false)
      setUploadImage([])
    }


  }, [userProfile, setValue]);

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setValue("userProfile", userProfile?.UserProfileImage || '')
    setOpenDialog(false)
  }

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setUploadImage(acceptedFiles)
        setValue('userprofile', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const renderContent = (
    <Stack
      sx={{
        flexShrink: 0,
        borderRadius: 2,
        width: 1,
        ...(isMdUp && {
          width: NAV.W_DRAWER,
          border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
        }),
      }}
    >
      <Stack spacing={2} sx={{ p: 3, pb: 2 }}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Avatar src={userProfile?.UserProfileImage || _mock.image.avatar(0)} sx={{ width: 64, height: 64 }} />
          <Stack
            onClick={handleOpenDialog}
            direction="row"
            alignItems="center"
            sx={{ typography: 'caption', cursor: 'pointer', '&:hover': { opacity: 0.72 } }}
          >
            <Iconify icon="carbon:edit" sx={{ mr: 1 }} />
            Change photo
          </Stack>
        </Stack>

        <Stack spacing={0.5}>
          <TextMaxLine variant="subtitle1" line={1}>
            {user?.Username}
          </TextMaxLine>
          {/* <TextMaxLine variant="body2" line={1} sx={{ color: 'text.secondary' }}>
            {user?.UserEmailAddress}
          </TextMaxLine> */}
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Stack sx={{ my: 1, px: 2 }}>
        {navigations.map((item) => (
          <MenuItem key={item.title} item={item} />
        ))}
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Stack sx={{ my: 1, px: 2 }}>
        <ListItemButton
          sx={{
            px: 1,
            height: 44,
            borderRadius: 1,
          }}
        >
          <ListItemIcon>
            <Iconify icon="carbon:logout" />
          </ListItemIcon>
          <ListItemText
            onClick={() => handleLogout()}
            primary="Logout"
            primaryTypographyProps={{
              typography: 'body2',
            }}
          />
        </ListItemButton>
      </Stack>
    </Stack>
  );

  const onSubmit = async (data) => {
    try {
      const files = uploadedImage;
      let image = '';
      if (files.length > 0) {
        files.map(async (file) => {
          const filename = new Date().valueOf();
          const extension = file.name.split('.').pop();
          const newFilename = `${filename}.${extension}`;
          image = newFilename;
          const res = await uploader([file], [newFilename], 'userProfile', user?.UserID)
          if (res === true) {
            dispatch(updateUserProfileImage(user?.UserID, 'PROFILEIMAGE', image));
            setNotification({ open: true, msg: "Successfully upload profile Image!", type: "success" })
          } else {
            setNotification({ open: true, msg: "Please upload new image to upload", type: "error" })
          }
        })
      }
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      {isMdUp ? (
        renderContent
      ) : (
        <Drawer
          open={open}
          onClose={onClose}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              width: NAV.W_DRAWER,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      <Dialog fullWidth maxWidth="sm" onClose={handleCloseDialog} open={openDialog}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle sx={{ typography: 'h5', pb: 3 }}>Profile Image</DialogTitle>
          <DialogContent sx={{ py: 0 }}>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="userprofile"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleCloseDialog} color="inherit">
              Cancel
            </Button>

            <LoadingButton color="inherit" type="submit" variant="contained" loading={isSubmitting}>
              Upload Profile Image
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>


      <Notification notification={notification} setNotification={setNotification} />
      {/* <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

      </FormProvider> */}

    </>
  );
}

EcommerceAccountMenu.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

// ----------------------------------------------------------------------

function MenuItem({ item }) {
  const { active } = useActiveLink(item.path);

  return (
    <Link
      component={RouterLink}
      key={item.title}
      to={item.path}
      color={active ? 'primary' : 'inherit'}
      underline="none"
    >
      <ListItemButton
        sx={{
          px: 1,
          height: 44,
          borderRadius: 1,
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText
          primary={item.title}
          primaryTypographyProps={{
            typography: 'body2',
            ...(active && {
              typography: 'subtitle2',
            }),
          }}
        />
      </ListItemButton>
    </Link>
  );
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.node,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};
