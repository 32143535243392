import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.ServerUrl;
const paymenturl = ServerConfiguration.PaymentUrl
const PROJECTID = ServerConfiguration.PROJECTID;

const initialState = {
  isLoading: false,
  error: null,
  profile: [],
  password: [],
  passwordAction: null,
  userProfile: null,
  userProfileAction: [],
  addresses: [],
  addressAction: [],
  profileImageAction: null,
  childAction: [],
  childrenProfile: [],
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // UPDATE PASSWORD
    updatePasswordSuccess(state, action) {
      state.isLoading = false;
      state.passwordAction = action.payload;
    },

    // UNSUBSCRIBE UPDATE PASSWORD
    unsubscribeUpdatePasswordSuccess(state, action) {
      state.isLoading = false;
      state.passwordAction = null;
    },

    // GET USER PROFILE
    userProfileSuccess(state, action) {
      state.isLoading = false;
      state.userProfile = action.payload;
    },

    // UPDATE USER PROFILE
    userProfileActionSuccess(state, action) {
      state.isLoading = false;
      state.userProfileAction = action.payload;
    },

    // CLEAR USER PROFILE ACTIONS
    clearUserProfileActionSuccess(state, action) {
      state.isLoading = false;
      state.userProfileAction = [];
    },

    // GET USER ADDRESS BOOK
    getUserAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addresses = action.payload;
    },

    // UPDATE DEFAULT ADDRESS
    updateDefaultAddressSuccess(state, action) {
      state.isLoading = false;
      state.addressAction = action.payload;
    },

    // ADD NEW ADDRESS
    addNewAddressSuccess(state, action) {
      state.isLoading = false;
      state.addressAction = action.payload;
    },

    // UPDATE ADDRESS
    updateUserAddressSuccess(state, action) {
      state.isLoading = false;
      state.addressAction = action.payload;
    },

    // DELETE USER ADDRESS
    deleteUserAddressSuccess(state, action) {
      state.isLoading = false;
      state.addressAction = action.payload;
    },

    updateProfileImageSuccess(state, action) {
      state.isLoading = false;
      state.profileImageAction = action.payload;
    },

    // UPDATE DEFAULT ADDRESS
    clearUserAddressActionSuccess(state, action) {
      state.isLoading = false;
      state.addressAction = [];
    },

    clearUserActionSuccess(state, action) {
      state.isLoading = false;
      state.profileImageAction = null;
    },

    // UPDATE CHILD PROFILE
    updateChildProfileSuccess(state, action) {
      state.isLoading = false;
      state.childAction = action.payload;
    },

    // GET CHILD PROFILE
    getChildProfileByParentIDSuccess(state, action) {
      state.isLoading = false;
      state.childrenProfile = action.payload;
    },

    // DELETE CHILD PROFILE
    deleteChildProfileSuccess(state, action) {
      state.isLoading = false;
      state.childAction = action.payload;
    },

    clearChildActionSuccess(state, action) {
      state.isLoading = false;
      state.childAction = [];
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function updatePassword(userId, type, otp, password) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_UpdateProfileSpecificField?USERID=${userId}&TYPE=${type}&OTP=${otp}&UPDATEDFIELD=${password}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updatePasswordSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updatePasswordSuccess(data.ReturnSqlError));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unsubscribeUpdatePassword() {
  return async (dispatch) => {
    dispatch(slice.actions.unsubscribeUpdatePasswordSuccess());
  };
}
// ----------------------------------------------------------------------


export function getRecheckSenangPay(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${paymenturl}paymentstatus.php?UserID=${userId}`);
      const json = await response.json();
      console.log(json)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserProfile(type, typeValue, userRoleId, listPerPage, page, userId) {
  // console.log(`${url}User_ProfileListByType?TYPE=${type}&TYPEVALUE=${typeValue}&USERROLEID=${userRoleId}&LISTPERPAGE=${listPerPage}&PAGE=${page}&USERID=${userId}&PROJECTID=${PROJECTID}`)
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ProfileListByType?TYPE=${type}&TYPEVALUE=${typeValue}&USERROLEID=${userRoleId}&LISTPERPAGE=${listPerPage}&PAGE=${page}&USERID=${userId}&PROJECTID=${PROJECTID}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.userProfileSuccess(JSON.parse(data.ReturnData)[0]));
        dispatch({
          type: 'LOGIN',
          payload: {
            user: JSON.parse(data.ReturnData)[0],
          },
        });
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserProfile(userId, firstName, lastName, contactNo, userDOB, email, gender) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_UpdateProfile?USERID=${userId}&FIRSTNAME=${firstName}&LASTNAME=${lastName}&USERCONTACTNO=${contactNo}&USERDOB=${userDOB}&USEREMAIL=${email}&USERGENDER=${gender}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.userProfileActionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearUserProfileAction() {
  return async (dispatch) => {
    dispatch(slice.actions.clearUserProfileActionSuccess());
  };
}

export function getAddressByUser(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ViewAddressBook?USERID=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getUserAddressBookSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getUserAddressBookSuccess(JSON.parse(data.ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDefaultAddress(addressBookId, oldAddressBookId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_UpdateDefaultAddress?USERADDRESSBOOKID=${addressBookId}&USEROLDADDRESSBOOKID=${oldAddressBookId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateDefaultAddressSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addNewAddress(userAddressName, userId, userContactNo, userEmail, userAddressLine1, userAddressLine2, userPoscode, userState, userCity, countryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_AddAddressBook?USERADDRESSNAME=${userAddressName}&USERID=${userId}&USERCONTACTNO=${userContactNo}&USEREMAIL=${userEmail}&USERADDRESSLINE1=${userAddressLine1}&USERADDRESSLINE2=${userAddressLine2}&USERPOSCODE=${userPoscode}&USERSTATE=${userState}&USERCITY=${userCity}&COUNTRYID=${countryId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addNewAddressSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserAddress(addressBookId, userAddressName, userId, userContactNo, userEmail, userAddressLine1, userAddressLine2, userPoscode, userState, userCity, countryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_UpdateAddressBook?USERADDRESSBOOKID=${addressBookId}&USERADDRESSNAME=${userAddressName}&USERID=${userId}&USERCONTACTNO=${userContactNo}&USEREMAIL=${userEmail}&USERADDRESSLINE1=${userAddressLine1}&USERADDRESSLINE2=${userAddressLine2}&USERPOSCODE=${userPoscode}&USERSTATE=${userState}&USERCITY=${userCity}&COUNTRYID=${countryId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateUserAddressSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteUserAddress(addressBookId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_DeleteAddressBook?USERADDRESSBOOKID=${addressBookId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteUserAddressSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserProfileImage(userId, type, image) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_UserUpdatePhoto?USERID=${userId}&TYPE=${type}&USERPROFILEIMAGE=${image}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(getUserProfile('UserProfile', userId, 0, 999, 1, userId))
        dispatch(slice.actions.updateProfileImageSuccess(JSON.parse(data.ReturnData)));

      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearUserAddressAction() {
  return async (dispatch) => {
    dispatch(slice.actions.clearUserAddressActionSuccess());
  };
}

export function clearUserAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearUserActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateChildProfile(childId, childName, parentId, schoolId, classId, image, classNumber, activeInd, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_UpdateChildProfile?CHILDID=${childId}&CHILDNAME=${childName}&PARENTID=${parentId}&SCHOOLID=${schoolId}&CLASSID=${classId}&IMAGE=${image}&CLASSNUMBER=${classNumber}&ACTIVEIND=${activeInd}&MODIFYBY=${modifyBy}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateChildProfileSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getChildProfileByParentID(parentId) {
  console.log("getChildProfileByParentID url called")
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ViewChildProfileByParentID?PARENTID=${parentId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getChildProfileByParentIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteChildProfile(childId, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_DeleteChildProfile?CHILDID=${childId}&MODIFYBY=${modifyBy}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteChildProfileSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearChildAction() {
  return async (dispatch) => {
    dispatch(slice.actions.clearChildActionSuccess());
  };
}