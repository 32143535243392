import { useState, useEffect } from "react";
import PropTypes from "prop-types";

export default function Countdown({ countDown, onTimeUp }) {
  const [timeLeft, setTimeLeft] = useState(countDown);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    if (timeLeft === 0) {
      clearInterval(interval);
      onTimeUp(true);
    }

    return () => clearInterval(interval);
  }, [timeLeft, onTimeUp]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };


  return formatTime(timeLeft);
};

Countdown.propTypes = {
  countDown: PropTypes.number.isRequired,
  onTimeUp: PropTypes.func.isRequired,
};
