import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Checkbox,
  Typography
} from '@mui/material';
// components
import FormProvider, { RHFTextField, RHFSelect } from 'src/components/hook-form';
import Notification from 'src/components/Notification/Notification';
// import { getState, getCountry } from 'src/redux/slices/general';
import { useDispatch, useSelector } from 'src/redux/store';
import LoadingScreen from 'src/components/loading-screen/LoadingScreen';

// ----------------------------------------------------------------------
export default function EcommerceCheckoutBillingView({ address, selectedAddressID, currentAddress, setCustomerBillingData, orderID, cartType, ...other }) {
  const { states, country } = useSelector((state) => state.general);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getState());
  //   dispatch(getCountry());
  // }, [dispatch])

  const BillingSchema = Yup.object().shape({
    recipient: Yup.mixed().required('Recipient Name is required'),
    email: Yup.string().required('Email Address is required').email("Email Address must be valid"),
    contact: Yup.string().required('Recipient Contact is required'),
    addressLine1: Yup.string().required('Address Line 1 is required'),
    addressLine2: Yup.string().required('Address Line 2 is required'),
    // postcode: Yup.string().required('Postcode is required'),
    // state: Yup.string().required('State is required'),
    // city: Yup.string().required('City is required'),
    // country: Yup.number().required('Country is required'),
  });

  const defaultValues = {
    recipient: currentAddress.UserAddressName || "",
    email: currentAddress.UserEmail || "",
    contact: currentAddress.UserContactNo || "",
    addressLine1: currentAddress.UserAddressLine1 || "",
    addressLine2: currentAddress.UserAddressLine2 || "",
    isSimilar: currentAddress.isSimilar || "",
    // postcode: currentAddress.UserPoscode || "",
    // state: currentAddress.UserState || 1,
    // city: currentAddress.UserCity || "",
    // country: currentAddress.CountryID || 148,
  }

  const methods = useForm({
    resolver: yupResolver(BillingSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data) => {
    // try {
    //   console.log(data)
    // } catch (error) {
    //   console.error(error);
    // }
  };


  if (!states) return <LoadingScreen />;

  const STATE_OPTIONS = []
  states.map((x) =>
    STATE_OPTIONS.push({ value: x.StateID, label: x.State, alias: x.StateAlias })
  )

  const CITY_OPTIONS = states.filter((x) => x.State === values.state).map((y) => JSON.parse(y.CityDetail))

  const handleSimilarAddress = (value) => {
    setValue("isSimilar", value)
    if (value === true) {
      if (selectedAddressID && selectedAddressID !== 0) {
        const filterAddress = address.filter((x) => x.UserAddressBookID === selectedAddressID)

        if (filterAddress.length > 0) {
          const countryData = country.filter((x) => x.CountryId === filterAddress[0].CountryID)
          setCustomerBillingData({
            ...currentAddress,
            UserAddressName: filterAddress[0].UserAddressName,
            UserEmail: filterAddress[0].UserEmail,
            UserContactNo: filterAddress[0].UserContactNo,
            UserAddressLine1: filterAddress[0].UserAddressLine1,
            UserAddressLine2: `${filterAddress[0].UserAddressLine2} ,${filterAddress[0].UserCity} ,${filterAddress[0].UserState} ,${filterAddress[0].UserPoscode} , ${countryData[0].CountryName}`,
            isSimilar: value
          })
          setValue("recipient", filterAddress[0].UserAddressName)
          setValue("email", filterAddress[0].UserEmail)
          setValue("contact", filterAddress[0].UserContactNo)
          setValue("addressLine1", filterAddress[0].UserAddressLine1)
          setValue("addressLine2", `${filterAddress[0].UserAddressLine2} ,${filterAddress[0].UserCity} ,${filterAddress[0].UserState} ,${filterAddress[0].UserPoscode} , ${countryData[0].CountryName}`)
        }
      }
    } else {
      setCustomerBillingData({
        ...currentAddress,
        isSimilar: value
      })
    }
  }

  const isShowDeliveryCheckBox = () => {
    let isShown = false

    if (address && selectedAddressID && selectedAddressID !== 0 && selectedAddressID !== -1 && cartType !== "hotelStep" && orderID === ""  && cartType !== "eventStep" )
      isShown = true

    return isShown
  }

  return (

    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

      {
        isShowDeliveryCheckBox() &&
        <Grid container spacing={3} pb={2}>
          <Grid item xs={1} md={1}>
              <Checkbox name="isSimilar" disabled={orderID !== ""} checked={getValues("isSimilar") ? getValues("isSimilar") : false} onChange={(e, value) => handleSimilarAddress(value)} />
          </Grid>
          <Grid item xs={11} md={11} mt={1}>
            <Typography variant='subtitle2'>  Same As Delivery Address </Typography>
          </Grid>
        </Grid>
      }
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RHFTextField name="recipient" disabled={currentAddress.isSimilar || orderID !== ""} label="Recipient Name *" onChange={(e) => {
            setValue("recipient", e.target.value)
            setCustomerBillingData({ ...currentAddress, UserAddressName: e.target.value })
          }
          } />
        </Grid>
        <Grid item xs={12} md={6}>
          <RHFTextField name="email" disabled={currentAddress.isSimilar || orderID !== ""} label="Email Address *" onChange={(e) => {
            setValue("email", e.target.value)
            setCustomerBillingData({ ...currentAddress, UserEmail: e.target.value })
          }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <RHFTextField name="contact" disabled={currentAddress.isSimilar || orderID !== ""} label="Contact Number *" onChange={(e) => {
            setValue("contact", e.target.value)
            setCustomerBillingData({ ...currentAddress, UserContactNo: e.target.value })
          }} />
        </Grid>
        <Grid item xs={12}>
          <RHFTextField name="addressLine1" disabled={currentAddress.isSimilar || orderID !== ""} label="Address Line 1 *" onChange={(e) => {
            setValue("addressLine1", e.target.value)
            setCustomerBillingData({ ...currentAddress, UserAddressLine1: e.target.value })
          }} />
        </Grid>
        <Grid item xs={12}>
          <RHFTextField name="addressLine2" disabled={currentAddress.isSimilar || orderID !== ""} label="Address Line 2 *" onChange={(e) => {
            setValue("addressLine2", e.target.value)
            setCustomerBillingData({ ...currentAddress, UserAddressLine2: e.target.value })
          }} />
        </Grid>
      </Grid>
    </FormProvider>


  );
}

EcommerceCheckoutBillingView.propTypes = {
  currentAddress: PropTypes.object,
  setCustomerBillingData: PropTypes.func,
  selectedAddressID: PropTypes.number,
  address: PropTypes.array,
  orderID: PropTypes.string,
  cartType: PropTypes.string,
};
