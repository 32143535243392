/* eslint-disable no-nested-ternary */
import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
// @mui
import { Box, Button, Typography, Stack, Container } from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { MotionContainer, varBounce } from 'src/components/animate';
//
import { EcommerceHeader } from '../layout';
import { useDispatch, useSelector } from '../../../redux/store';
import LoadingScreen from '../../../components/loading-screen';
import {
  viewOrderStatusByUUID,
  clearOrderData,
} from '../../../redux/slices/order';
import { useAuthContext } from '../../../auth/useAuthContext'
import { ServerConfiguration } from '../../../utils/serverConfig';

// ----------------------------------------------------------------------

export default function EcommerceOrderCompletedView({ transactionNo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymenturl = ServerConfiguration.PaymentUrl
  const { loginBuyNow } = useAuthContext();
  const { orderStatusByID } = useSelector((data) => data.order);
  const [paymentData, setPaymentData] = useState({
    isDataBind: false,
    data: [],
    isErrorPage: false,
  });

  const [orderData, setOrderData] = useState([]);
  const [isOrderSet, setOrder] = useState(false);

  useEffect(() => {
    if (transactionNo) {
      checkToyyibPay();
      dispatch(viewOrderStatusByUUID(transactionNo, 4));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionNo]);

  useEffect(() => {
    if (orderStatusByID) {

      const submitLogin = async () => {
        try {
          await loginBuyNow(orderStatusByID[0]?.UserID)

        } catch (error) {
          console.error(error);
        }
      };

      submitLogin()
      setOrder(true);
      setOrderData(orderStatusByID);

      if (orderStatusByID.length > 0 && orderStatusByID[0].Transactionuuid === transactionNo) {
        setPaymentData({
          isDataBind: true,
          data: orderStatusByID[0],
          isErrorPage: false,
        });
      } else {
        setPaymentData({
          isDataBind: true,
          data: [],
          isErrorPage: true,
        });
      }

      dispatch(clearOrderData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatusByID]);


  const getOrderData = (propData, type) => {
    let returnData = '';

    if (type === 'OrderNumber') {
      propData.map((x, index) => {
        returnData += x.OrderID;
        if (index < propData.length - 1) returnData += ',';
        return {};
      });
    } else {
      returnData = parseFloat(
        propData.reduce((subtotal, item) => subtotal + item.OrderTotalAmount, 0)
      ).toFixed(2);
    }

    return returnData;
  };

  const checkToyyibPay = () => {
    const formdata = new FormData();
    formdata.append('order_id', transactionNo);

    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    fetch(`${paymenturl}queryOrderStatus.php`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result && JSON.parse(result).status === 1) {
          const query = JSON.parse(result)
          console.log(query)

          // const reference = query.data[0].payment_info.transaction_reference

          // const formdata2 = new FormData();
          // formdata2.append('transaction_reference', reference);

          // const requestOptions2 = {
          //   method: 'POST',
          //   body: formdata2,
          //   redirect: 'follow',
          // };

          // fetch('https://be-delight.my/Payment/queryTransactionStatus.php', requestOptions2)
          // .then((response) => response.text())
          // .then((result2) => {     
          //   if (result2 && JSON.parse(result2).status === 1) {
          //    const query2 = JSON.parse(result2)
          //   }
          // })
          //   .catch((error) => console.error(error));

        }
      })
      .catch((error) => console.error(error));
  };

  const notFoundPage = () => (
    <>
      <m.div variants={varBounce().in}>
        <Box sx={{ fontSize: 128 }}>📂</Box>
      </m.div>
      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="h3" sx={{ color: '#FA541C' }}>
          Order Transaction Not Found
        </Typography>

        <Typography variant="h5">Transaction Id: {transactionNo}</Typography>

        <Stack spacing={1} sx={{ my: 5, pt: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            Unfortunately transaction was found in our system. Please contact administrator for this
            issue
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Page will be automatically redirected to the main page or click button below
          </Typography>
        </Stack>
      </Stack>
      <Button
        onClick={() => navigate(paths.landing)}
        size="large"
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="carbon:chevron-left" />}
      >
        Continue Shopping
      </Button>{' '}
    </>
  );

  const successPage = (propData) => (
    <>
      <m.div variants={varBounce().in}>
        <Box sx={{ fontSize: 128 }}>🎉</Box>
      </m.div>

      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="h3" sx={{ color: 'green' }}>
          Your order is complete!
        </Typography>

        <Typography variant="h5">Order Number: {getOrderData(propData, 'OrderNumber')}</Typography>

        <Typography variant="h5">Transaction Id: {transactionNo}</Typography>

        <Typography variant="h5">
          Transaction Amount: RM {getOrderData(propData, 'OrderTotal')}
        </Typography>

        <Stack spacing={1} sx={{ my: 5, pt: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            Your payment had been processed successfully
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Page will be automatically redirected to the main page or click button below
          </Typography>
        </Stack>
      </Stack>
      <Button
        onClick={() => navigate(paths.eCommerce.account.orderDetail(orderData[0].OrderID))}
        size="large"
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="carbon:chevron-left" />}
      >
        View Order History
      </Button>
    </>
  );

  const pendingPage = (propData) => (
    <>
      <m.div variants={varBounce().in}>
        <Box sx={{ fontSize: 128 }}>⏳</Box>
      </m.div>

      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="h3">Pending Approval</Typography>

        <Typography variant="h5">Order Number: {getOrderData(propData, 'OrderNumber')}</Typography>

        <Typography variant="h5">Transaction Id: {transactionNo}</Typography>

        <Typography variant="h5">
          Transaction Amount: RM {getOrderData(propData, 'OrderTotal')}
        </Typography>

        <Stack spacing={1} sx={{ my: 5, pt: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            Your request has been sent. Pending for authorizer Approval
            {propData?.OrderTotalAmount > 30000 &&
              '. Maximum Transaction Limit Exceeded RM30000.00'}
            {propData?.OrderTotalAmount < 1 &&
              '. Transaction Amount is Lower than the Minimum Limit RM1.00'}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Page will be automatically redirected to the main page or click button below
          </Typography>
        </Stack>
      </Stack>

      <Button
        onClick={() => navigate(paths.eCommerce.account.orderDetail(orderData[0].OrderID))}
        size="large"
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="carbon:chevron-left" />}
      >
        View Order History
      </Button>
    </>
  );

  const errorPage = (propData) => (
    <>
      <m.div variants={varBounce().in}>
        <Box sx={{ fontSize: 128 }}>❌</Box>
      </m.div>

      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="h3" sx={{ color: 'red' }}>
          Transaction Failed
        </Typography>

        <Typography variant="h5">Order Number: {getOrderData(propData, 'OrderNumber')}</Typography>

        <Typography variant="h5">Transaction Id: {transactionNo}</Typography>

        <Typography variant="h5">
          Transaction Amount: RM {getOrderData(propData, 'OrderTotal')}
        </Typography>

        <Stack spacing={1} sx={{ my: 5, pt: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            Unfortunately payment was unsuccessful
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Page will be automatically redirected to the main page or click button below
          </Typography>
        </Stack>
      </Stack>

      <Button
        onClick={() => navigate(paths.eCommerce.account.orderDetail(orderData[0].OrderID))}
        size="large"
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="carbon:chevron-left" />}
      >
        View Order History
      </Button>
    </>
  );

  const handleReturnPage = (paymentStatus) => {
    let returnPage = '';

    switch (paymentStatus) {
      case 1:
        returnPage = successPage(orderData);
        break;
      case 2:
        returnPage = pendingPage(orderData);
        break;
      case 0:
        returnPage = errorPage(orderData);
        break;

      default:
        returnPage = errorPage(orderData);
        break;
    }

    return returnPage;
  };

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        paymentData.isDataBind === true && isOrderSet ? (
          <Container
            component={MotionContainer}
            sx={{
              textAlign: 'center',
              alignItem: 'center',
              pb: { xs: 10, md: 20 },
              pt: { xs: 10, md: 20 },
            }}
          >
            {paymentData.data && paymentData.data && orderData.length > 0 ? paymentData.data.PaymentMethodID === 61 && paymentData.data.TrackingStatusID === 2 ? handleReturnPage(1) : handleReturnPage(paymentData.data.senangPayStatusID) : notFoundPage()}
          </Container>
        ) : (
          <LoadingScreen />
        )
      }
    </>
  );
}

EcommerceOrderCompletedView.propTypes = {
  transactionNo: PropTypes.string,
};
