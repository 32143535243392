/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // routes
import { paths } from 'src/routes/paths';
import moment from 'moment';
// @mui
import {
  Stack,
  IconButton,
  Typography,
  Checkbox,
  Grid,
} from '@mui/material';
// utils
import { fCurrency } from 'src/utils/formatNumber';
import { checkAccessCategory, checkDateDiff } from 'src/utils/helpers'
// hooks
import useResponsive from 'src/hooks/useResponsive';
// components
import Label from 'src/components/label';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import Notification from 'src/components/Notification/Notification';
import TextMaxLine from 'src/components/text-max-line';
import { QuantityControl } from 'src/components/hook-form';
import { ProductPricing } from '../components';
import { useAuthContext } from '../../../auth/useAuthContext';
import {
  deleteProductCart,
  updateProductCart,
  clearProductCartAction,
} from '../../../redux/slices/product';
import { useDispatch, useSelector } from '../../../redux/store';


// ----------------------------------------------------------------------

export default function EcommerceCartItemSide({
  product,
  handleSelectedItem,
  selectedProduct,
  handleAdjustSelectedQuantity,
  isTicketContain,
}) {
  const isSmUp = useResponsive('up', 'sm');
  const { user } = useAuthContext();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { productCartAction } = useSelector((state) => state.product);

  const [isChecked, setIsChecked] = useState(false);

  const [promotionData, setPromotionData] = useState({
    ProductStockAvailable: 0,
    PromotionID: '',
    DiscountPrice: '0',
    stockLimit: 0,
    purchaseLimit: 0,
    discount: 0,
    isDiscount: false,
  });

  const [notification, setNotification] = useState({
    open: false,
    msg: '',
    type: 'success',
  });

  const overPreOrder = (product.CheckinDate113 && product?.PreOrderDay > 0) ? checkDateDiff(product.CheckinDate113, new Date()) < product.PreOrderDay - 1 : false

  useEffect(() => {
    if (productCartAction.length > 0) {
      dispatch(clearProductCartAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCartAction]);

  useEffect(() => {
    if (product.ProductPromotion !== null) {
      const data = JSON.parse(product.ProductPromotion).filter(
        (x) => x.ProductVariationDetailID === product.ProductVariationDetailID
      );
      if (data.length > 0 && data[0].ProductStockAvailable >= product.ProductQuantity)
        setPromotionData({
          ProductStockAvailable: data[0].ProductStockAvailable,
          PromotionID: data[0].PromotionID,
          DiscountPrice: data[0].PromotionPrice,
          stockLimit: data[0].ProductStockLimit,
          purchaseLimit: data[0].ProductPurchaselimit,
          discount: data[0].ProductDiscount,
          isDiscount: true,
        });
      if (data.length > 0 && data[0].ProductStockAvailable < product.ProductQuantity)
        setPromotionData({
          ProductStockAvailable: data[0].ProductStockAvailable,
          PromotionID: '',
          DiscountPrice: '0',
          stockLimit: 0,
          purchaseLimit: 0,
          discount: 0,
          isDiscount: false,
        });
    } else
      setPromotionData({
        ProductStockAvailable: 0,
        PromotionID: '',
        DiscountPrice: '0',
        stockLimit: 0,
        purchaseLimit: 0,
        discount: 0,
        isDiscount: false,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (
      selectedProduct.length > 0 &&
      selectedProduct.find((x) => x.UserCartID === product.UserCartID)
    ) {
      setIsChecked(true);
    } else setIsChecked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  const handleDeleteCart = () => {
    dispatch(deleteProductCart(product.UserCartID, checkAccessCategory(product.isHotel, product.isEvent)));
  };


  const handleCartCheckbox = () => {
    let isDisabled = false;
    if (promotionData.PromotionID !== '' && product.ProductQuantity > promotionData.stockLimit)
      isDisabled = true;
    if (promotionData.PromotionID !== '' && product.ProductQuantity > promotionData.purchaseLimit)
      isDisabled = true;
    if (product.isProductDeleted === 1 || product.isProductVariationDeleted === 1)
      isDisabled = true;
    // if (promotionData.PromotionID !== "" && product.ProductQuantity > promotionData.ProductStockAvailable)
    //   isDisabled = true
    if (product.ProductQuantity > product.ProductStock) isDisabled = true;
    if (
      moment(product.CheckinDate113).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')
    )
      isDisabled = true;
    if (product.EventEndedInd === 1) isDisabled = true;

    if (product?.CartCustomization && product?.CartCustomization !== undefined && JSON.parse(product.CartCustomization).filter((x) => x.ISAVAILABLE === 0).length > 0)
      isDisabled = true;

    if (product.IsAvailable === 0)
      isDisabled = true;

    if (overPreOrder)
      isDisabled = true;

    return isDisabled;
  };

  const handleAddQuantity = (data) => {
    if (data < product.ProductStock) {
      handleAdjustSelectedQuantity(product.UserCartID, data + 1);
      dispatch(
        updateProductCart(
          user.UserID,
          product.UserCartID,
          data + 1,
          checkAccessCategory(product.isHotel, product.isEvent)
        )
      );
    } else setNotification({ open: true, msg: 'Maximum available stock added', type: 'error' });
  };

  const handleMinusQuantity = (data) => {
    if (data > 1) {
      handleAdjustSelectedQuantity(product.UserCartID, data - 1);
      dispatch(
        updateProductCart(
          user.UserID,
          product.UserCartID,
          data - 1,
          checkAccessCategory(product.isHotel, product.isEvent)
        )
      );
    } else setNotification({ open: true, msg: 'Minimum 1 item is required', type: 'error' });
  };

  const customizePrice = product.CartCustomization ? JSON.parse(product.CartCustomization).reduce((subtotal, item) => subtotal + item.CustomizationDetailsPrice, 0) + product.ProductPrice : product.ProductPrice

  return (
    <Stack
      alignItems="center"
      sx={{ borderBottom: (theme) => `solid 1px ${theme.palette.divider}` }}
      py={{ xs: 0, md: 1 }}
    >
      {(product.isProductDeleted === 1 || product.isProductVariationDeleted === 1) && (
        <Typography variant="caption" color="red" my={1}>
          This product is currently not available
        </Typography>
      )}

      <Grid container py={1} sx={{ opacity: handleCartCheckbox() ? 0.5 : 1 }}>
        <Grid item xs={2}>
          <Checkbox
            checked={isChecked}
            sx={{ color: handleCartCheckbox() && 'lightgrey' }}
            disabled={handleCartCheckbox()}
            onClick={() => handleSelectedItem(product, '')}
          />
        </Grid>

        <Grid item xs={3} >
          <Image
            src={product.ProductImage}
            sx={{
              width: { xs: 70, sm: 90 },
              height: { xs: 70, sm: 90 },
              flexShrink: 0,
              borderRadius: 1.5,
              bgcolor: 'background.neutral',
            }}
          />
        </Grid>

        <Grid item xs={7} >
          <Stack spacing={{ xs: 0, sm: 0.5 }} sx={{ pl: 1.5 }}>
            <Stack spacing={{ xs: 0.5, sm: 1 }} direction="row" alignItems="flex-start" justifyContent="space-between" pt={0.5}>
              <TextMaxLine
                variant="subtitle2"
                fontSize={{ xs: 11, sm: 13, md: 16 }}
                line={3}
                onClick={() =>
                  product.isHotel === 1
                    ? navigate(paths.hotel.list(product.ProductID))
                    : product.isEvent === 1
                      ? navigate(
                        paths.booklahEvent.event(product.ProductName.replace(/ /g, '').toLowerCase())
                      )
                      : navigate(paths.eCommerce.product(product.ProductID))
                }
              >
                {product.ProductName}
              </TextMaxLine>

              <IconButton onClick={handleDeleteCart} sx={{ ml: -1, p: 0, pt: 0.5 }}>
                <Iconify icon="carbon:trash-can" width={{ xs: 13, md: 18 }} color="red" />
              </IconButton>
            </Stack>

            <Stack direction='column'>
              <Stack direction="row" alignItems="center">
                <Iconify icon="bx:child" width={{ xs: 16, md: 24 }} sx={{ ml: -0.5, mr: 0.5 }} />

                <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontSize: { xs: 10, sm: 12, md: 14 } }}>
                  {product.ChildName} ({product.ClassName})
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center">
                <Iconify icon="lets-icons:date-fill" width={{ xs: 10, md: 16 }} sx={{ mr: 1 }} />

                <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontSize: { xs: 10, sm: 12, md: 14 } }}>
                  {' '}
                  {product.CheckinDate113}
                </Typography>
              </Stack>
            </Stack>


            {/* <Stack pt={1} >
              {promotionData.PromotionID !== '' ? (
                <ProductPricing
                  price={Number(promotionData.DiscountPrice)}
                  priceSale={Number(customizePrice)}
                  discount={promotionData.discount}
                  isDiscount={promotionData.isDiscount}
                  row="false"
                />
              ) : product.EventMerchandiseInd === 1 && isTicketContain ? (
                <ProductPricing
                  price={Number(
                    product.DiscountedProductPrice ? product.DiscountedProductPrice : '0'
                  )}
                  priceSale={Number(customizePrice || '0')}
                  discount={10}
                  isDiscount
                  percent={false}
                  sx={{ typography: 'subtitle2' }}
                />
              ) : (
                <Stack sx={{ typography: 'subtitle2' }}>
                  {' '}
                  {fCurrency(customizePrice || '0')}{' '}
                </Stack>
              )}
            </Stack> */}

          </Stack>
        </Grid>


        <Grid item xs={2} />

        <Grid item xs={10} >
          <Stack>
            <Stack direction="column">
              <Typography variant="body2" sx={{ color: 'text.secondary', textDecoration: 'underline', fontSize: { xs: 11, md: 14 } }}>
                Order Details
              </Typography>

              <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontSize: { xs: 11, md: 14 } }}>
                Variation : {product.ProductVariationValue} ({fCurrency(product.ProductPrice || '0')})
              </Typography>

              {product?.IsAvailable === 0 &&
                <Stack direction="row" alignItems="center">
                  <Iconify icon="fluent-emoji-flat:warning" width={{ xs: 12, md: 20 }} mr={1} />
                  <Typography variant="subtitle2" sx={{ color: 'red', fontSize: { xs: 10, md: 14 } }} >Variation Not Available</Typography>
                </Stack>
              }

              {
                product?.CartCustomization && product?.CartCustomization !== undefined && JSON.parse(product.CartCustomization).map((customize) => (
                  <Stack key={customize.UserCartCustomizationID}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontSize: { xs: 10, md: 14 } }} >
                      + {customize.CustomizationDetails} {" "} ({fCurrency(customize.CustomizationDetailsPrice || '0')})
                    </Typography>
                    {customize.ISAVAILABLE === 0 &&
                      <Typography variant="subtitle2" sx={{ color: 'red' }} >
                        <Iconify icon="fluent-emoji-flat:warning" mr={1} />
                        Customization Not Available
                      </Typography>
                    }
                  </Stack>
                ))
              }
            </Stack>


            {promotionData.PromotionID !== '' && (
              <Stack my={1}>
                <Label color="error">
                  {promotionData.discount.toFixed(2)} % OFF{' '}
                </Label>
              </Stack>
            )}


            <Stack sx={{ my: { xs: 0, sm: 1 } }} >
              {overPreOrder === false && moment(product.CheckinDate113).format('YYYY-MM-DD') <
                moment(new Date()).format('YYYY-MM-DD') && (
                  <Stack direction="row" alignItems="center">
                    <Iconify icon="ph:warning-fill" width={{ xs: 10, md: 16 }} sx={{ mr: 1, color: 'red' }} />

                    <Typography variant="body2" sx={{ color: 'red', fontSize: { xs: 10, md: 12 } }}>
                      Exceed Current Date
                    </Typography>
                  </Stack>
                )}

              {overPreOrder &&
                <Stack direction="row" alignItems="center">
                  <Iconify icon="ph:warning-fill" width={{ xs: 10, md: 16 }} sx={{ mr: 1, color: 'red' }} />
                  <Typography variant="body2" sx={{ color: 'red', fontSize: { xs: 10, md: 12 } }}>
                    Preorder is required
                  </Typography>
                </Stack>
              }
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <QuantityControl
                value={product.ProductQuantity}
                onAdd={(e) => handleAddQuantity(e)}
                onMinus={(e) => handleMinusQuantity(e)}
                mt={isSmUp ? -15 : -18}
                mb={0}
                textFieldWidth={isSmUp ? 50 : 20}
                sx={{
                  fontSize: isSmUp ? 14 : 10,
                }}
              />

              <Stack direction='column' justifyContent="center" spacing={{ xs: 0, md: 0.5 }}>
                {promotionData.PromotionID !== '' ? (
                  <Typography variant='subtitle2' sx={{ color: 'red', fontSize: { xs: 12, md: 17 } }}>
                    Total:{' '}
                    {fCurrency(
                      promotionData.DiscountPrice
                        ? promotionData.DiscountPrice * product.ProductQuantity
                        : '0'
                    )}
                  </Typography>
                ) : product.EventMerchandiseInd === 1 && isTicketContain ? (
                  <Typography variant='subtitle2' fontSize={{ xs: 12, md: 17 }}>
                    Total:{' '}
                    {fCurrency(
                      product.DiscountedProductPrice
                        ? product.DiscountedProductPrice * product.ProductQuantity
                        : '0'
                    )}
                  </Typography>
                ) : (
                  <Typography variant='subtitle2' fontSize={{ xs: 12, md: 17 }}>
                    Total:{' '}
                    {fCurrency(
                      customizePrice ? customizePrice * product.ProductQuantity : '0'
                    )}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      {
        product.EventMerchandiseInd === 1 && isTicketContain && (
          <Typography variant="caption" color="#d53600">
            **This price only apply with purchase of event ticket
          </Typography>
        )
      }

      {
        product.EventEndedInd === 1 && (
          <Typography variant="caption" color="#d53600">
            **Event ticket sold out
          </Typography>
        )
      }

      {/* {product.EventMerchandiseInd === 1 && !isTicketContain && (
        <Typography variant='caption' color="#d53600">**This is a promotion with purchase of event ticket</Typography>
      )} */}

      {
        promotionData.PromotionID !== '' &&
        product.ProductQuantity > promotionData.purchaseLimit && (
          <Typography variant="caption" color="red">
            This promotion allow only {promotionData.purchaseLimit} item per transaction
          </Typography>
        )
      }

      {
        promotionData.PromotionID !== '' &&
        product.ProductQuantity === promotionData.ProductStockAvailable && (
          <Typography variant="caption" color="red">
            {promotionData.ProductStockAvailable} item left for this promotion, more then item left
            will calculate in non-discount price{' '}
          </Typography>
        )
      }

      {
        promotionData.PromotionID !== '' && product.ProductQuantity > promotionData.stockLimit && (
          <Typography variant="caption" color="red">
            Only {promotionData.stockLimit} Promotion item left{' '}
          </Typography>
        )
      }

      {
        product.ProductQuantity > product.ProductStock && (
          <Typography variant="caption" color="red">
            Insufficient stock for you purchase
          </Typography>
        )
      }

      <Notification notification={notification} setNotification={setNotification} />
    </Stack >
  );
}

EcommerceCartItemSide.propTypes = {
  product: PropTypes.shape({
    EventEndedInd: PropTypes.number,
    DiscountedProductPrice: PropTypes.number,
    EventMerchandiseInd: PropTypes.number,
    ProductImage: PropTypes.string,
    ProductName: PropTypes.string,
    ProductID: PropTypes.number,
    ProductPrice: PropTypes.number,
    ProductPromotion: PropTypes.string,
    ProductVariationValue: PropTypes.string,
    ProductStock: PropTypes.number,
    ProductQuantity: PropTypes.number,
    MerchantShopName: PropTypes.string,
    MerchantID: PropTypes.number,
    UserCartID: PropTypes.number,
    isHotel: PropTypes.number,
    isEvent: PropTypes.number,
    CheckinDate113: PropTypes.string,
    CheckoutDate113: PropTypes.string,
    ProductVariationDetailID: PropTypes.number,
    isProductDeleted: PropTypes.number,
    isProductVariationDeleted: PropTypes.number,
    CartCustomization: PropTypes.string,
    RelatedID: PropTypes.number,
    RelatedTypeID: PropTypes.number,
    ChildName: PropTypes.string,
    ChildProfileImage: PropTypes.string,
    SchoolName: PropTypes.string,
    ClassName: PropTypes.string,
    IsAvailable: PropTypes.number,
    PreOrderDay: PropTypes.number,
  }),
  handleSelectedItem: PropTypes.func,
  handleAdjustSelectedQuantity: PropTypes.func,
  selectedProduct: PropTypes.array,
  isTicketContain: PropTypes.bool,
};
