import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Stack, Radio, RadioGroup, FormControlLabel, Paper, TextField } from '@mui/material';
// components
import Iconify from 'src/components/iconify';

import Image from 'src/components/image';

// ----------------------------------------------------------------------

export default function EcommerceCheckoutPaymentMethod({ options,
  // handleBanking, 
  selection,
  handleSelection,
  //  bankSelected, bankOptions 
}) {
  const { control } = useFormContext();
  return (
    <Controller
      name="paymentMethods"
      control={control}
      render={({ field }) => (
        <RadioGroup {...field}>
          <Box
            rowGap={2.5}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{ xs: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
          >
            {options.map((option) => {
              if (option.disable) {
                return (<OptionItemDisable
                  selection1={selection}
                  key={option.value}
                  option={option}
                  selected={selection === option.value}
                  // hasChild={option.value === 1}
                  // isBankIn={option.value === 1}
                  hasChild={false}
                  isBankIn={false}
                  // handleBanking={handleBanking}
                  // bankSelected={bankSelected}
                  // bankOptions={bankOptions}
                  handleSelection={handleSelection}
                />)
              }
              return (<OptionItem
                selection1={selection}
                key={option.value}
                option={option}
                selected={selection === option.value}
                // hasChild={option.value === 1}
                // isBankIn={option.value === 1}
                hasChild={false}
                isBankIn={false}
                // handleBanking={handleBanking}
                // bankSelected={bankSelected}
                // bankOptions={bankOptions}
                handleSelection={handleSelection}
              />)

            })}
          </Box>
        </RadioGroup>
      )}
    />
  );
}

EcommerceCheckoutPaymentMethod.propTypes = {
  options: PropTypes.array,
  // bankOptions: PropTypes.array,
  // bankSelected: PropTypes.string,
  // handleBanking: PropTypes.func,
  handleSelection: PropTypes.func,
  selection: PropTypes.number,
};

// ----------------------------------------------------------------------


function OptionItemDisable({ option, selected, handleSelection, selection1, hasChild, isBankIn,
  // handleBanking, bankSelected, bankOptions
}) {
  const { value, label, description, icons, PaymentmethodID } = option;
  const renderLabel = (
    <Stack flexGrow={1} spacing={0.5} >
      <Stack direction="row" alignItems="center">
        <Box component="span" sx={{ typography: 'subtitle1', flexGrow: 1 }}>
          {label}
        </Box>

        {/* <Iconify
          icon={
            (value === 'visa' && 'logos:visa') ||
            (value === 'mastercard' && 'logos:mastercard') ||
            'logos:paypal'
          }
          width={24}
        /> */}

        {icons.map((icon) => (
          <Image key={icon} disabledEffect alt="logo card" src={icon} sx={{ width: 60 }} />
        ))}
      </Stack>

      <Box component="span" sx={{ typography: 'body2' }}>
        {description}
      </Box>
    </Stack>


  );

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        alignItems: 'center',
        transition: (theme) => theme.transitions.create('all'),
        ...(hasChild && {
          flexWrap: 'wrap',
        }),
      }}
    >
      <FormControlLabel
        value="disabled"
        disabled
        control={
          <Radio
            disable
            disableRipple
            // checkedIcon={<></>}
            // checked={selected}
            // onClick={(e) => handleSelection(e.target.value, PaymentmethodID)}
            sx={{ mx: 1 }}
          />
        }
        label={renderLabel}
        sx={{

          width: "100%",
          m: 0,
          py: 3,
          pr: 2,
          borderRadius: 1,
          border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
          ...(selected && {
            boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
          }),
          '& .MuiFormControlLabel-label': {
            width: 1,
          },
        }}
      />
      {/* {isBankIn && selected && (
        <Stack
          direction="row"
          alignItems="flex-start"
          flexGrow={1} spacing={0.5}
          sx={{
            px: 3,
            width: 1,
            my: 3
          }}
        >
          <TextField select fullWidth label="Banks" SelectProps={{ native: true }} value={bankSelected} onChange={(e) => { 
            handleBanking(e.target.value) }}>
            {bankOptions.map((bank) => (
              <option key={bank.BankID} value={bank.BankID}>
                {bank.BankName}
              </option>
            ))}
          </TextField>
        </Stack>
      )} */}
    </Paper>
  );
}

OptionItemDisable.propTypes = {
  option: PropTypes.shape({
    description: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
    icons: PropTypes.array,
    PaymentmethodID: PropTypes.number,
  }),
  selected: PropTypes.bool,
  hasChild: PropTypes.bool,
  isBankIn: PropTypes.bool,
  selection1: PropTypes.string,
  // handleBanking: PropTypes.func,
  // bankSelected: PropTypes.string,
  // bankOptions: PropTypes.array,
  handleSelection: PropTypes.func,
};

function OptionItem({ option, selected, handleSelection, selection1, hasChild, isBankIn,
  // handleBanking, bankSelected, bankOptions
}) {
  const { value, label, description, icons, PaymentmethodID } = option;
  const renderLabel = (
    <Stack flexGrow={1} spacing={0.5} >
      <Stack direction="row" alignItems="center">
        <Box component="span" sx={{ typography: 'subtitle1', flexGrow: 1 }}>
          {label}
        </Box>

        {/* <Iconify
          icon={
            (value === 'visa' && 'logos:visa') ||
            (value === 'mastercard' && 'logos:mastercard') ||
            'logos:paypal'
          }
          width={24}
        /> */}

        {icons.map((icon) => (
          <Image key={icon} disabledEffect alt="logo card" src={icon} sx={{ width: 60 }} />
        ))}
      </Stack>

      <Box component="span" sx={{ typography: 'body2' }}>
        {description}
      </Box>
    </Stack>


  );

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        alignItems: 'center',
        transition: (theme) => theme.transitions.create('all'),
        ...(hasChild && {
          flexWrap: 'wrap',
        }),
      }}
    >
      <FormControlLabel
        value={value ?? " "}
        control={
          <Radio
            disableRipple
            checkedIcon={<Iconify width={24} icon="carbon:checkmark-outline" />}
            checked={selected}
            onClick={(e) => handleSelection(e.target.value, PaymentmethodID)}
            sx={{ mx: 1 }}
          />
        }
        label={renderLabel}
        sx={{

          width: "100%",
          m: 0,
          py: 3,
          pr: 2,
          borderRadius: 1,
          border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
          ...(selected && {
            boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
          }),
          '& .MuiFormControlLabel-label': {
            width: 1,
          },
        }}
      />
      {/* {isBankIn && selected && (
        <Stack
          direction="row"
          alignItems="flex-start"
          flexGrow={1} spacing={0.5}
          sx={{
            px: 3,
            width: 1,
            my: 3
          }}
        >
          <TextField select fullWidth label="Banks" SelectProps={{ native: true }} value={bankSelected} onChange={(e) => { 
            handleBanking(e.target.value) }}>
            {bankOptions.map((bank) => (
              <option key={bank.BankID} value={bank.BankID}>
                {bank.BankName}
              </option>
            ))}
          </TextField>
        </Stack>
      )} */}
    </Paper>
  );
}

OptionItem.propTypes = {
  option: PropTypes.shape({
    description: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
    icons: PropTypes.array,
    PaymentmethodID: PropTypes.number,
  }),
  selected: PropTypes.bool,
  hasChild: PropTypes.bool,
  isBankIn: PropTypes.bool,
  selection1: PropTypes.string,
  // handleBanking: PropTypes.func,
  // bankSelected: PropTypes.string,
  // bankOptions: PropTypes.array,
  handleSelection: PropTypes.func,
};
