// @mui
import PropTypes from 'prop-types';
import { Checkbox, Stack, FormControlLabel, Grid } from '@mui/material';

// ----------------------------------------------------------------------

export default function EcommerceFilterDataCustomize({ options, filterData, onChangeData, isDisabled, ...other }) {
  return (
    <Grid container {...other}>
      {options.map((option) => (
        <Grid item xs={6} key={option.value}>
          <FormControlLabel
            disabled={isDisabled}
            key={option.value}
            control={
              <Checkbox
                size="small"
                value={option.value}
                checked={filterData.filter((x) => x.value === option.value).length > 0}
                onChange={() => onChangeData(option)}
              />
            }
            label={option.label}
          />
        </Grid>

      ))}
    </Grid>
  );
}

EcommerceFilterDataCustomize.propTypes = {
  filterData: PropTypes.array,
  onChangeData: PropTypes.func,
  options: PropTypes.array,
  isDisabled: PropTypes.bool,
};
