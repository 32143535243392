import _mock from '../_mock';

// ----------------------------------------------------------------------

export const _categories = [
  { label: 'Marketing', path: '' },
  { label: 'Community', path: '' },
  { label: 'Tutorials', path: '' },
  { label: 'Business', path: '' },
  { label: 'Management', path: '' },
];

// ----------------------------------------------------------------------

export const _tags = [
  { label: 'Marketing', path: '' },
  { label: 'Development', path: '' },
  { label: 'Banking', path: '' },
  { label: 'HR & Recruting', path: '' },
  { label: 'Design', path: '' },
  { label: 'Management', path: '' },
  { label: 'Business', path: '' },
  { label: 'Community', path: '' },
  { label: 'Tutorials', path: '' },
];

// ----------------------------------------------------------------------

export const _testimonials = [...Array(8)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  role: _mock.role(index),
  avatar: _mock.image.avatar(index),
  postDate: _mock.time(index),
  rating: 5,
  review:
    'Amazing experience i love it a lot. Thanks to the team that dreams come true, great! I appreciate there attitude and approach.',
}));

// ----------------------------------------------------------------------

export const _socials = [
  {
    value: 'facebook',
    label: 'FaceBook',
    icon: 'carbon:logo-facebook',
    color: '#1877F2',
    link: "https://www.facebook.com/profile.php?id=100065225284121"
  },
  {
    value: 'instagram',
    label: 'Instagram',
    icon: 'carbon:logo-instagram',
    color: '#E02D69',
    link: "https://www.facebook.com/profile.php?id=100065225284121"
  },
  // {
  //   value: 'linkedin',
  //   label: 'Linkedin',
  //   icon: 'carbon:logo-linkedin',
  //   color: '#007EBB',
  // },
  // {
  //   value: 'twitter',
  //   label: 'Twitter',
  //   icon: 'carbon:logo-twitter',
  //   color: '#00AAEC',
  // },
];

// ----------------------------------------------------------------------

const LAT_LONG = [
  [33, 65],
  [-12.5, 18.5],
  [20.96, 26.27],
];

export const _offices = ['Kuching'].map((office, index) => ({
  id: _mock.id(index),
  country: office,
  address: _mock.address.fullAddress(index),
  phoneNumber: _mock.phoneNumber(index),
  email: _mock.email(index),
  photo: _mock.image.travel(index + 4),
  latlng: LAT_LONG[index],
}));

// ----------------------------------------------------------------------

const BRANDS_NAME = [
  'airbnb',
  'dropbox',
  'facebook',
  'google',
  'heroku',
  'lenovo',
  'microsoft',
  'netflix',
  'slack',
  'spotify',
  'tripadvisor',
  'vimeo',
];

export const _brands = BRANDS_NAME.map((brand, index) => ({
  id: _mock.id(index),
  name: brand,
  image: `/assets/logo/${brand}.svg`,
}));

export const _brandsColor = BRANDS_NAME.map((brand, index) => ({
  id: _mock.id(index),
  name: brand,
  image: `/assets/logo/${brand}_original.svg`,
}));

// ----------------------------------------------------------------------

export const _faqs = [
  'Sed augue ipsum, egestas nec, vestibulum et',
  'alesuada adipiscing, dui vestibulum suscipit nulla quis orci.',
  'Ut varius tincidunt libero',
  'In ut quam vitae odio lacinia tincidunt.',
  'Fusce vel dui Morbi nec metus.',
].map((question, index) => ({
  id: _mock.id(index),
  question,
  answer:
    'Amazing experience i love it a lot. Thanks to the team that dreams come true, great! I appreciate there attitude and approach.',
}));

export const _faqsAccountSupport = [
  'Cannot log in to my account?',
  'How to update my profile?',
  'My account credentials?',
].map((question, index) => ({
  id: _mock.id(index),
  question,
  answer: [
    'Click FORGET PASSWORD. We will send new password to your email link.',
    'Log in to your account. Click on user icon. Change in "Personal Info"',
    `Bronson and CheaLing Enterprise never request any TAC number or Account password from our customer.
    # Please DO NOT give TAC number or Account password to anyone.
    # Please feel free to contact us in case you need any further information concerning this matter.`,
  ][index]
}));

export const _faqsProductSupport = [
  'What if the Product is different from the description?',
  'What do I do if the product is damaged?',
  'What to do during self collection and your order not found?',
].map((question, index) => ({
  id: _mock.id(index),
  question,
  answer: [
    `If you have received something that is damaged or believe it may have been incorrectly listed, do return the product back to our oulet.
    Don't worry, we will get you taken care of by offering you a replacement.
    Simply reach our customer service for futher information. `,
    `If you have received something that is damaged or believe it may have been incorrectly listed, do return the product back to our oulet.
    Don't worry, we will get you taken care of by offering you a replacement.
    Simply reach our customer service for futher information.`,
    `Please provide us your order information such as tracking order number, mobile phone number and etc.
    If we found error in our online order system, we will process your item immediately.
    If your order item not found, please reach our customer service for further information.`,
  ][index]
}));

export const _faqsPaymentSupport = [
  'What types of payment methods available?',
  'Credit cards that are accepted for payment?',
  'What to do If Customer is charged the wrong amount?',
  'Does Bronson and CheaLing Enterprise save my credit card info?',
].map((question, index) => ({
  id: _mock.id(index),
  question,
  answer: [
    ` Cash or Credit card; Bank Transfer; and Online Banking are accepted. Customer can pay by cash or credit card to the cashier that in charge when self collect item. 
      Customer can pay by ATM transfer and make sure to keep the bank invoice receipt.
      Customer can pay by online banking and make sure to save or screenshot the bank invoice receipt on your phone.`,
    `Visa and MasterCard; and Credit & Debit Card are accepted.`,
    `If your order was charged with wrong amount, you can contact us and we will check to figure out whether you were indeed charged the wrong amount.`,
    `We are very concerned about your credit card info and we will never expose your credit card details.
      We will make sure that any credit card transaction is safe for your usage.
      We will always guarantee the security of your information in our system.`,
  ][index]
}));

export const _faqsDeliverySupport = [
  'What types of delivery methods available?',
  'Can I ask others to help me collect?',
  'Any Self-collection Instructions?',
].map((question, index) => ({
  id: _mock.id(index),
  question,
  answer: [
    ` Self-collection on your orders is available. We encourage self-collection by the Purchaser. Choose the “Outlet to collect” option during your check out process, then you will receive a message from us when your order is ready.`,
    `Yes, you can. However, the following criteria must be met. Bronson and CheaLing Enterprise reserves the rights to reject collection by the unauthorized person and/or if the following criteria is not fulfilled -: 1) Purchaser need to provide the authorized person full name (as per MYKAD) and contact number at the “your order” column.; 2) During collection, the authorized person needs to remember the TRACKING NUMBER of the order and present MYKAD for verification to collect the product(s).`,
    ` 1) We will e-mail/call you to inform you the date and time of collection.
    2) Your order will ONLY be available for pickup @ Bronson and CheaLing Enterprise within the period stated in your Invoice E-Mail Notification.
    3) When picking up your order, please present your TRACKING NUMBER at the Cashier Counter.
    4) We are unable to support a change of pickup location.
    5) Should you have any questions about your order, feel free to call us.`,
  ][index]
}));

export const _faqsReturnRefundSupport = [
  'What should I do if I received the wrong product?',
  'How to claim refund?',
].map((question, index) => ({
  id: _mock.id(index),
  question,
  answer: [
    ` You have 3 DAYS to return an item from the date you received it. To be eligible for a return, your item must be unused and in the same condition that you received it. Your item must be in the original packaging. Your item needs to have the receipt or proof of purchase. `,
    `Once we receive your item, we will inspect it and notify you that we have received your returned item. We will immediately notify you on the status of your refund after inspecting the item. If your return is approved, we will initiate a refund to your credit card (or original method of payment). You will receive the credit within a certain amount of days, depending on your card issuer's policies. `,
  ][index]
}));
