import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { Box, Divider, IconButton, MenuItem, Popover, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
// components
import { useAuthContext } from 'src/auth/useAuthContext';
import Notification from 'src/components/Notification/Notification';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { clearChildAction, deleteChildProfile, getChildProfileByParentID } from 'src/redux/slices/profile';
import { useDispatch, useSelector } from 'src/redux/store';
import ChildrenNewEditForm from './ChildrenNewEditForm';
// ----------------------------------------------------------------------

export default function ChildrenDetailCard({ card, index }) {
  const { ActiveInd, ChildID, ChildName, ChildProfileImage, ClassName, ClassNumber, SchoolName } = card;

  const dispatch = useDispatch();

  const { user } = useAuthContext();

  const { isLoading, childAction } = useSelector((state) => state.profile);

  const [open, setOpen] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [isNewAddress, setIsNewAddress] = useState(false);

  const [notification, setNotification] = useState({
    open: false,
    msg: "",
    type: "success",
  });

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleFormClose = () => {
    setOpenForm(false);
    setOpen(null);
  };

  const handleDeleteChild = (selectedChildID) => {
    dispatch(deleteChildProfile(selectedChildID, user?.UserID))
  }

  const handleOpenNewEditForm = () => {
    setOpenForm(true);
    setIsNewAddress(false);
  }

  useEffect(() => {
    if (childAction !== null && childAction.length > 0) {
      setNotification({
        open: true,
        msg: 'Updated Successfully',
        type: 'success',
      });
      dispatch(clearChildAction());
      dispatch(getChildProfileByParentID(user?.UserID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childAction]);

  return (
    <>
      <Stack
        spacing={3}
        sx={{
          p: 3,
          pr: 1,
          borderRadius: 2,
          border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
        }}
        key={ChildID}
      >
        <Stack direction="row" alignItems="center" sx={{ typography: 'subtitle1' }}>
          <Stack direction="row" alignItems="center" flexGrow={1}>
            <Typography variant='subtitle2' sx={{ fontSize: "18px" }}>Child {index + 1}
              {
                ActiveInd === 1 && (
                  <Label color="success" startIcon={<Iconify icon="carbon:star-filled" />} sx={{ ml: 2 }}>
                    Active
                  </Label>
                )
              }
            </Typography>
          </Stack>
          <IconButton onClick={handleOpen}>
            <Iconify icon="carbon:overflow-menu-vertical" />
          </IconButton>
        </Stack>
        <Box>
          <Stack spacing={1}>
            <Typography variant="subtitle2" sx={{ display: "flex", alignItems: "center" }}> <Iconify icon="carbon:user" mr={3} /> {ChildName} </Typography>
            <Typography variant="subtitle2" sx={{ display: "flex", alignItems: "center" }}> <Iconify icon="hugeicons:school" mr={3} /> {SchoolName} </Typography>
            <Typography variant="subtitle2" sx={{ display: "flex", alignItems: "center" }}> <Iconify icon="ic:outline-class" mr={3} /> {ClassName}, Number {ClassNumber} </Typography>
          </Stack>
        </Box>
      </Stack>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { p: 1 },
        }}
      >
        <MenuItem onClick={() => handleOpenNewEditForm()}>
          <Iconify icon="carbon:edit" sx={{ mr: 1 }} /> Edit
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed', mt: 0.5, width: "10vw" }} />

        <MenuItem onClick={() => handleDeleteChild(ChildID)} sx={{ color: 'error.main' }}>
          <Iconify icon="carbon:trash-can" sx={{ mr: 1 }} /> Delete
        </MenuItem>
      </Popover>

      <Notification notification={notification} setNotification={setNotification} />

      <ChildrenNewEditForm open={openForm} onClose={() => handleFormClose()} isNewAddress={isNewAddress} currentChild={card} />
    </>
  );
}

ChildrenDetailCard.propTypes = {
  card: PropTypes.shape({
    ActiveInd: PropTypes.number,
    ChildID: PropTypes.number,
    ChildName: PropTypes.string,
    ChildProfileImage: PropTypes.string,
    ClassID: PropTypes.number,
    ClassName: PropTypes.string,
    ClassNumber: PropTypes.string,
    SchoolName: PropTypes.string,
  }),
  index: PropTypes.number,
};
