import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Stack,
    Button,
    Divider,
    Typography,
    Card, Chip, IconButton,
    Unstable_Grid2 as Grid,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@mui/material';
// components
import TextMaxLine from 'src/components/text-max-line';
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField, RHFSelect } from 'src/components/hook-form';
import Notification from 'src/components/Notification/Notification';
import { addNewAddress, updateUserAddress, clearUserAddressAction } from 'src/redux/slices/profile';
import { getState, getCountry } from 'src/redux/slices/general';
import { useDispatch, useSelector } from 'src/redux/store';
import { useAuthContext } from 'src/auth/useAuthContext';
import LoadingScreen from 'src/components/loading-screen';
import { AddressNewEditForm } from './index';

export default function AddressItem({ address, onCreateBilling, setIsLoading, isloading, selectedAddressID, orderID, viewOnly, handleCallBack, handleGetSelectedAddresID }) {
    const { UserAddressBookID, UserAddressName, UserAddressLine1, UserAddressLine2, UserCity, UserPoscode, UserState, UserContactNo, isDefaultAddress, NSA_ODA, PostOffice } = address;
    const isDefault = isDefaultAddress === 1
    const [openEditForm, setOpenEditForm] = useState(false);
    const [isNewAddress, setIsNewAddress] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const handleEditAddress = () => {
        setOpenEditForm(true);
        setIsNewAddress(false);
    }
    const handleOpenNewEditForm = () => {

        setOpenForm(true);
        setIsNewAddress(true);
        handleCallBack(true)
    }

    const checkDeliveryTime = (ODA) => {
        let duration = 0
        if (ODA !== undefined) {
            duration = ODA.split("+")[1]
        }
        return duration
    }

    // const handleSelectAddress = (data) => {
    //     handleGetSelectedAddresID(address.UserAddressBookID)
    //     onCreateBilling()
    // }
    return (
        <>
            {
                openEditForm ?
                    <AddressNewEditForm open={openEditForm} onClose={() => setOpenEditForm(false)} isNewAddress={isNewAddress} currentAddress={address} />
                    :
                    <Stack
                        spacing={2}
                        justifyContent="center"
                        direction={{
                            xs: 'column',
                            sm: 'column',
                        }}

                    >
                        <Stack direction="column" flexGrow={1} spacing={0}>
                            <Stack direction="row" justifyContent="space-between">
                                <Stack direction="row" alignItems="center" sx={{ textAlign: "left" }}>
                                    {
                                        selectedAddressID === 0 && viewOnly ?
                                            <Typography variant="subtitle1">
                                                {UserAddressName}
                                            </Typography>
                                            :
                                            <>
                                                <IconButton>
                                                    <Iconify icon="solar:user-bold" />
                                                </IconButton>
                                                <Typography variant="subtitle1">
                                                    {UserAddressName}
                                                </Typography>
                                            </>
                                    }

                                    {isDefault && (
                                        <Chip key="Default" label="Default" sx={{ m: 0.5 }} onClick={() => { }} />
                                    )}
                                </Stack>
                                {
                                    !viewOnly && <Iconify icon="carbon:edit" sx={{ mr: 1 }} onClick={handleEditAddress} />
                                }

                            </Stack>

                            <Stack direction="row" alignItems="center" sx={{ textAlign: "left" }}>
                                {
                                    selectedAddressID === 0 && viewOnly ?
                                        <TextMaxLine line={1} variant="body2">{`${UserAddressLine1}`}</TextMaxLine>
                                        :
                                        <>
                                            <IconButton>
                                                <Iconify icon="mdi:address-marker" />
                                            </IconButton> 
                                            <TextMaxLine line={1} variant="body2">{`${UserAddressLine1} , ${UserAddressLine2} , ${UserCity} ${UserState}`}</TextMaxLine>
                                        </>
                                }

                            </Stack>

                            <Stack direction="row" justifyContent="space-between">
                                <Stack direction="row" alignItems="center" sx={{ textAlign: "left" }}>
                                    {
                                        selectedAddressID === 0 && viewOnly ? ""
                                            :
                                            <>
                                                <IconButton>
                                                    <Iconify icon="solar:phone-bold" />
                                                </IconButton>
                                                <Typography variant="body2" sx={{ color: 'text.secondary' }}> {UserContactNo}     </Typography>
                                            </>
                                    }

                                </Stack>
                                {!viewOnly ?
                                    <Stack >
                                        <Button variant="outlined" size="small"
                                            sx={{
                                                backgroundColor: Number(selectedAddressID) === Number(UserAddressBookID) && "primary.main",
                                                color: Number(selectedAddressID) === Number(UserAddressBookID) && "white"
                                            }}
                                            disabled={orderID !== ""}
                                            onClick={() => (<>{handleGetSelectedAddresID(address.UserAddressBookID)}{onCreateBilling(address)}</>)}>
                                            Select
                                        </Button>
                                    </Stack>
                                    :
                                    <Stack justifyContent="flex-end">
                                        <Button
                                            size="small"
                                            disabled={orderID !== ""}
                                            onClick={() => handleOpenNewEditForm()}
                                            endIcon={<Iconify icon="carbon:chevron-right" />}
                                            sx={{ color: "#FA541C" }}
                                        >Change</Button>
                                    </Stack>}

                            </Stack>

                            {
                                NSA_ODA === "Non-Serviceable Area" && <Typography style={{ color: "red" }}>Parcel required self-pickup in nearest post office.</Typography>
                            }
                            {
                                NSA_ODA !== undefined && NSA_ODA !== "Non-Serviceable Area" && NSA_ODA !== null && <Typography style={{ color: "red" }}>Extra {checkDeliveryTime(NSA_ODA)} days is required than normal delivery</Typography>
                            }
                        </Stack>

                        {
                            !viewOnly && <Divider />
                        }

                    </Stack>

            }
        </>

    );
}

AddressItem.propTypes = {
    address: PropTypes.object,
    onCreateBilling: PropTypes.func,
    setIsLoading: PropTypes.func,
    isloading: PropTypes.bool,
    selectedAddressID: PropTypes.number,
    orderID: PropTypes.string,
    viewOnly: PropTypes.bool,
    handleCallBack: PropTypes.func,
    handleGetSelectedAddresID: PropTypes.func,
};
