import PropTypes from 'prop-types';
// @mui
import { Box, Stack, Typography } from '@mui/material';
import Image from 'src/components/image';
import itemNotFound from '../../assets/no-item-found.png';


// ----------------------------------------------------------------------

export default function NoItemFound({ title }) {
  return (
    <Stack
      alignItems="center"
      textAlign="center"
      sx={{ mb: 5 }}
    >
      <Image
        alt="no item found"
        src={itemNotFound}
        sx={{ width: 300, height: 300 }}
      />
      <Typography variant="h5" maxWidth="500px">
        {title}
      </Typography>
    </Stack>
  );
}

NoItemFound.propTypes = {
  title: PropTypes.string,
};
