import PropTypes from 'prop-types';
// @mui
import { Stack, Box, Typography } from '@mui/material';
// utils
import { fCurrency } from 'src/utils/formatNumber';
// components
import Label from 'src/components/label';

// ----------------------------------------------------------------------

export default function ProductPrice({ price, priceSale , discount, percent,isDiscount, row, sx, ...other }) {
  return (
    row === "false" ?
      <>
        {
          percent &&
          <Label color="error" sx={{ mt: 0.5 }}>
            {discount} % OFF
          </Label>
        }
        <Stack direction="row">
          <Typography sx={{ typography: 'subtitle2', ...sx }} > {fCurrency(price === 0 ? "0" : Number(price))} </Typography>
          {
            isDiscount &&
            <Box
              component="span"
              sx={{
                ml: 1.5,
                color: 'text.disabled',
                textDecoration: 'line-through',
                fontWeight: 'fontWeightMedium',
              }}
            >
             <Typography variant='caption'>{Number(priceSale) > 0 && fCurrency(priceSale === 0 ? "0" :  priceSale)}</Typography> 
            </Box>
          }

        </Stack>
      </>
      :
      <Stack direction="row" sx={{ typography: 'subtitle2', ...sx }} {...other}>
        {fCurrency(price === 0 ? "0" : Number(price))}
        {
          isDiscount &&
          <>

            <Box
              component="span"
              sx={{
                ml: 1,
                color: 'text.disabled',
                textDecoration: 'line-through',
                fontWeight: 'fontWeightMedium',
              }}
            >
             <Typography variant='subtitle1'>{Number(priceSale) > 0 && fCurrency(priceSale === 0 ? "0" :  priceSale)}</Typography> 
            </Box>
            {
              percent &&
              <Label color="error" sx={{ ml: 2, mt: 0.5 }}>
                {discount} % OFF
              </Label>
            }

          </>
        }
      </Stack>

  );
}

ProductPrice.propTypes = {
  price: PropTypes.number,
  priceSale: PropTypes.number,
  sx: PropTypes.object,
  discount: PropTypes.number,  
  isDiscount: PropTypes.bool,
  percent: PropTypes.bool,
  row: PropTypes.string,
};
