import PropTypes from 'prop-types';
// @mui
import { Unstable_Grid2 as Grid } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// utils
import { filterStyles } from 'src/utils/cssStyles';
// routes
// components
import Image from 'src/components/image';

// ----------------------------------------------------------------------

export default function MerchantProductItemHero({ product }) {
    const theme = useTheme();

    const isSmUp = useResponsive('up', 'sm');

    const { label, title, ProductMediaDesc, ProductMediaUrl } = product;

    return (


        ProductMediaUrl ?
            <Grid
                container
            >
                <Image
                    src={ProductMediaUrl}
                    sx={{
                        ...filterStyles(
                            `drop-shadow(20px 20px 24px ${alpha(theme.palette.common.black, 0.16)})`
                        ),
                        // maxWidth: 400,
                        width: "100%",
                        height: "500px",
                        ml: 'auto',
                        mr: { xs: 'auto', md: 'unset' },
                    }}
                />
            </Grid >
            :
            <></>


    );
}

MerchantProductItemHero.propTypes = {
    product: PropTypes.shape({
        ProductMediaDesc: PropTypes.string,
        ProductMediaUrl: PropTypes.string,
        label: PropTypes.string,
        title: PropTypes.string,
    }),
};
