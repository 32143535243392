/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
// @mui
import {
  Container,
  Typography,
  Button,
  Unstable_Grid2 as Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Tooltip,
  Stack,
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import Notification from 'src/components/Notification/Notification';
import Countdown from 'src/components/CountDown';
// sections
import { RefreshDialog } from 'src/sections/_travel/tour/components';
import LoadingScreen from 'src/components/loading-screen/LoadingScreen';
// utils

import moment from 'moment';
// import axios from "axios";
import { fCurrency } from 'src/utils/formatNumber';
import { getServiceCharge, RandomString } from 'src/utils/helpers';
import { getComparator, stableSort } from 'src/sections/_e-commerce/account/orders';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
import { ServerConfiguration } from '../../../utils/serverConfig';
// redux
import { getAllPaymentMethod, getCountry, getState } from '../../../redux/slices/general';
import { getUserAddress } from '../../../redux/slices/user';
import { clearUserAddressAction } from '../../../redux/slices/profile';
import {
  getOrderShippingFeesByMerchant,
  clearOrderData,
  addOrder,
  updateOrder,
  // getValidatePromoCode,
  getValidatePromoCodeByMerchant,
  checkCartValidity, clearCartValidity,
  setCartProduct,
  clearPaymentData,
  setPaymentData
} from '../../../redux/slices/order';
import { useDispatch, useSelector } from '../../../redux/store';
import EcommerceCartView from './EcommerceCartView';
import CheckoutSteps from './EcommerceCartCheckoutStep';
import EcommerceCheckoutView from './EcommerceCheckoutView';
import EcommerceCheckoutPaymentView from './EcommerceCheckoutPaymentView';
import EcommerceGuestInformation from './EcommerceGuestInformation';
import EcommerceVoucherList from '../cart/EcommerceVoucherList';

// ----------------------------------------------------------------------

// const CryptoJS = require("crypto-js");

const extraRequestOption = [
  // { value: 'High Floor', label: "I would like a room on a high floor" },
  { value: 'Quiet Room', label: 'I would like a room quiet room' },
  { value: 'Airport Transfer', label: 'I would like to have airport transfer ' },
];

const productStep = ['Cart', 'Delivery & Billing Details', 'Payment'];

const hotelStep = ['Cart', 'Customer Information', 'Billing Details', 'Payment'];

const combinationStep = ['Cart', 'Customer Information', 'Delivery & Billing Details', 'Payment'];

const eventStep = ['Cart', 'Customer Information', 'Billing Details', 'Payment'];

// ----------------------------------------------------------------------

export default function EcommerceCartPageCheckOut() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  // console.log("state", state)
  const { user } = useAuthContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cookies = new Cookies();
  const paymenturl = ServerConfiguration.PaymentUrl
  const { country, paymentMethod } = useSelector((data) => data.general);
  const { address } = useSelector((data) => data.user);
  const { shippingFeesByMerchant, voucherByMerchant, orderAction, cartProduct, isCartValid, paymentData } = useSelector(
    (data) => data.order
  );
  const { addressAction } = useSelector((data) => data.profile);
  // const { ticketValidity } = useSelector((data) => data.event);
  // const [cartProduct, setCartProduct] = useState([]);
  const [cartListing, setCartListing] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const [isCalculateDeliveryFees, setIsCalculateDeliveryFees] = useState(false);
  const [bankSelected, setSelectedBank] = useState('0');
  const [responseHtml, setResponseHtml] = useState(null);
  const [finalAllBankDetails, setfinalAllBankDetails] = useState([]);
  const [methodSelection, setMethodSelection] = useState(9);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(62);
  const [validPromoData, setValidPromoData] = useState({ isChecked: 'false', promoData: [] });
  const [promoDataByMerchant, setPromoDataByMerchant] = useState([]);
  const [hotelGuestData, setHotelGuestData] = useState({
    firstName: '',
    lastName: '',
    contact: '',
    email: '',
    countryID: 148,
    bookForOthersPurpose: false,
    guestContact: '',
    guestFirstName: '',
    guestLastName: '',
    guestCountry: 148,
    smokingPreference: '-',
    bedPreference: '-',
    requiredMoreRequest: false,
    extraRequest: [],
    personalRequest: '-',
    isMalaysian: true,
  });
  const [customerBillingData, setCustomerBillingData] = useState({
    requiredBill: false,
    isSimilar: false,
    UserAddressName: '',
    UserEmail: '',
    UserContactNo: '',
    UserAddressLine1: '',
    UserAddressLine2: '',
    UserPoscode: '',
    UserState: '',
    UserCity: '',
    CountryID: 148,
  });
  const [STEPS, setSteps] = useState(productStep);
  const [cartType, setCarType] = useState('productStep');
  const [promoCode, setPromoCode] = useState('');
  const [orderListing, setOrderListing] = useState('');
  const [IsRequiredRefresh, setIsRequiredRefresh] = useState(2);
  const [hotelInfoDialog, setHotelInfoDialog] = useState({ open: false, info: '', title: '' });
  const [voucherInfoDialog, setVoucherInfoDialog] = useState({
    open: false,
    productID: '',
    merchantID: '',
    title: '',
    productData: [],
  });
  const [notification, setNotification] = useState({ open: false, msg: '', type: 'success' });

  const [summary, setSummary] = useState({
    tax: 0,
    subtotal: 0,
    shipping: 0,
    discount: 0,
    total: 0,
  });
  const [selectedAddressID, setSelectedAddressID] = useState(-1);
  const [currentAddress, setCurrentAddress] = useState([]);
  const [Selfpickup, changeSelfpickup] = useState([
    {
      UserAddressBookID: 0,
      UserAddressName: 'Self-pickup',
      UserAddressLine1: 'Pickup Directly from our store',
      UserAddressLine2: '-',
      UserCity: 'Kuching',
      UserState: 'Sarawak',
      UserEmail: 'helpdesk.myemporia@outlook.com',
      UserPoscode: '93350',
    },
  ]);

  const CallSelectedAddressID = (data) => {
    setSelectedAddressID(data);
  };


  // console.log("activeStep", activeStep)
  // console.log("paymentData", paymentData)
  // console.log("state", state)

  useEffect(() => {
    if (state && state.step === 1) {
      // console.log("wwww")
      setActiveStep(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // useEffect(() => {
  //   if (cookies && cookies.get('email') !== undefined && hotelGuestData.email === '') {
  //     setHotelGuestData({
  //       ...hotelGuestData,
  //       firstName: cookies.get('firstName') !== undefined ? cookies.get('firstName') : '',
  //       lastName: cookies.get('lastName') !== undefined ? cookies.get('lastName') : '',
  //       contact: cookies.get('contact') !== undefined ? cookies.get('contact') : '',
  //       email: cookies.get('email') !== undefined ? cookies.get('email') : '',
  //       countryID: cookies.get('countryID') !== undefined ? cookies.get('countryID') : '',
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [cookies]);

  useEffect(() => {
    if (hotelGuestData.email === '') {

      const data = localStorage.getItem("cartToOrderInfo") && JSON.parse(localStorage.getItem("cartToOrderInfo"))
      if (data?.userId === user?.UserID) {
        setHotelGuestData({
          ...hotelGuestData,
          firstName: data?.firstName && data?.firstName !== undefined ? data?.firstName : '',
          lastName: data?.lastName && data?.lastName !== undefined ? data?.lastName : '',
          contact: data?.contact && data?.contact !== undefined ? data?.contact : '',
          email: data?.email && data?.email !== undefined ? data?.email : '',
          countryID: data?.countryID && data?.countryID !== undefined ? data?.countryID : 148,
        });
      } else {
        setHotelGuestData({
          ...hotelGuestData,
          firstName: user?.FirstName,
          lastName: user?.LastName,
          contact: user?.UserContactNo,
          email: user?.UserEmailAddress,
          countryID: 148,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("cartToOrderInfo")]);

  useEffect(() => {
    if (methodSelection) {
      const pricing = summary;
      let totalPrice = 0;
      let serviceFees = 0;
      const serviceFeesByProduct = [];

      cartProduct.forEach((data) => {
        data.cartList.forEach((x) => {
          if (x.ProductPrice) {

            const customizePrice = x.CartCustomization ? JSON.parse(x.CartCustomization).reduce((subtotal, details) => subtotal + details.CustomizationDetailsPrice, 0) : 0

            totalPrice += (x.ProductPrice + customizePrice) * x.ProductQuantity;

            const tax = state?.isReload !== true && methodSelection === 9 ? getServiceCharge(
              x.CalculationID,
              x.ServiceFeeRate,
              x.priceAfterDiscount || (x.ProductPrice + customizePrice) * x.ProductQuantity,
              methodSelection
            ) : 0;
            serviceFees += tax;
            serviceFeesByProduct.push({
              UserCartID: x.UserCartID,
              MerchantID: x.MerchantID,
              serviceFees: tax,
            });
          }
        });

        // return totalPrice
      });

      if (serviceFees < 1 && state?.isReload !== true && methodSelection === 9) serviceFees = 1;

      setSummary({
        ...pricing,
        tax: serviceFees,
        // tax: 0,
        total: totalPrice + serviceFees,
      });

      const cartListData = [];

      cartProduct.forEach((x) => {
        const cartDetails = [];
        const totalServiceFees = serviceFeesByProduct
          .filter((y) => y.MerchantID === x.MerchantID)
          .reduce((subtotal, item) => subtotal + item.serviceFees, 0);
        x.cartList.forEach((y) => {
          const feesByCart = serviceFeesByProduct
            .filter((z) => z.UserCartID === y.UserCartID)
            .reduce((subtotal, item) => subtotal + item.serviceFees, 0);
          cartDetails.push({
            ...y,
            ServiceFees: feesByCart || (y.ServiceFees ? y.ServiceFees : 0),
          });
        });
        cartListData.push({
          ...x,
          ServiceFees: totalServiceFees,
          cartList: cartDetails,
        });
      });
      dispatch(setCartProduct(cartListData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodSelection]);

  useEffect(() => {
    if (state) {

      dispatch(getAllPaymentMethod());
      dispatch(getUserAddress(user?.UserID));
      dispatch(getCountry());
      dispatch(getState());
      // setCartProduct(state.merchantLising)
      setCartListing(state.cartLising);
      setPromoDataByMerchant([]);
      setPromoCode('');

      if (state.orderData) {
        setOrderListing(state.orderData);
        BillDetailsSetting(state.orderData);
        if (state.cartLising.filter((x) => x.isHotel === 1).length > 0)
          GuestDetailSetting(state.orderData, state.cartLising.filter((x) => x.isHotel === 1)[0]);
      } else {
        setOrderListing('');
      }

      if (state.cartLising.filter((x) => x.isHotel === 1).length > 0) {
        setSteps(hotelStep);
        setCarType('hotelStep');
      } else {
        setSteps(eventStep);
        setCarType('eventStep');
      }

      let totalPrice = 0;
      let serviceFees = 0;
      const serviceFeesByProduct = [];

      state.cartLising.map((x) => {
        if (x.ProductPrice) {

          const customizePrice = x.CartCustomization ? JSON.parse(x.CartCustomization).reduce((subtotal, data) => subtotal + data.CustomizationDetailsPrice, 0) : 0

          totalPrice += (x.ProductPrice + customizePrice) * x.ProductQuantity;
          const tax = state?.isReload !== true && methodSelection === 9 ? getServiceCharge(
            x.CalculationID,
            x.ServiceFeeRate,
            (x.ProductPrice + customizePrice) * x.ProductQuantity,
            methodSelection
          ) : 0;
          serviceFees += tax;
          serviceFeesByProduct.push({
            UserCartID: x.UserCartID,
            MerchantID: x.MerchantID,
            serviceFees: tax,
          });
        }
        return totalPrice;
      });


      if (serviceFees < 1 && state?.isReload !== true && methodSelection === 9) 
        serviceFees = 1;

      setSummary({
        // tax: totalPrice * 0.06,
        tax: serviceFees,
        // tax: 0,
        subtotal: totalPrice,
        shipping: 0,
        discount: 0,
        // total: totalPrice * 1.06
        total: totalPrice + serviceFees,
      });

      const cartListData = [];
      state.merchantLising.forEach((x) => {
        const cartDetails = [];
        const totalServiceFees = serviceFeesByProduct
          .filter((y) => y.MerchantID === x.MerchantID)
          .reduce((subtotal, item) => subtotal + item.serviceFees, 0);
        x.cartList.forEach((y) => {
          const feesByCart = serviceFeesByProduct
            .filter((z) => z.UserCartID === y.UserCartID)
            .reduce((subtotal, item) => subtotal + item.serviceFees, 0);
          cartDetails.push({
            ...y,
            ServiceFees: feesByCart || (y.ServiceFees ? y.ServiceFees : 0),
          });
        });

        const sortByOrderDate = stableSort(cartDetails, getComparator('asc', 'CheckinDate23'))
        const sortByChild = stableSort(sortByOrderDate, getComparator('asc', 'RelatedID'))


        cartListData.push({
          ...x,
          ServiceFees: totalServiceFees,
          cartList: sortByChild,
        });
      });

      dispatch(setCartProduct(cartListData));

      if (state.orderData && state.orderData.PromoCodeID !== 0) {
        setPromoCode(state.orderData.PromoCode);
        handleVoucherCode(state.cartLising, false);
        const productIDListing = state.cartLising
          .filter((x) => x.isHotel === 0)
          .map((y) => y.ProductID);
        dispatch(
          getValidatePromoCodeByMerchant(
            state.orderData.PromoCode,
            state.merchantLising[0].MerchantID,
            productIDListing
          )
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state, navigate]);

  useEffect(() => {
    if (activeStep === 2) {
      const userDiscount = user?.AgentInd === 1 ? user?.AgentDiscount || 0 : 0;
      const totalAmount = summary.subtotal + summary.shipping - summary.discount + summary.tax - (userDiscount / 100) * summary.subtotal;
      const sPaytotalAmount = summary.subtotal + summary.shipping - summary.discount - (userDiscount / 100) * summary.subtotal;
      const random = `${RandomString(5)}${moment(new Date()).format("YYYYMMDDHHmmss").toString()}${RandomString(5)}`

      const formdata = new FormData();
      formdata.append('order_id', random);
      formdata.append('name', `${hotelGuestData?.firstName} ${hotelGuestData?.lastName}`);
      formdata.append('email', hotelGuestData?.email);
      formdata.append('phone', hotelGuestData?.contact);
      formdata.append('amount', totalAmount.toFixed(2));
      formdata.append('detail', `Bronson Payment For Order TRS${random}`);

      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };

      fetch(`${paymenturl}payment.php`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          if (result) {
            if (JSON.parse(result)?.order_id) {
              const obj = JSON.parse(result);
              const paymentObj = {
                merchantId: "M100065652",
                curType: "RM",
                notifyURL: `${paymenturl}spaycallback.php?merOrderNo=${random}`,
                merOrderNo: random,
                orderAmt: sPaytotalAmount.toFixed(2),
                goodsName: `Bronson Payment For Order TRS${random}`,
                detailURL: `${paymenturl}spaycallback.php?merOrderNo=${random}`,
                transactionType: '1',
                remark: `Bronson Payment For Order TRS${random}`,
                successUrl:`${paymenturl}spaycallback.php?merOrderNo=${random}`,
              }

              const requestOptionsSPay2 = {
                method: 'POST',
                body: JSON.stringify(paymentObj),
                headers: {
                  'Content-Type': 'application/json'
                },
                redirect: 'follow',
              };
              console.log(obj)
              // console.log(obj2)
              fetch(`${paymenturl}/index.php`, requestOptionsSPay2)
                .then((response) => response.text())
                .then((result2) => {
                  if (result2) {
                    if (result2 && JSON.parse(result2).status === "success") {
                      const obj2 = JSON.parse(result2);
                      console.log(obj)
                      console.log(obj2)
                      dispatch(setPaymentData({...obj,...obj2.data,encryptData: obj2.encryptedData}))
                    }
                  }
                })
                .catch((error) => console.error(error));
            }
          }
        })
        .catch((error) => console.error(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (shippingFeesByMerchant && shippingFeesByMerchant.length > 0) {
      const shippingData = [];
      shippingFeesByMerchant.map((y) => shippingData.push(JSON.parse(y.Shipping)[0]));

      const productListing = [];
      cartProduct.forEach((x, index) => {
        let merchantShippingData = [];
        merchantShippingData = shippingData.filter((y) => y.MerchantID === x.MerchantID);
        productListing.push({
          ...x,
          merchantShippingData: merchantShippingData.length > 0 ? merchantShippingData[0] : {},
          merchantShippingCost:
            merchantShippingData.length > 0 ? merchantShippingData[0].ShippingCost : 0,
        });
      });

      const totals = productListing.reduce(
        (subtotal, item) => subtotal + item.merchantShippingCost,
        0
      );

      const pricing = summary;
      setSummary({
        ...pricing,
        shipping: totals,
      });
      dispatch(clearOrderData());
      dispatch(setCartProduct(productListing));
      setIsCalculateDeliveryFees(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingFeesByMerchant, dispatch, summary]);

  useEffect(() => {
    console.log("isCartValid",isCartValid)
    if (isCartValid) {
      switch (isCartValid?.isRequiredRefresh) {
        case 0:
          dispatch(clearCartValidity());
          if (activeStep >= 2)
            console.log("isCartValid",1)
            onSubmitOrder();
          break;

        case 1:
          setIsRequiredRefresh(1);
          dispatch(clearCartValidity());
          setRefresh(true);
          break;

        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartValid]);

  useEffect(() => {
    if (voucherByMerchant && voucherByMerchant.length >= 0) {
      const promoData = [...promoDataByMerchant];

      if (
        promoDataByMerchant.filter((x) => x.MerchantID === voucherInfoDialog.MerchantID).length > 0
      ) {
        promoDataByMerchant.forEach((x, index) => {
          if (x.MerchantID === voucherInfoDialog.MerchantID) {
            promoData[index] = voucherByMerchant[0];
            setPromoDataByMerchant(promoData);
          }
        });
      } else {
        promoData.push(voucherByMerchant[0]);
        setPromoDataByMerchant(promoData);
      }
      dispatch(clearOrderData());

      if (state.orderData && state.orderData.PromoCodeID !== 0)
        handleConfirmApplyVoucher(promoData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherByMerchant, dispatch, summary]);

  useEffect(() => {
    if (state && summary.total !== 0) {
      // For New Order
      if (address.length > 0 && orderListing === '') {
        let filterData = address.filter((x) => x.isDefaultAddress === 1);
        if (filterData.length > 0) onCreateBilling(filterData[0]);
        else {
          filterData = address[0];
          onCreateBilling(filterData);
        }
      }
      if (address.length === 0 && orderListing === '') {
        onCreateBilling(Selfpickup[0]);
      }

      // For Pending Payment Order
      if (orderListing.UserAddresID !== 0 && orderListing !== '') {
        if (orderListing.UserAddresID === 0) {
          onCreateBilling(Selfpickup[0]);
        } else {
          const orderAddress = {
            UserID: orderListing.UserID,
            UserAddressBookID: orderListing.UserAddresID,
            UserAddressName: orderListing.UserFullName,
            UserAddressLine1: orderListing.UserAddressLine1,
            UserAddressLine2: orderListing.UserAddressLine2,
            UserCity: orderListing.UserCity,
            UserState: orderListing.UserState,
            UserEmail: orderListing.UserEmailAddress,
            UserPoscode: orderListing.UserPoscode,
            UserContactNo: orderListing.UserContactNo,
            CountryID: orderListing.CountryID,
          };
          onCreateBilling(orderAddress);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, state]);

  useEffect(() => {
    if (addressAction.length > 0) {
      dispatch(getUserAddress(user?.UserID));
      dispatch(clearUserAddressAction());
      setNotification({
        open: true,
        msg: 'Address Information Updated Successfully',
        type: 'success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressAction]);

  const GuestDetailSetting = (data, hotelData) => {
    const list = hotelData.ExtraRequest === '-' ? [] : hotelData.ExtraRequest.split(',');
    const filterListing = [];
    list.map((x) =>
      extraRequestOption.filter((y) => y.value === x).map((details) => filterListing.push(details))
    );

    setHotelGuestData({
      firstName: data.UserFirstName_Hotel,
      lastName: data.UserLastName_Hotel,
      contact: data.UserContact_Hotel,
      email: data.UserEmail_Hotel,
      countryID: Number(data.UserCountryID_Hotel),
      bookForOthersPurpose: data.Hotel_isBookForOtherInd,
      guestContact: data.UserContact_HotelGuest,
      guestFirstName: data.UserFirstName_HotelGuest,
      guestLastName: data.UserLastName_HotelGuest,
      guestCountry: data.UserCountryID_HotelGuest,
      smokingPreference: hotelData.SmokingPreference === '-' ? '' : hotelData.SmokingPreference,
      bedPreference: hotelData.BedPreference === '-' ? '' : hotelData.BedPreference,
      requiredMoreRequest: !(hotelData.SpecialRequest === '-' && hotelData.ExtraRequest === '-'),
      extraRequest: filterListing,
      personalRequest: hotelData.SpecialRequest,
      isMalaysian: data.Hotel_isMalaysianInd,
    });
  };

  const BillDetailsSetting = (data) => {
    setCustomerBillingData({
      requiredBill: data.isBillRequiredInd,
      isSimilar: false,
      UserAddressName: data.BillingName,
      UserEmail: data.BillingEmail,
      UserContactNo: data.BillingContact,
      UserAddressLine1: data.BillingAddress1,
      UserAddressLine2: data.BillingAddress2,
    });
  };

  const handleNextStep = (data) => {
    if (data === 'check' && selectedAddress === '') {
      if (customerBillingData.requiredBill) {
        setSelectedAddress({
          UserEmail: customerBillingData.UserEmail === '' ? '-' : customerBillingData.UserEmail,
          UserAddressLine1:
            customerBillingData.UserAddressLine1 === ''
              ? '-'
              : customerBillingData.UserAddressLine1,
          UserCity: customerBillingData.UserCity === '' ? '-' : customerBillingData.UserCity,
          UserPoscode: customerBillingData.UserPoscode ? '-' : customerBillingData.UserPoscode,
          UserState: customerBillingData.UserState ? '-' : customerBillingData.UserState,
          UserAddressBookID: 0,
        });
      } else {
        setSelectedAddress({
          UserEmail: hotelGuestData.email,
          UserAddressLine1: '-',
          UserCity: '-',
          UserPoscode: '-',
          UserState: '-',
          UserAddressBookID: 0,
        });
      }
    }
    setActiveStep(activeStep + 1);
  };

  const handleBackStep = () => {
    setMethodSelection(9);
    setSelectedMethod(62)
    // setfpx_information({ ...fpx_information, fpx_checkSum: '' });
    // setcard_information({ ...card_information, signature: '' });
    // setSelectedBank('0');
    setActiveStep(activeStep - 1);
  };

  const handleSelection = (value, PaymentmethodID) => {
    setMethodSelection(Number(value));
    setSelectedMethod(Number(PaymentmethodID))
    // setfpx_information({ ...fpx_information, fpx_checkSum: '' });
    // setcard_information({ ...card_information, signature: '' });
    // setSelectedBank('0');
  };


  const onCreateBilling = (data) => {
    setSelectedAddressID(data.UserAddressBookID);
    setCurrentAddress([data]);
    setSelectedAddress(data);

    const setEmptyShipping = () => {
      const pricing = summary;
      const productListing = [];
      cartProduct.forEach((x, index) => {
        productListing.push({
          ...x,
          merchantShippingData: {},
          merchantShippingCost: 0,
        });
      });

      setSummary({
        ...pricing,
        shipping: 0,
      });
      setIsCalculateDeliveryFees(true);
      if (productListing.length > 0) {
        dispatch(setCartProduct(productListing));
      }
    };

    if (data.UserAddressBookID === 0) {
      // setEmptyShipping();
    } else {
      let productIDByMerchant = [];
      let productQuantityByMerchant = [];

      state.merchantLising.forEach((x, xIndex) => {
        let isHotel = true;
        x.cartList
          .filter((y) => y.isHotel === 0 && y.isEvent === 0 && y.EventMerchandiseInd === 0)
          .forEach((z, index) => {
            productIDByMerchant += z.ProductID;
            productQuantityByMerchant += z.ProductQuantity;
            isHotel = false;

            if (index !== x.cartList.filter((y) => y.isHotel === 0).length - 1) {
              productIDByMerchant += ',';
              productQuantityByMerchant += ',';
            }
          });

        if (isHotel === false && xIndex !== state.merchantLising.length - 1) {
          productIDByMerchant += ';';
          productQuantityByMerchant += ';';
        }
      });

      if (productIDByMerchant.length > 0)
        dispatch(
          getOrderShippingFeesByMerchant(
            productIDByMerchant,
            productQuantityByMerchant,
            data.UserPoscode
          )
        );
      else setEmptyShipping();
      // setIsCalculateDeliveryFees(true)
    }
  };

  const handlePromoAvailability = (data) => {
    const promoData = [];
    const variationData = [];
    const productQuantity = [];
    const startDate = [];
    const endDate = [];
    const accessData = [];

    cartProduct.forEach((x) => {
      promoData.push(x.PromotionID);
      variationData.push(x.ProductVariationDetailID);
      productQuantity.push(x.ProductQuantity);
      startDate.push(moment(x.CheckinDate).format("YYYY-MM-DD"));
      endDate.push(moment(x.CheckoutDate).format("YYYY-MM-DD"));
      accessData.push(x.ACCESSCATEGORYID);
    });

      if (state?.isReload === true) {
        onSubmitOrder();
      } else {
        dispatch(checkCartValidity(promoData, variationData, productQuantity, startDate, endDate, accessData));
      }
    


    // const filteredListing = cartProduct.filter((x) => x.isEvent === 1);
    // if (filteredListing.length > 0) {
    //   const promoData = [];
    //   const ticketData = [];
    //   const ticketQuantity = [];

    //   filteredListing.forEach((x) => {
    //     promoData.push(x.PromotionID);
    //     ticketData.push(x.ProductVariationDetailID);
    //     ticketQuantity.push(x.ProductQuantity);
    //   });
    //   setSelectedMethod(data);
    //   dispatch(checkEventTicketValidity(promoData, ticketData, ticketQuantity));
    // } else {
    //   onSubmitOrder(data);
    // }
  };

  function onSubmitOrder() {
    let ProductID_Arr = '';
    let UserCartID_Arr = [];
    let ProductQuantity_Arr = [];
    let ProductVariationDetailID_Arr = [];
    let isHotel_Arr = [];
    let hotelStartDate_Arr = [];
    let hotelEndDate_Arr = [];
    let bedQuantity_Arr = [];
    let breakfastQuantity_Arr = [];
    let specialRequest_Arr = [];
    let smokingPreference_Arr = [];
    let bedPreference_Arr = [];
    let extraRequest_Arr = [];
    let hotelGuestContact_Arr = [];
    let hotelGuestFirstName_Arr = [];
    let hotelGuestLastName_Arr = [];
    let hotelGuestCountryID_Arr = [];
    let hotelUserContact_Arr = [];
    let hotelUserFirstName_Arr = [];
    let hotelUserLastName_Arr = [];
    let hotelUserCountryID_Arr = [];
    let hotelUserEmail_Arr = [];
    let hotelIsBookForOther_Arr = [];
    let hotelIsMalaysianInd_Arr = [];
    let promoCodeID_Arr = [];
    let promoCode_Arr = [];
    let promoCodeDiscount_Arr = [];
    let remark_Arr = [];
    let orderTotalAmount_Arr = [];
    let agentDiscount_Arr = [];
    let orderPaidAmount_Arr = [];
    let orderShippingFee_Arr = [];
    let orderTax_Arr = [];
    let accessCategoryID_Arr = [];
    // let topUpInd_Arr = [];

    const PromoID_Arr = 0;
    const OrderID_Arr = orderListing === '' ? 0 : orderListing.OrderID;

    let isContainEvent_Arr = [];
    let eventEndTime_Arr = [];
    let eventStartTime_Arr = [];
    let isEvent_Arr = [];
    let customize_Arr = [];
    let relatedType_Arr = [];
    let relatedID_Arr = [];

    let isDefaultTax = false;

    const userDiscount = user?.AgentInd === 1 ? user?.AgentDiscount || 0 : 0;

    if (state?.isReload !== true && methodSelection === 9 && cartProduct.reduce((subtotal, item) => subtotal + item.ServiceFees, 0) < 1)
      isDefaultTax = true;

    cartProduct.forEach((y, yIndex) => {
      y.cartList.forEach((x, index) => {

        const customize = JSON.parse(x.CartCustomization)
        if (customize.length > 0) {
          customize.forEach((details, detailIdx) => {
            customize_Arr += details.CustomizationDetailsID

            if (detailIdx !== customize.length - 1) {
              customize_Arr += '*'
            }
          }
          )
        } else {
          customize_Arr += '-'
        }

        relatedType_Arr += x.RelatedTypeID;
        relatedID_Arr += x.RelatedID;
        ProductID_Arr += x.ProductID;
        UserCartID_Arr += x.UserCartID;
        ProductQuantity_Arr += x.ProductQuantity;
        ProductVariationDetailID_Arr += x.ProductVariationDetailID;
        isHotel_Arr += x.isHotel;

        hotelStartDate_Arr += moment(x.CheckinDate).format('YYYY-MM-DD');
        hotelEndDate_Arr += moment(x.CheckoutDate).format('YYYY-MM-DD');

        bedQuantity_Arr += '0';
        breakfastQuantity_Arr += '0';

        const specialRequest = x.isHotel === 1 ? hotelGuestData?.personalRequest || '-' : '-';
        specialRequest_Arr += specialRequest;

        const smokingRequest = x.isHotel === 1 ? hotelGuestData?.smokingPreference || '-' : '-';
        smokingPreference_Arr += smokingRequest;

        const bedRequest = x.isHotel === 1 ? hotelGuestData?.bedPreference || '-' : '-';
        bedPreference_Arr += bedRequest;

        let extraRequestArr = '';
        if (hotelGuestData.extraRequest.length > 0)
          hotelGuestData.extraRequest.forEach((k, i) => {
            extraRequestArr += k.value;
            if (i !== hotelGuestData.length - 1) extraRequestArr += ',';
          });
        else extraRequestArr = '-';

        const extraRequest = x.isHotel === 1 ? extraRequestArr : '-';
        extraRequest_Arr += extraRequest;
        isEvent_Arr += x.isEvent;
        eventEndTime_Arr += '00:00';
        eventStartTime_Arr += '00:00';
        accessCategoryID_Arr += x.ACCESSCATEGORYID;

        if (index !== y.cartList.length - 1) {
          relatedType_Arr += ',';
          relatedID_Arr += ',';
          ProductID_Arr += ',';
          UserCartID_Arr += ',';
          ProductQuantity_Arr += ',';
          ProductVariationDetailID_Arr += ',';
          isHotel_Arr += ',';
          hotelStartDate_Arr += ',';
          hotelEndDate_Arr += ',';
          bedQuantity_Arr += ',';
          breakfastQuantity_Arr += ',';
          specialRequest_Arr += ',';
          smokingPreference_Arr += ',';
          bedPreference_Arr += ',';
          extraRequest_Arr += ';';
          isEvent_Arr += ',';
          eventEndTime_Arr += ',';
          eventStartTime_Arr += ',';
          accessCategoryID_Arr += ',';
          customize_Arr += ',';
        }
      });

      hotelGuestContact_Arr +=
        hotelGuestData.bookForOthersPurpose === 1
          ? hotelGuestData?.guestContact || '-'
          : hotelGuestData?.contact || '-';
      hotelGuestFirstName_Arr +=
        hotelGuestData.bookForOthersPurpose === 1
          ? hotelGuestData?.guestFirstName || '-'
          : hotelGuestData?.firstName || '-';
      hotelGuestLastName_Arr +=
        hotelGuestData.bookForOthersPurpose === 1
          ? hotelGuestData?.guestLastName || '-'
          : hotelGuestData?.lastName || '-';
      hotelGuestCountryID_Arr +=
        hotelGuestData.bookForOthersPurpose === 1
          ? hotelGuestData?.guestCountry || 0
          : hotelGuestData?.countryID || 0;
      hotelUserContact_Arr += hotelGuestData?.contact || '-';
      hotelUserFirstName_Arr += hotelGuestData?.firstName || '-';
      hotelUserLastName_Arr += hotelGuestData?.lastName || '-';
      hotelUserCountryID_Arr += hotelGuestData?.countryID || 0;
      hotelUserEmail_Arr += hotelGuestData?.email || '-';
      hotelIsBookForOther_Arr += hotelGuestData.bookForOthersPurpose ? 1 : 0;
      hotelIsMalaysianInd_Arr += hotelGuestData.isMalaysian ? 1 : 0;


      promoCodeID_Arr += y.PromoCodeID;
      promoCode_Arr += y.PromoCode === '' ? '-' : y.PromoCode;
      promoCodeDiscount_Arr += y.PromoCodeDiscount;
      remark_Arr += y.Remarks === '' ? '-' : y.Remarks;

      const summationAmount = parseFloat(
        y.cartList.reduce(
          (subtotal, item) => subtotal + (item.ProductPrice + JSON.parse(item.CartCustomization).reduce((subdetails, details) => subdetails + details.CustomizationDetailsPrice, 0)) * item.ProductQuantity,
          0
        ) +
        (y.merchantShippingCost === undefined ? 0 : y.merchantShippingCost) -
        y.PromoCodeDiscount
        + (isDefaultTax ? 1 / cartProduct.length : y.ServiceFees)
      ).toFixed(2);

      orderTotalAmount_Arr += parseFloat(
        summationAmount - (userDiscount / 100) * summationAmount
      ).toFixed(2);
      agentDiscount_Arr += parseFloat((userDiscount / 100) * summationAmount).toFixed(2);
      orderPaidAmount_Arr += 0;
      orderShippingFee_Arr += y?.merchantShippingCost || 0;
      // orderTax_Arr += 0
      orderTax_Arr += isDefaultTax ? 1 / cartProduct.length : y.ServiceFees;
      // orderTax_Arr += parseFloat((y.cartList.reduce((subtotal, item) => subtotal + item.ProductPrice * item.ProductQuantity, 0) + y.merchantShippingCost - y.PromoCodeDiscount)*0.06).toFixed(2)
      isContainEvent_Arr += y.cartList.filter((x) => x.isEvent === 1).length > 0 ? 1 : 0;

      // topUpInd_Arr += state?.isReload === true ? 1 : 0

      if (yIndex !== cartProduct.length - 1) {
        promoCodeID_Arr += ';';
        promoCode_Arr += ';';
        promoCodeDiscount_Arr += ';';
        remark_Arr += ';';
        orderTotalAmount_Arr += ';';
        agentDiscount_Arr += ';';
        orderPaidAmount_Arr += ';';
        orderShippingFee_Arr += ';';
        orderTax_Arr += ';';
        // topUpInd_Arr += ';';

        // ---------------------------------
        relatedType_Arr += ';';
        relatedID_Arr += ';';
        ProductID_Arr += ';';
        UserCartID_Arr += ';';
        ProductQuantity_Arr += ';';
        ProductVariationDetailID_Arr += ';';
        isHotel_Arr += ';';
        hotelStartDate_Arr += ';';
        hotelEndDate_Arr += ';';
        bedQuantity_Arr += ';';
        breakfastQuantity_Arr += ';';
        specialRequest_Arr += ';';
        smokingPreference_Arr += ';';
        bedPreference_Arr += ';';
        extraRequest_Arr += '*';
        hotelGuestContact_Arr += ';';
        hotelGuestFirstName_Arr += ';';
        hotelGuestLastName_Arr += ';';
        hotelGuestCountryID_Arr += ';';
        hotelUserContact_Arr += ';';
        hotelUserFirstName_Arr += ';';
        hotelUserLastName_Arr += ';';
        hotelUserCountryID_Arr += ';';
        hotelUserEmail_Arr += ';';
        hotelIsBookForOther_Arr += ';';
        hotelIsMalaysianInd_Arr += ';';
        isEvent_Arr += ';';
        eventEndTime_Arr += ';';
        eventStartTime_Arr += ';';
        isContainEvent_Arr += ';';
        accessCategoryID_Arr += ';';

        customize_Arr += ';'
      }
    });

    const defaultData = {
      OrderID: OrderID_Arr,
      UserID: user?.UserID,
      ProductID: ProductID_Arr,
      ProductQuantity: ProductQuantity_Arr,
      UserCartID: UserCartID_Arr,
      UserAddressID: selectedAddress.UserAddressBookID,
      PaymentMethodID: selectedMethod,
      UserPaymentMethodID: methodSelection,
      OrderTotalAmount: orderTotalAmount_Arr,
      AgentDiscount: agentDiscount_Arr,
      OrderPaidAmount: orderPaidAmount_Arr,
      OrderShippingFee: orderShippingFee_Arr,
      OrderTax: orderTax_Arr,
      ProductVariationDetailID: ProductVariationDetailID_Arr,
      TrackingStatusID: 2,
      PickUpInd: selectedAddress.UserAddressBookID === 0 ? 1 : 0,
      PromotionID: PromoID_Arr,
      PromotionCodeID: promoCodeID_Arr,
      UserContact_Hotel: hotelUserContact_Arr,
      UserFirstName_Hotel: hotelUserFirstName_Arr,
      UserLastName_Hotel: hotelUserLastName_Arr,
      UserEmail_Hotel: hotelUserEmail_Arr,
      UserCountryID_Hotel: hotelUserCountryID_Arr,
      UserFirstName_HotelGuest: hotelGuestFirstName_Arr,
      UserLastName_HotelGuest: hotelGuestLastName_Arr,
      UserContact_HotelGuest: hotelGuestContact_Arr,
      UserCountryID_HotelGuest: hotelGuestCountryID_Arr,
      Hotel_isBookForOtherInd: hotelIsBookForOther_Arr,
      Hotel_isMalaysianInd: hotelIsMalaysianInd_Arr,
      PromoCodeID_Hotel: 0,
      isHotel: isHotel_Arr,
      hotelStartDate: hotelStartDate_Arr,
      hotelEndDate: hotelEndDate_Arr,
      bedQuantity: bedQuantity_Arr,
      breakfastQuantity: breakfastQuantity_Arr,
      specialRequest: specialRequest_Arr,
      Hotel_smokingPreference: smokingPreference_Arr,
      Hotel_bedPreference: bedPreference_Arr,
      Hotel_extraRequest: extraRequest_Arr,
      billingName: customerBillingData?.UserAddressName || '-',
      billingAdd1: customerBillingData?.UserAddressLine1 || '-',
      billingAdd2: customerBillingData?.UserAddressLine2 || '-',
      billingContact: customerBillingData?.UserContactNo || '-',
      billingEmail: customerBillingData?.UserEmail || '-',
      isBillRequiredInd: customerBillingData.requiredBill ? 1 : 0,
      PromoCode: promoCode_Arr,
      PromoCodeDiscount: promoCodeDiscount_Arr,
      Remarks: remark_Arr,
      Event_isEvent: isEvent_Arr,
      // Event_isEvent: isEvent_Arr,
      Event_startTime: eventStartTime_Arr,
      Event_endTime: eventEndTime_Arr,
      Event_isContainEvent: isContainEvent_Arr,
      // eslint-disable-next-line no-nested-ternary
      orderOrigin: state?.isFromBookLah ? 'app' : state?.isFromEmporiaApp ? 'emporiaApp' : 'web',
      ACCESSCATEGORYID: accessCategoryID_Arr,
      Customization: customize_Arr,
      relatedId: relatedID_Arr,
      relatedTypeId: relatedType_Arr,
      TopUpInd: state?.isReload === true ? 1 : 0
    };

    const object = {
      ...defaultData,
      TransactionUUID: paymentData?.order_id,
    };

    if (object.OrderID !== 0) dispatch(updateOrder(object));
    else dispatch(addOrder(object));
  }

  const handleCheckPromo = () => {
    if (voucherInfoDialog.info !== '') {
      dispatch(
        getValidatePromoCodeByMerchant(
          promoCode,
          voucherInfoDialog.merchantID,
          voucherInfoDialog.productID
        )
      );
    }
  };

  const handlePromoCode = (data) => {
    setPromoCode(data);
  };

  const handleHotelInfoDialog = (data) => {
    setHotelInfoDialog({
      open: true,
      title: 'Booking Information',
      info: data,
    });
  };

  const handleVoucherCode = (product, isOpen) => {
    setVoucherInfoDialog({
      open: isOpen,
      title: `${product[0].MerchantShopName} vouchers`,
      merchantID: product[0].MerchantID,
      productID: product.filter((x) => x.isHotel === 0).map((y) => y.ProductID),
      productData: product,
    });
  };

  const handlePage = (type) => {
    let returnPage = '';
    switch (type) {
      case 'EcommerceCartView':
        returnPage = (
          <EcommerceCartView
            summary={summary}
            handleRemark={handleRemark}
            checkPromoData={checkPromoData}
            handleVoucherCode={handleVoucherCode}
            cartProduct={cartProduct}
            cartListing={cartListing}
            handleNext={handleNextStep}
            promoCode={promoCode}
            handleCheckPromo={handleCheckPromo}
            handlePromoCode={handlePromoCode}
            validPromoData={validPromoData}
            setHotelInfoDialog={handleHotelInfoDialog}
            orderID={orderListing !== '' ? String(orderListing.OrderID) : ''}
            parsedData={state?.parsedUrlData || ''}
          />
        );
        break;

      case 'EcommerceCheckoutView':
        returnPage = (
          <EcommerceCheckoutView
            checkPromoData={checkPromoData}
            summary={summary}
            country={country}
            address={address}
            cartProduct={cartProduct}
            cartListing={cartListing}
            isCalculateDeliveryFees={isCalculateDeliveryFees}
            handleNext={handleNextStep}
            handlePrevious={handleBackStep}
            onCreateBilling={onCreateBilling}
            promoCode={promoCode}
            handleCheckPromo={handleCheckPromo}
            handlePromoCode={handlePromoCode}
            validPromoData={validPromoData}
            customerBillingData={customerBillingData}
            setCustomerBillingData={setCustomerBillingData}
            orderID={orderListing !== '' ? String(orderListing.OrderID) : ''}
            cartType={cartType}
            setHotelInfoDialog={handleHotelInfoDialog}
            selectedAddress={selectedAddress}
            currentAddress={currentAddress}
            selectedAddressID={selectedAddressID}
            setSelectedAddressID={setSelectedAddressID}
            CallSelectedAddressID={CallSelectedAddressID}
            isReload={state?.isReload || false}
          />
        );
        break;

      case 'EcommerceGuestInformation':
        returnPage = (
          <EcommerceGuestInformation
            checkPromoData={checkPromoData}
            summary={summary}
            country={country}
            hotelGuestData={hotelGuestData}
            setHotelGuestData={setHotelGuestData}
            address={address}
            cartProduct={cartProduct}
            cartListing={cartListing}
            isCalculateDeliveryFees={isCalculateDeliveryFees}
            handleNext={handleNextStep}
            handlePrevious={handleBackStep}
            onCreateBilling={onCreateBilling}
            promoCode={promoCode}
            handleCheckPromo={handleCheckPromo}
            handlePromoCode={handlePromoCode}
            validPromoData={validPromoData}
            orderID={orderListing !== '' ? String(orderListing.OrderID) : ''}
            cartType={cartType}
            setHotelInfoDialog={handleHotelInfoDialog}
            extraRequestOption={extraRequestOption}
            isReload={state?.isReload || false}
          />
        );
        break;

      case 'EcommerceCheckoutPaymentView':

        if (!paymentData) returnPage = (<LoadingScreen />)
        else
          returnPage = (
            <EcommerceCheckoutPaymentView
              checkPromoData={checkPromoData}
              summary={summary}
              address={selectedAddress}
              cartProduct={cartProduct}
              cartListing={cartListing}
              handleNext={handleNextStep}
              handlePrevious={handleBackStep}
              handleSelection={handleSelection}
              methodSelection={methodSelection}
              // handleBanking={handleBanking}
              // bankSelected={bankSelected}
              // bankOptions={finalAllBankDetails}
              // card_information={card_information}
              // fpx_information={fpx_information}
              onSubmitOrder={handlePromoAvailability}
              promoCode={promoCode}
              handleCheckPromo={handleCheckPromo}
              handlePromoCode={handlePromoCode}
              validPromoData={validPromoData}
              orderID={orderListing !== '' ? String(orderListing.OrderID) : ''}
              cartType={cartType}
              setHotelInfoDialog={handleHotelInfoDialog}
              IsAvailablePromo={IsRequiredRefresh}
              isReload={state?.isReload || false}
            />
          );
        break;

      default:
        break;
    }
    return returnPage;
  };

  const handleCartType = () => {
    let cartStep = '';
    switch (cartType) {
      case 'productStep':
        cartStep = (
          <>
            {activeStep === 0 && handlePage('EcommerceCartView')}
            {activeStep === 1 && handlePage('EcommerceCheckoutView')}
            {activeStep === 2 && handlePage('EcommerceCheckoutPaymentView')}
          </>
        );
        break;

      default:
        cartStep = (
          <>
            {activeStep === 0 && handlePage('EcommerceCartView')}
            {activeStep === 1 && handlePage('EcommerceGuestInformation')}
            {activeStep === 2 && handlePage('EcommerceCheckoutView')}
            {activeStep === 3 && handlePage('EcommerceCheckoutPaymentView')}
          </>
        );
        break;
    }
    return cartStep;
  };

  const checkPromoData = (merchant) => {
    let promoData = { isValid: 0, beenCall: false, promoData: {} };
    if (promoDataByMerchant.filter((x) => x.MerchantID === merchant).length > 0) {
      promoDataByMerchant
        .filter((x) => x.MerchantID === merchant)
        .forEach((y) => {
          promoData = { isValid: y.IsValid, beenCall: true, promoData: y };
        });
    }
    return promoData;
  };

  const handleDeleteVoucher = () => {
    const promoDetails = checkPromoData(voucherInfoDialog.merchantID).promoData;
    const promoData = promoDataByMerchant.filter((x) => x.PromoCodeID !== promoDetails.PromoCodeID);
    const pricing = summary;

    if (promoDetails.isConfirmApply === 1 && promoDetails.IsValid === 1) {
      const listing = [];
      cartProduct.forEach((x) => {
        const cartDetails = [];
        let merchantServiceFee = x.ServiceFees;
        if (x.MerchantID === promoDetails.MerchantID) {
          x.cartList.forEach((y) => {
            const customizePrice = y.CartCustomization ? JSON.parse(y.CartCustomization).reduce((subtotal, data) => subtotal + data.CustomizationDetailsPrice, 0) : 0

            const tax = state?.isReload !== true && methodSelection === 9 ? getServiceCharge(
              y.CalculationID,
              y.ServiceFeeRate,
              (y.ProductPrice + customizePrice) * y.ProductQuantity,
              methodSelection
            ) : 0;
            cartDetails.push({ ...y, ServiceFees: tax || 0, priceAfterDiscount: null });
          });
          merchantServiceFee = cartDetails.reduce(
            (subtotal, item) => subtotal + item.ServiceFees,
            0
          );
        }

        listing.push({
          ...x,
          PromoCode: x.MerchantID === promoDetails.MerchantID ? '' : x.PromoCode,
          PromoCodeID: x.MerchantID === promoDetails.MerchantID ? 0 : x.PromoCodeID,
          PromoCodeDiscount: x.MerchantID === promoDetails.MerchantID ? 0 : x.PromoCodeDiscount,
          ServiceFees: merchantServiceFee,
          cartList: x.MerchantID === promoDetails.MerchantID ? cartDetails : x.cartList,
        });
      });
      dispatch(setCartProduct(listing));

      const serviceFees = listing.reduce((subtotal, item) => subtotal + item.ServiceFees, 0);
      setSummary({
        ...pricing,
        discount: pricing.discount - promoDetails.finalDiscount,
        tax: state?.isReload !== true && methodSelection === 9 ? serviceFees > 1 ? serviceFees : 1 : 0,
        // tax: 0,
      });
    }

    setPromoDataByMerchant(promoData);
  };

  const handleCloseVoucherDialog = () => {
    setVoucherInfoDialog({
      open: false,
      productID: '',
      merchantID: '',
      title: '',
      productData: [],
    });
    setPromoCode('');
    const promoData = promoDataByMerchant.filter((x) => x.IsValid === 1);
    setPromoDataByMerchant(promoData);
  };

  const handleConfirmApplyVoucher = (promoPassData) => {
    const promoDetails = checkPromoData(voucherInfoDialog.merchantID).promoData;
    const promoData = [];
    const pricing = summary;
    let tax = 0;

    promoPassData
      .filter((x) => x.IsValid === 1)
      .forEach((x, index) => {
        let defaultConfirm = 0;
        if (promoDataByMerchant.filter((y) => y.MerchantID === x.MerchantID).length > 0)
          defaultConfirm = promoDataByMerchant.filter((y) => y.MerchantID === x.MerchantID)[0]
            .isConfirmApply;
        promoData.push({
          ...x,
          isConfirmApply: x.MerchantID === voucherInfoDialog.merchantID ? 1 : defaultConfirm,
        });
      });

    let totalDiscountPrice = 0;
    let serviceFees = 0;
    const serviceFeesByProduct = [];

    const finalPromoList = [...promoData];
    promoData
      .filter((x) => x.isConfirmApply === 1)
      .forEach((y, index) => {
        let discountPrice = 0;
        let isMaxApply = false;
        const listing = cartListing.filter(
          (x) =>
            x.MerchantID === y.MerchantID && y.ProductID.split(',').includes(x.ProductID.toString())
        );

        listing.forEach((x) => {
          let total = 0;
          if (y.MerchantID === 56) {
            total = x.cartList
              .filter((data) => data.isEvent === 1)
              .reduce((subtotal, item) => subtotal + item.ProductPrice * item.ProductQuantity, 0);
            discountPrice = y.MaxDiscount > total ? total : y.MaxDiscount;
          } else {
            // total = x.ProductPrice * x.ProductQuantity;

            const customizePrice = x.CartCustomization ? JSON.parse(x.CartCustomization).reduce((subtotal, details) => subtotal + details.CustomizationDetailsPrice, 0) : 0

            total = (x.ProductPrice + customizePrice) * x.ProductQuantity;

            discountPrice += (y.PromoDiscount / 100) * total;

            if (discountPrice > y.MaxDiscount) {
              isMaxApply = true;
              discountPrice = y.MaxDiscount;
            }
          }
        });

        if (isMaxApply) {
          let balDiscount = y.MaxDiscount;
          listing.forEach((x) => {
            // let priceAfterDiscount = x.ProductPrice * x.ProductQuantity;

            const customizePrice = x.CartCustomization ? JSON.parse(x.CartCustomization).reduce((subtotal, details) => subtotal + details.CustomizationDetailsPrice, 0) : 0

            let priceAfterDiscount = (x.ProductPrice + customizePrice) * x.ProductQuantity;


            if (balDiscount > 0) {
              const oriDiscountPrice =
                (y.PromoDiscount / 100) * (x.ProductPrice + customizePrice) * x.ProductQuantity;
              balDiscount -= oriDiscountPrice;

              if (balDiscount < 0) {
                priceAfterDiscount += -balDiscount - oriDiscountPrice;
              } else {
                priceAfterDiscount -= oriDiscountPrice;
              }
            }
            tax = state?.isReload !== true && methodSelection === 9 ? getServiceCharge(
              x.CalculationID,
              x.ServiceFeeRate,
              priceAfterDiscount,
              methodSelection
            ) : 0;
            serviceFees += tax;
            serviceFeesByProduct.push({
              UserCartID: x.UserCartID,
              MerchantID: x.MerchantID,
              serviceFees: tax,
              discountedPrice: priceAfterDiscount,
            });
          });

          cartListing
            .filter(
              (x) =>
                x.MerchantID === y.MerchantID &&
                !y.ProductID.split(',').includes(x.ProductID.toString())
            )
            .forEach((x) => {
              const customizePrice = x.CartCustomization ? JSON.parse(x.CartCustomization).reduce((subtotal, data) => subtotal + data.CustomizationDetailsPrice, 0) : 0

              tax = state?.isReload !== true && methodSelection === 9 ? getServiceCharge(
                x.CalculationID,
                x.ServiceFeeRate,
                (x.ProductPrice + customizePrice) * x.ProductQuantity,
                methodSelection
              ) : 0;
              serviceFees += tax;
              serviceFeesByProduct.push({
                UserCartID: x.UserCartID,
                MerchantID: x.MerchantID,
                serviceFees: tax,
              });
            });
        } else {
          listing.forEach((x) => {
            const customizePrice = x.CartCustomization ? JSON.parse(x.CartCustomization).reduce((subtotal, data) => subtotal + data.CustomizationDetailsPrice, 0) : 0

            tax = state?.isReload !== true && methodSelection === 9 ? getServiceCharge(
              x.CalculationID,
              x.ServiceFeeRate,
              ((100 - y.PromoDiscount) / 100) * ((x.ProductPrice + customizePrice) * x.ProductQuantity),
              methodSelection
            ) : 0;
            serviceFees += tax;
            serviceFeesByProduct.push({
              UserCartID: x.UserCartID,
              MerchantID: x.MerchantID,
              serviceFees: tax,
              discountedPrice:
                ((100 - y.PromoDiscount) / 100) * (x.ProductPrice * x.ProductQuantity),
            });
          });
          cartListing
            .filter(
              (x) =>
                x.MerchantID === y.MerchantID &&
                !y.ProductID.split(',').includes(x.ProductID.toString())
            )
            .forEach((x) => {
              const customizePrice = x.CartCustomization ? JSON.parse(x.CartCustomization).reduce((subtotal, data) => subtotal + data.CustomizationDetailsPrice, 0) : 0

              tax = state?.isReload !== true && methodSelection === 9 ? getServiceCharge(
                x.CalculationID,
                x.ServiceFeeRate,
                (x.ProductPrice + customizePrice) * x.ProductQuantity,
                methodSelection
              ) : 0;
              serviceFees += tax;
              serviceFeesByProduct.push({
                UserCartID: x.UserCartID,
                MerchantID: x.MerchantID,
                serviceFees: tax,
              });
            });
        }

        finalPromoList[index].finalDiscount = discountPrice;

        totalDiscountPrice += discountPrice;
      });

    cartListing.forEach((x) => {
      if (promoData.filter((y) => y.MerchantID === x.MerchantID).length === 0) {
        const customizePrice = x.CartCustomization ? JSON.parse(x.CartCustomization).reduce((subtotal, data) => subtotal + data.CustomizationDetailsPrice, 0) : 0

        tax = state?.isReload !== true && methodSelection === 9 ? getServiceCharge(
          x.CalculationID,
          x.ServiceFeeRate,
          (x.ProductPrice + customizePrice) * x.ProductQuantity,
          methodSelection
        ) : 0;
        serviceFees += tax;
        serviceFeesByProduct.push({
          UserCartID: x.UserCartID,
          MerchantID: x.MerchantID,
          serviceFees: tax,
        });
      }
    });

    const listing = [];

    cartProduct.forEach((x) => {
      let total = 0;
      const totalServiceFees = serviceFeesByProduct
        .filter((y) => y.MerchantID === x.MerchantID)
        .reduce((subtotal, item) => subtotal + item.serviceFees, 0);

      const cartDetails = [];

      x.cartList.forEach((y) => {
        const feesByCart = serviceFeesByProduct
          .filter((z) => z.UserCartID === y.UserCartID)
          .reduce((subtotal, item) => subtotal + item.serviceFees, 0);
        const discountedPrice = serviceFeesByProduct
          .filter((z) => z.UserCartID === y.UserCartID)
          .reduce((subtotal, item) => subtotal + item.discountedPrice, 0);
        cartDetails.push({
          ...y,
          ServiceFees: feesByCart || (y.ServiceFees ? y.ServiceFees : 0),
          priceAfterDiscount: discountedPrice || null,
        });
      });

      // if (x.MerchantID === 56) {
      //   total = x.cartList
      //     .filter((data) => data.isEvent === 1)
      //     .reduce((subtotal, item) => subtotal + item.ProductPrice * item.ProductQuantity, 0);
      //   listing.push({
      //     ...x,
      //     PromoCode:
      //       x.MerchantID === promoDetails.MerchantID ? promoDetails.PromoCode : x.PromoCode,
      //     PromoCodeID:
      //       x.MerchantID === promoDetails.MerchantID ? promoDetails.PromoCodeID : x.PromoCodeID,
      //     // PromoCodeDiscount: x.MerchantID === promoDetails.MerchantID ? promoDetails.PromoDiscount / 100 * total : 0,

      //     ServiceFees:
      //       x.MerchantID === promoDetails.MerchantID ? totalServiceFees : x.ServiceFees || 0,
      //     PromoCodeDiscount:
      //       x.MerchantID === promoDetails.MerchantID
      //         ? promoDetails.MaxDiscount > total
      //           ? total
      //           : promoDetails.MaxDiscount
      //         : x.PromoCodeDiscount || 0,
      //     cartList: cartDetails,
      //   });
      // } else {
      total = x.cartList
        .filter((data) => promoDetails.ProductID.split(',').includes(data.ProductID.toString()))
        .reduce((subtotal, item) => (subtotal + item.ProductPrice + item.CartCustomization ? JSON.parse(item.CartCustomization).reduce((subtotals, data) => subtotals + data.CustomizationDetailsPrice, 0) : 0) * item.ProductQuantity, 0);
      listing.push({
        ...x,
        PromoCode:
          x.MerchantID === promoDetails.MerchantID ? promoDetails.PromoCode : x.PromoCode,
        PromoCodeID:
          x.MerchantID === promoDetails.MerchantID ? promoDetails.PromoCodeID : x.PromoCodeID,
        // PromoCodeDiscount: x.MerchantID === promoDetails.MerchantID ? promoDetails.PromoDiscount / 100 * total : 0,
        ServiceFees:
          x.MerchantID === promoDetails.MerchantID ? totalServiceFees : x.ServiceFees || 0,
        PromoCodeDiscount:
          x.MerchantID === promoDetails.MerchantID
            ? promoDetails.MaxDiscount > (promoDetails.PromoDiscount / 100) * total
              ? (promoDetails.PromoDiscount / 100) * total
              : promoDetails.MaxDiscount > total
                ? total
                : promoDetails.MaxDiscount
            : x.PromoCodeDiscount || 0,
        cartList: cartDetails,
      });
      // }
    });

    dispatch(setCartProduct(listing));

    setSummary({
      ...pricing,
      discount: totalDiscountPrice,
      tax: state?.isReload !== true && methodSelection === 9 ? serviceFees < 1 ? 1 : serviceFees : 0,
      // tax: 0
    });
    setPromoDataByMerchant(finalPromoList);
    setPromoCode('');
    setVoucherInfoDialog({
      open: false,
      productID: '',
      merchantID: '',
      title: '',
      productData: [],
    });
  };

  const handleRemark = (merchant, value) => {
    const listing = [];
    cartProduct.map((x) =>
      listing.push({
        ...x,
        Remarks: x.MerchantID === merchant ? value : x.Remarks,
      })
    );
    dispatch(setCartProduct(listing));
  };

  // if (!isCalculateDeliveryFees) return <LoadingScreen />;

  return (
    <>

      <Container
        sx={{
          overflow: 'hidden',
          pt: { xs: 5, md: 10 },
          pb: { xs: 5, md: 10 },
        }}
      >
        <Container
          sx={{
            py: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: (theme) => `1px solid ${theme.palette.error.light}`,
            borderRadius: 2,
          }}
        >
          <Typography variant="h4">
            <Countdown countDown={300} onTimeUp={setRefresh} />
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Time remaining to complete your order
          </Typography>
        </Container>

        <Grid
          xs={12}
          md={8}
          justifyContent={activeStep === STEPS.length - 1 ? 'center' : 'flex-start'}
        >
          <CheckoutSteps activeStep={activeStep} steps={STEPS} />
        </Grid>
        {activeStep === STEPS.length ? '' : handleCartType()}
        <Notification notification={notification} setNotification={setNotification} />

        <Dialog
          open={hotelInfoDialog.open}
          onClose={() => setHotelInfoDialog({ open: false, info: '', title: '' })}
        >
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <DialogTitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ color: 'text.secondary' }}
              >
                <Typography variant="h4"> {hotelInfoDialog.title} </Typography>
                <Tooltip title="Close">
                  <Button
                    endIcon={<Iconify icon="carbon:close-filled" />}
                    onClick={() => setHotelInfoDialog({ open: false, info: '', title: '' })}
                  />
                </Tooltip>
              </Stack>
            </DialogTitle>
            <DialogContent sx={{ padding: 3 }}>
              <Typography variant="h5" sx={{ textDecoration: 'underlined' }}>
                {' '}
                {hotelInfoDialog.info && hotelInfoDialog.info.ProductName}{' '}
              </Typography>
              {hotelInfoDialog.info && (
                <Box mt={1}>
                  <Typography variant="subtitle2" sx={{ textAlign: 'justify' }}>
                    Cancellation Policies:
                  </Typography>
                  <Typography variant="body2">{hotelInfoDialog.info.CancellationPolicy}</Typography>

                  <Typography variant="subtitle2" mt={2}>
                    Room Details:
                  </Typography>
                  <Stack direction="row">
                    <Iconify
                      icon="fluent:circle-small-20-filled"
                      width={16}
                      sx={{ mr: 1, mt: 0.5 }}
                    />
                    <Typography variant="body2"> {hotelInfoDialog.info.RoomType} Room </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Iconify
                      icon="fluent:circle-small-20-filled"
                      width={16}
                      sx={{ mr: 1, mt: 0.5 }}
                    />{' '}
                    <Typography variant="body2">
                      {' '}
                      Maximum {hotelInfoDialog.info.MaxAdultPax} pax{' '}
                    </Typography>
                  </Stack>

                  <Typography variant="subtitle2" mt={2}>
                    Additional Policies:
                  </Typography>
                  <Stack direction="row">
                    <Iconify
                      icon="fluent:circle-small-20-filled"
                      width={16}
                      sx={{ mr: 1, mt: 0.5 }}
                    />
                    <Typography variant="body2">
                      {' '}
                      Extra beds will be charges from{' '}
                      {fCurrency(
                        hotelInfoDialog.info.ExtraBedCharges
                          ? hotelInfoDialog.info.ExtraBedCharges
                          : '0'
                      )}{' '}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Iconify
                      icon="fluent:circle-small-20-filled"
                      width={16}
                      sx={{ mr: 1, mt: 0.5 }}
                    />
                    <Typography variant="body2">
                      {' '}
                      Breakfast can be serve from{' '}
                      {fCurrency(
                        hotelInfoDialog.info.BreakfastCharges
                          ? hotelInfoDialog.info.BreakfastCharges
                          : '0'
                      )}{' '}
                      per pax{' '}
                    </Typography>
                  </Stack>
                </Box>
              )}
            </DialogContent>
          </Box>
        </Dialog>

        <EcommerceVoucherList
          voucherInfoDialog={voucherInfoDialog}
          setVoucherInfoDialog={setVoucherInfoDialog}
          checkPromoData={checkPromoData}
          handleConfirmApplyVoucher={handleConfirmApplyVoucher}
          promoDataByMerchant={promoDataByMerchant}
          orderListing={orderListing}
          promoCode={promoCode}
          handlePromoCode={handlePromoCode}
          handleCheckPromo={handleCheckPromo}
          handleDeleteVoucher={handleDeleteVoucher}
          handleCloseVoucherDialog={handleCloseVoucherDialog}
        />
      </Container>


      {/* <Footer /> */}
      <RefreshDialog refreshDialog={refresh} setRefresh={setRefresh} />
    </>
  );
}
