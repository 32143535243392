import { PropTypes } from "prop-types";
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { DialogAnimate } from "src/components/animate";
import { useDispatch } from "src/redux/store";

FeedbackForm.propTypes = {
    isOpen: PropTypes.func,
    onClose: PropTypes.func,
};

export default function FeedbackForm({ isOpen, onClose }) {
    const dispatch = useDispatch();

    const FeedbackSchema = Yup.object().shape({
        email: Yup.string().required('Email Address is required').email("Email Address must be valid"),
        feedback: Yup.string().required('Feedback is required'),
    });

    const defaultValues = {
        email: "",
        feedback: "",
    }

    const methods = useForm({
        resolver: yupResolver(FeedbackSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            console.log(data) // API
            reset();
            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <DialogAnimate open={isOpen} onClose={onClose}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>Your FEEDBACK is important to us!! </DialogTitle>
                <DialogContent>
                    <Stack spacing={1}>
                        <RHFTextField name="email" label="Email Address" />
                        <RHFTextField name="feedback" label="Feedbacks" multiline rows={4} />
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button variant="outlined" onClick={onClose} color="inherit">
                        Cancel
                    </Button>

                    <LoadingButton autoFocus type="submit" variant="contained" loading={isSubmitting}>
                        Submit
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </DialogAnimate>
    )
};