// @mui
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Stack, Typography, Box } from '@mui/material';//
// paths
import { paths } from 'src/routes/paths';
import { TravelTourItem, TravelTourItemSkeleton } from 'src/sections/_travel/tour/item';
import { MerchantProductViewListItemSkeleton, MerchantProductItemBestSellers } from '../item';

// ----------------------------------------------------------------------

export default function MerchantProductListBestSellers({ products, loading }) {
    return (
        <Stack spacing={3}>
            {
                products !== undefined && products.length > 0 && (
                    <>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="h4" sx={{ textAlign: { xs: 'center', md: 'unset' } }} >
                                Best Sell
                            </Typography>

                            {/* {
                                !loading && products.length > 5 &&
                                <Typography
                                    onClick={() => navigate(paths.eCommerce.merchantViewProducts, { state: { product: products, keyword: "Best Sell", header: true } })}
                                    variant='subtitle2' sx={{ display: "flex", alignItems: "flex-end", '&:hover': { color: 'primary.dark', cursor: "pointer" }, }}>View All <ArrowForwardIosIcon fontSize='small' /></Typography>
                            } */}
                        </Stack>

                        <Box
                            rowGap={4}
                            columnGap={{ xs: 1, sm: 3, md: 3 }}
                            display="grid"
                            gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(5, 1fr)' }}
                        >
                            {(loading ? [...Array(5)] : products.slice(0, 5)).map((product, index) =>
                                product ? (
                                    <TravelTourItem key={product.ProductID} product={product} isMerchantPage />
                                ) : (
                                    <TravelTourItemSkeleton key={index} />
                                )

                            )}
                        </Box>
                    </>
                )
            }
        </Stack>
    );
}

MerchantProductListBestSellers.propTypes = {
    products: PropTypes.array,
    loading: PropTypes.bool,
};
