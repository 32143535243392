import { useEffect, useState } from 'react';
// @mui
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
// components
import { useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/useAuthContext';
import Notification from 'src/components/Notification/Notification';
import Iconify from 'src/components/iconify';
import { getChildProfileByParentID } from 'src/redux/slices/profile';
import { useDispatch, useSelector } from 'src/redux/store';
// routes
import NoItemFound from 'src/layouts/components/NoItemFound';
//
import ChildrenDetailCard from '../account/children/ChildrenDetailCard';
import ChildrenNewEditForm from '../account/children/ChildrenNewEditForm';
import { EcommerceAccountLayout } from '../layout';

// ----------------------------------------------------------------------

EcommerceAccountChildrenView.propTypes = {
  // currentUserProfile: PropTypes.object,
};

export default function EcommerceAccountChildrenView() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const { childrenProfile } = useSelector((state) => state.profile);
  const [notification, setNotification] = useState({
    open: false,
    msg: "",
    type: "success",
  });
  const [openForm, setOpenForm] = useState(false);
  const [isNewChild, setIsNewChild] = useState(false);

  const handleOpenNewEditForm = () => {
    setOpenForm(true);
    setIsNewChild(true);
  }

  useEffect(() => {
    dispatch(getChildProfileByParentID(user?.UserID));
  }, [dispatch, user])

  return (
    <EcommerceAccountLayout>
      <Stack spacing={5}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">Children Management</Typography>
            <Tooltip title="Add New Children">
              {/* <Stack direction="row" alignItems="center" onClick={() => handleOpenNewEditForm()} sx={{ cursor: "pointer", }}>
                <Iconify icon="carbon:add" width={18} sx={{ mr: 0.5 }} />
                <Typography variant="body2">Add</Typography>
              </Stack> */}
              <Button variant="contained" size="small" onClick={() => handleOpenNewEditForm()} sx={{ borderRadius: 50 }}>
                + Add{" "}
              </Button>
            </Tooltip>
          </Stack>
          {childrenProfile.length > 0 ?
            <Box
              gap={3}
              display="grid"
              gridTemplateColumns={{ xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
            >
              {
                childrenProfile.map((data, index) => (
                  <ChildrenDetailCard key={data.ChildID} card={data} index={index} />
                ))
              }
            </Box>
            :
            <NoItemFound title="There are no children added yet. Add at least 1 Children to shop." />

          }
        </Stack>
      </Stack>

      <ChildrenNewEditForm open={openForm} onClose={() => setOpenForm(false)} isNewChild={isNewChild} currentChild={null} />

      <Notification notification={notification} setNotification={setNotification} />
    </EcommerceAccountLayout>
  );
}
