import PropTypes from 'prop-types';
import { useState } from 'react';
import moment from 'moment';
// @mui
import { Button, Collapse, Drawer, Stack, Typography } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// config
import { NAV } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';
import { getProducts } from 'src/redux/slices/product';
import { useDispatch, useSelector } from 'src/redux/store';
//
import {
  EcommerceFilterBrand,
  EcommerceFilterCategory,
  EcommerceFilterPrice,
  EcommerceFilterRating
} from './components';

// ----------------------------------------------------------------------
export default function EcommerceFilters({ mobileOpen, onMobileClose, BRAND_OPTIONS, filters, defaultValues, setFilters, categorizedData, handleChangeCategories, handleOpenExpand, isExpandLevel }) {
  const isMdUp = useResponsive('up', 'md');
  const dispatch = useDispatch();
  const { userCheckInDate } = useSelector((data) => data.general);

  const getSelected = (selectedItems, item) =>
    selectedItems.includes(item)
      ? selectedItems.filter((value) => value !== item)
      : [...selectedItems, item];

  const handleChangeBrand = (name) => {
    setFilters({
      ...filters,
      filterBrand: getSelected(filters.filterBrand, name),
    });
  };

  const handleChangeRating = (event) => {
    setFilters({
      ...filters,
      filterRating: Number(event.target.value),
    });
  };
  
  const handleChangeStartPrice = (event) => {
    setFilters({
      ...filters,
      filterPrice: {
        ...filters.filterPrice,
        start: Number(event.target.value),
      },
    });
  };

  const handleChangeEndPrice = (event) => {
    setFilters({
      ...filters,
      filterPrice: {
        ...filters.filterPrice,
        end: Number(event.target.value),
      },
    });
  };

  const handleClearAll = () => {
    setFilters(defaultValues);
    dispatch(getProducts("Category", 0, 0, 999, 1, moment(userCheckInDate).format('YYYY-MM-DD'), moment(userCheckInDate).format('YYYY-MM-DD')));
  };

  const renderContent = (
    <Stack spacing={3} alignItems="flex-start"
      sx={{ flexShrink: 0, width: { xs: 1, md: NAV.W_FILTERDRAWER }, mb: 3, p: 3 }}
    >
      <Block title="Category">
        <EcommerceFilterCategory
          filterCategories={filters.filterCategories}
          onChangeCategories={handleChangeCategories}
          openExpand={handleOpenExpand}
          isExpandLevel={isExpandLevel}
          options={categorizedData}
          sx={{ mt: 2 }}
        />
      </Block>

      <Block title="Brand">
        <EcommerceFilterBrand
          filterBrand={filters.filterBrand}
          onChangeBrand={handleChangeBrand}
          options={BRAND_OPTIONS}
          sx={{ mt: 1 }}
        />
      </Block>

      <Block title="Price">
        <EcommerceFilterPrice
          filterPrice={filters.filterPrice}
          onChangeStartPrice={handleChangeStartPrice}
          onChangeEndPrice={handleChangeEndPrice}
          sx={{ mt: 2 }}
        />
      </Block>

      <Block title="Ratings">
        <EcommerceFilterRating
          filterRating={filters.filterRating}
          onChangeRating={handleChangeRating}
          sx={{ mt: 2 }}
        />
      </Block>

      <Button
        fullWidth
        color="inherit"
        size="large"
        variant="contained"
        startIcon={<Iconify icon="carbon:trash-can" />}
        onClick={handleClearAll}
      >
        Clear All
      </Button>
    </Stack>
  );

  return (
    <>
      {isMdUp ? (
        renderContent
      ) : (
        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={onMobileClose}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              pt: 3,
              px: 3,
              width: NAV.W_DRAWER,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}

EcommerceFilters.propTypes = {
  mobileOpen: PropTypes.bool,
  onMobileClose: PropTypes.func,
  BRAND_OPTIONS: PropTypes.array,
  setFilters: PropTypes.func,
  filters: PropTypes.object,
  defaultValues: PropTypes.object,
  categorizedData: PropTypes.array,
  handleOpenExpand: PropTypes.func,
  isExpandLevel: PropTypes.object,
  handleChangeCategories: PropTypes.func,
};

// ----------------------------------------------------------------------

function Block({ title, children, ...other }) {
  const [checked, setChecked] = useState(true);

  const handleOpen = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Stack alignItems="flex-start" sx={{ width: 1 }} {...other}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={handleOpen}
        sx={{ width: 1, cursor: 'pointer' }}
      >
        <Typography variant="h6">{title}</Typography>

        <Iconify
          icon={checked ? 'carbon:subtract' : 'carbon:add'}
          sx={{ color: 'text.secondary' }}
        />
      </Stack>

      <Collapse unmountOnExit in={checked} sx={{ px: 0.5 }}>
        {children}
      </Collapse>
    </Stack>
  );
}

Block.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};
