import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Card, Fab, Link, Stack, Typography } from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// utils
// components
import Logo from 'src/assets/logo.png';
import Iconify from 'src/components/iconify';
import Image from 'src/components/image';
import Label from 'src/components/label';
import TextMaxLine from 'src/components/text-max-line';
import useResponsive from 'src/hooks/useResponsive';
import { ProductPricing } from 'src/sections/_e-commerce/components';

// ----------------------------------------------------------------------

export default function TravelTourListItem({ product }) {
  const {
    ProductName,
    ShopState,
    ProductID,
    ProductImage,
    ProductPromotion,
    ProductPrice,
    ProductAvailability,
    MerchantID,
    MerchantShopName,
  } = product;

  const navigate = useNavigate();
  const isMdUp = useResponsive('up', 'md');

  const [selectedVariationPrice, setSelectedVariationPrice] = useState({
    oriPrice: 0,
    promoPrice: 0,
    discount: 0,
    percent: null,
    isDiscount: false,
  });

  useEffect(() => {
    let obj = {
      oriPrice: ProductPrice,
      promoPrice: ProductPrice,
      discount: 0,
      percent: null,
      isDiscount: false,
    };
    if (product) {
      if (ProductPromotion !== undefined && ProductPromotion !== '[]') {
        const PromotionData = JSON.parse(ProductPromotion)[0];

        obj = {
          oriPrice: ProductPrice,
          promoPrice: PromotionData.PromotionPrice,
          discount: PromotionData.ProductDiscount,
          percent: false,
          isDiscount: true,
        };
      }
    }

    setSelectedVariationPrice(obj);
  }, [ProductPromotion, product, ProductPrice]);

  return (
    <Card>
      <Stack
        direction="row"
        onClick={() => navigate(paths.eCommerce.product(ProductID))}
        sx={{
          position: 'relative',
          '&:hover': {
            boxShadow: (theme) => theme.customShadows.z8,
            transition: (theme) =>
              theme.transitions.create('box-shadow', {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.shortest,
              }),
          },
        }}
      >
        {selectedVariationPrice.discount ? (
          <Label color="error" sx={{ position: 'absolute', m: 1, top: 0, left: 0, zIndex: 9 }}>
            {selectedVariationPrice.discount} % OFF
          </Label>
        ) : (
          <></>
        )}

        <Fab
          component={RouterLink}
          to={paths.eCommerce.product(ProductID)}
          className="add-to-cart"
          color="primary"
          size="medium"
          sx={{
            right: 8,
            zIndex: 9,
            top: 8,
            opacity: 0,
            position: 'absolute',
            transition: (theme) =>
              theme.transitions.create('opacity', {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.shortest,
              }),
          }}
        >
          <Iconify icon="carbon:shopping-cart-plus" />
        </Fab>

        <Image
          src={ProductImage || Logo}
          sx={{
            mr: 2,
            flexShrink: 0,
            borderRadius: 1.5,
            width: { xs: 110, sm: 160 },
            height: { xs: 110, sm: 160 },
            bgcolor: 'background.neutral',
          }}
        />

        <Stack direction="column" justifyContent="center" spacing={0.5} p={0.5}>
          <Stack direction="column" spacing={{ xs: 0, md: 0.7 }}>
            <Link component={RouterLink} to={paths.eCommerce.merchantProduct(MerchantID)} color="inherit">
              <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: { xs: '12px', sm: '15px' } }}>
                {MerchantShopName}
              </Typography>
            </Link>

            <Link component={RouterLink} to={paths.eCommerce.product(ProductID)} color="inherit">
              <TextMaxLine variant={isMdUp ? "h6" : "subtitle2"} sx={{ fontSize: { xs: '13.5px', sm: '15px', md: '18px' } }} line={3}>
                {ProductName}
              </TextMaxLine>
            </Link>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {Number(selectedVariationPrice.oriPrice) ? (
                <ProductPricing
                  price={Number(selectedVariationPrice.promoPrice)}
                  priceSale={Number(selectedVariationPrice.oriPrice)}
                  discount={selectedVariationPrice.discount}
                  isDiscount={selectedVariationPrice.isDiscount}
                  percent={selectedVariationPrice.percent}
                  sx={{ typography: 'h5', flexDirection: { xs: 'column', sm: 'row' }, fontSize: { xs: '12.5px', sm: '15px', md: '18px' } }}
                />
              ) : (
                <Typography sx={{ typography: 'subtitle2', fontSize: { xs: '12.5px', sm: '15px', md: '18px' } }}>
                  {' '}
                  RM {selectedVariationPrice.oriPrice}{' '}
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack
            direction='row'
            sx={{
              right: { xs: 'unset', md: 13 },
              zIndex: 9,
              bottom: { xs: 'unset', md: 13 },
              position: { xs: 'relative', md: 'absolute' },
            }
            }
          >
            <Label
              color={ProductAvailability === 'out_of_stock' ? 'error' : 'success'}
              sx={{ fontSize: { xs: '10px', sm: '13px' } }}
            >
              {ProductAvailability === 'out_of_stock' ? 'Out Of Stock' : 'In Stock'}
            </Label>
          </Stack>
        </Stack>
      </Stack>

      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          pt: 1.5,
          pl: 2,
          pr: 1.5,
          top: 0,
          width: 1,
          zIndex: 9,
          position: 'absolute',
        }}
      >
        {selectedVariationPrice.discount ? (
          <Label color="error" sx={{ position: 'absolute', m: 1, top: 0, left: 0, zIndex: 9 }}>
            {selectedVariationPrice.discount} % OFF
          </Label>
        ) : (
          <></>
        )}
      </Stack>

      <Image alt={ProductName} src={ProductImage || Logo} ratio="1/1" />

      <Stack spacing={0.5} sx={{ p: 2.5 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {ShopState}
        </Typography>

        <Link component={RouterLink} to={paths.eCommerce.product(ProductID)} color="inherit">
          <TextMaxLine variant="h6" persistent>
            {ProductName}sasasa
          </TextMaxLine>
        </Link>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />
      
      <Stack direction="row" alignItems="center" sx={{ p: 2.5 }}>
        {Number(selectedVariationPrice.oriPrice) ? (
          <ProductPricing
            price={Number(selectedVariationPrice.promoPrice)}
            priceSale={Number(selectedVariationPrice.oriPrice)}
            discount={selectedVariationPrice.discount}
            isDiscount={selectedVariationPrice.isDiscount}
            percent={selectedVariationPrice.percent}
            sx={{ typography: 'h5', flexDirection: { xs: 'column', sm: 'row' } }}
          />
        ) : (
          <Stack direction="row">
            <Typography sx={{ typography: 'subtitle2', fontSize: { xs: '15px', sm: '18px' } }}>
              {' '}
              RM {selectedVariationPrice.oriPrice}{' '}
            </Typography>
          </Stack>
        )}

        <Label
          color={ProductAvailability === 'out_of_stock' ? 'error' : 'success'}
          sx={{ position: 'absolute', bottom: { xs: -20, sm: 20 }, right: 5, zIndex: 9 }}
        >
          {ProductAvailability === 'out_of_stock' ? 'Out Of Stock' : 'In Stock'}
        </Label>
      </Stack> */}
    </Card >
  );
}

TravelTourListItem.propTypes = {
  product: PropTypes.shape({
    ProductImage: PropTypes.string,
    ShopState: PropTypes.string,
    ProductName: PropTypes.string,
    ProductPromotion: PropTypes.string,
    ProductID: PropTypes.number,
    ProductAvailability: PropTypes.string,
    MerchantID: PropTypes.number,
    MerchantShopName: PropTypes.string,
    ProductPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
