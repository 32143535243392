import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useRoutes, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Stack,
  Button,
  Divider,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Alert,
  Tooltip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// utils
import { fCurrency, fPercent } from 'src/utils/formatNumber';
import LoadingScreen from 'src/components/loading-screen';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
// components
import Scrollbar from 'src/components/scrollbar';
import Label from 'src/components/label';

import { paths } from 'src/routes/paths';
import { strings } from '../../../auth';
import { useAuthContext } from '../../../auth/useAuthContext';
import { useDispatch, useSelector } from '../../../redux/store';
import { clearOrderData } from '../../../redux/slices/order';

// ----------------------------------------------------------------------

export default function EcommerceCheckoutOrderSummary({
  tax,
  total,
  subtotal,
  shipping,
  discount,
  products,
  loading,
  pageType,
  isCalculateDeliveryFees,
  handleNext,
  selection,
  onSubmitOrder,
  handleCheckPromo,
  handlePromoCode,
  promoCode,
  validPromoData,
  cartListing,
  orderID,
  setHotelInfoDialog,
  customerBillingData,
  isPromoApplied,
  isReload
}) {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { paymentData, orderAction } = useSelector(
    (data) => data.order
  );
  const { userProfile } = useSelector((state) => state.profile);
  const { user } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userDiscount = user?.AgentInd === 1 ? user?.AgentDiscount || 0 : 0

  useEffect(() => {
    if (paymentData && ref && ref.current && orderAction && orderAction.length > 0) {
      dispatch(clearOrderData())
      if (selection === 8) {
        navigate(paths.eCommerce.orderCompleted(orderAction[0].Transactionuuid))
      } else {
        ref.current.submit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData, ref, orderAction]);

  // useEffect(() => {
  //   if (paymentData && ref && ref.current) {
  //     dispatch(clearOrderData())
  //     if (selection === 8) {
  //       navigate(paths.eCommerce.orderCompleted(orderAction[0].Transactionuuid))
  //     } else {

  //       ref.current.submit();
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paymentData, ref]);


  const checkButton = () => {
    let isDisabled = false;
    // if (pageType === 'delivery' && !isCalculateDeliveryFees) isDisabled = true;

    if (pageType === 'delivery' && customerBillingData.requiredBill) {
      if (
        customerBillingData.UserAddressLine1 === '' ||
        customerBillingData.UserAddressLine2 === '' ||
        customerBillingData.UserAddressName === '' ||
        customerBillingData.UserContactNo === '' ||
        customerBillingData.UserEmail === ''
      )
        isDisabled = true;
    }
    // if (pageType !== 'delivery' && selection === 1 && bankSelected === "0")
    //   isDisabled = true

    // if (pageType !== 'delivery' && selection === 1 && fpx_information.fpx_checkSum === "")
    //   isDisabled = true

    // if (pageType !== 'delivery' && selection === 2 && card_information.signed === "")
    //   isDisabled = true

    if (
      cartListing.length > 0 &&
      cartListing.filter((x) => x.ProductQuantity > x.ProductStock).length > 0
    )
      isDisabled = true;

    if (isSubmitting) isDisabled = true;

    return isDisabled;
  };

  const handleSubmitOrder = (type) => {
    setIsSubmitting(true);
    onSubmitOrder(type);
  };

  const parameter = `{"merchantId":"M100002380 ","curType":"RM ","notifyURL":"http://amazonaws.com/notify-spg.php","merOrderNo":"H5166101370245","orderAmt":"10.00","goodsName":"Bill","detailURL":"http://amazonaws.com","transactionType":"1","remark":"notes", "successUrl":"http://amazonaws.com"}`

  return (
    <Stack
      spacing={3}
      sx={{
        p: 3,
        borderRadius: 2,
        border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
      }}
    >
      <Typography variant="h6"> Order Summary </Typography>

      <Stack sx={{ maxHeight: 400 }}>
        <Scrollbar sx={{ height: '400px', mb: '5vw' }}>
          {!!products?.length && (
            <>
              {products.map((merchant, index) => (
                <Stack key={merchant.MerchantID} pb={1}>
                  <Stack direction="row" alignItems="center" textAlign="left">
                    <IconButton>
                      <Iconify icon="ant-design:shop-filled" />
                    </IconButton>
                    <Typography variant="subtitle2"> {merchant.MerchantShopName} </Typography>
                  </Stack>
                  {merchant.cartList &&
                    merchant.cartList.map((product, cartIndex) => (
                      <ProductItem
                        key={product.ProductID}
                        product={product}
                        merchantData={merchant}
                        setHotelInfoDialog={setHotelInfoDialog}
                        isLast={cartIndex === merchant.cartList.length - 1}
                      />
                    ))}
                  {index !== products.length - 1 && <Divider sx={{ borderStyle: 'dashed' }} />}
                  <Typography variant="subtitle2"> Remarks: {merchant?.Remarks || "-"} </Typography>
                </Stack>
              ))}
              <Divider sx={{ borderStyle: 'dashed' }} />
            </>
          )}
        </Scrollbar>
      </Stack>

      <Stack spacing={2}>
        <Row label="Subtotal" value={fCurrency(subtotal)} />

        {/* <Row label="Shipping" value={fCurrency(shipping)} /> */}

        <Row
          label="Discount"
          value={`-${fCurrency(discount + (userDiscount / 100) * subtotal)}`}
        />

        {userDiscount > 0 && (
          <Typography variant="caption" color="orange" fontWeight="bold" textAlign="right">
            {userDiscount} % Agent Discount Applied
          </Typography>
        )}

        <Row label={<Stack >
          Service Fee
          {cartListing.length > 0 && cartListing[0] && cartListing[0].ProductID === 3133 ? <Typography variant="body2" sx={{ color: 'red', fontSize: '10px' }}>
            Senang Pay &apos;s Transaction Fee (only charged if payment by FPX or Credit Card) is waived if using top up to bronson pay
          </Typography> : <Typography variant="body2" sx={{ color: 'red', fontSize: '10px' }}>
            Senang Pay &apos;s Transaction Fee (only charged if payment by FPX or Credit Card)
          </Typography>}
        </Stack>} value={`${fCurrency(tax)}`} />
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Row
        label="Total"
        value={fCurrency(total <= 0 ? '0' : total - ((userDiscount) / 100) * subtotal)}
        sx={{
          typography: 'h6',
          '& span': { typography: 'h6' },
        }}
      />

      {pageType === '' && (
        <LoadingButton
          size="large"
          variant="contained"
          color="inherit"
          type="submit"
          loading={loading}
          onClick={() => handleNext('check')}
        // disabled={checkButton()}
        >
          Proceed Next Step
        </LoadingButton>
      )}

      {pageType === 'customer' && (
        <LoadingButton
          size="large"
          variant="contained"
          color="inherit"
          type="submit"
          loading={loading}
        >
          Proceed Next Step
        </LoadingButton>
      )}

      {pageType === 'delivery' && (
        <LoadingButton
          size="large"
          variant="contained"
          color="inherit"
          type="submit"
          loading={loading}
          onClick={() => handleNext()}
          disabled={checkButton()}
        >
          Order Now
        </LoadingButton>
      )}

      {selection === 9 && pageType !== 'delivery' && (
        <>
          <form name="order" id="payment_form2" action={`https://app.senangpay.my/payment/${paymentData?.merchant_id}`} method="post" ref={ref} >
            <input type="hidden" name="detail" value={paymentData?.detail} />
            <input type="hidden" name="amount" value={paymentData?.amount} />
            <input type="hidden" name="order_id" value={paymentData?.order_id} />
            <input type="hidden" name="name" value={paymentData?.name} />
            <input type="hidden" name="email" value={paymentData?.email} />
            <input type="hidden" name="phone" value={paymentData?.phone} />
            <input type="hidden" name="hash" value={paymentData?.hash} />
            <LoadingButton
              loading={isSubmitting}
              variant="contained"
              color="inherit"
              fullWidth
              size='large'
              type="submit"
              disabled={checkButton()}
              onClick={() => handleSubmitOrder("bank")}
            >
              Proceed
            </LoadingButton>
          </form>
          {!isReload && (
            <Typography variant="subtitle2" color="red" fontWeight="bold" textAlign="center">
              Service Tax will be charges on this option
            </Typography>
          )}
        </>
      )}

      {selection === 10 && pageType !== 'delivery' && (
        <>
          <form id="spform" method="POST" action="https://xservice.spayglobal.my/xservice/TapAndPayPaymentAction.preOrder.do" ref={ref} >
            <input type="hidden" id="formData" name="formData" value={paymentData.encryptData} />
            <LoadingButton
              loading={isSubmitting}
              variant="contained"
              color="inherit"
              fullWidth
              size='large'
              type="submit"
              disabled={checkButton()}
              onClick={() => handleSubmitOrder("spay")}
            >
              Proceed
            </LoadingButton>
          </form>
        </>
      )}

      {selection === 8 && pageType !== 'delivery' && (
        <>
          <LoadingButton ref={ref}
            loading={isSubmitting}
            variant="contained"
            color="inherit"
            fullWidth
            size='large'
            type="submit"
            disabled={checkButton() || ((userProfile?.MembershipPoint || 0) < (subtotal + tax))}
            onClick={() => handleSubmitOrder("credit")}
          >
            Proceed
          </LoadingButton>
          {((userProfile?.MembershipPoint || 0) < (subtotal + tax)) && (
            <Typography variant="subtitle2" color="red" fontWeight="bold" textAlign="center">
              Insufficient Credit
            </Typography>
          )}
        </>

      )}

    </Stack>
  );
}

EcommerceCheckoutOrderSummary.propTypes = {
  discount: PropTypes.number,
  loading: PropTypes.bool,
  products: PropTypes.array,
  shipping: PropTypes.number,
  subtotal: PropTypes.number,
  tax: PropTypes.number,
  total: PropTypes.number,
  pageType: PropTypes.string,
  isCalculateDeliveryFees: PropTypes.bool,
  handleNext: PropTypes.func,
  selection: PropTypes.number,
  onSubmitOrder: PropTypes.func,
  handlePromoCode: PropTypes.func,
  handleCheckPromo: PropTypes.func,
  promoCode: PropTypes.string,
  validPromoData: PropTypes.object,
  cartListing: PropTypes.array,
  orderID: PropTypes.string,
  setHotelInfoDialog: PropTypes.func,
  customerBillingData: PropTypes.object,
  isPromoApplied: PropTypes.number,
  isReload: PropTypes.bool,
};

// ----------------------------------------------------------------------

function ProductItem({ product, merchantData, setHotelInfoDialog, isLast, ...other }) {
  const { user } = useAuthContext();
  const userDiscount = user?.AgentInd === 1 ? user?.AgentDiscount || 0 : 0

  const customizePrice = product.CartCustomization ? JSON.parse(product.CartCustomization).reduce((subtotal, item) => subtotal + item.CustomizationDetailsPrice, 0) + product.ProductPrice : product.ProductPrice


  return (
    <>
      <Stack direction="row" alignItems="flex-start" pt={2} {...other}>
        <Image
          src={product.ProductImage}
          sx={{
            mr: 2,
            width: 64,
            height: 64,
            flexShrink: 0,
            borderRadius: 1.5,
            bgcolor: 'background.neutral',
          }}
        />

        <Stack flexGrow={1}>
          <Stack direction="row" justifyContent="space-between">
            <TextMaxLine variant="body2" line={1} sx={{ fontWeight: 'fontWeightMedium' }}>
              {product.ProductName}
            </TextMaxLine>
            {product.isHotel === 1 && (
              <Tooltip
                title="Click Me For More Resort Information"
                placement="top-start"
                onClick={() => setHotelInfoDialog(product)}
              >
                <Iconify icon="mdi:information-outline" style={{ color: '#FF5630' }} />
              </Tooltip>
            )}
          </Stack>
          <Divider sx={{ borderStyle: 'dashed' }} />

          {
            product.ProductID !== 3133 &&
            <>
              <Stack direction="row">
                <Iconify icon="bx:child" width={24} sx={{ mr: 1 }} />
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  {' '}
                  {product.ChildName} ({product.ClassName})
                </Typography>
              </Stack>
              <Stack direction="row" pb={1}>
                <Iconify icon="lets-icons:date-fill" width={16} sx={{ ml: 0.6, mr: 1, mt: 0.5 }} />
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  {' '}
                  {product.CheckinDate113}
                </Typography>
              </Stack>
              <Divider />
            </>
          }


          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ color: 'text.secondary', pt: 1 }}
          >
            <Typography variant="caption"> {product.ProductVariationValue} </Typography>
            <Typography variant="caption">
              {' '}
              {fCurrency(customizePrice || '0')}{' '}
            </Typography>
          </Stack>

          {
            JSON.parse(product.CartCustomization).map((customize) => (
              <Stack direction='column' key={customize.UserCartCustomizationID}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ color: 'text.secondary' }}
                >
                  <Typography variant="caption"> {customize.CustomizationDetails} </Typography>
                  <Typography variant="caption">    {fCurrency(customize.CustomizationDetailsPrice || '0')}  </Typography>
                </Stack>
                {customize.ISAVAILABLE === 0 &&
                  <Typography variant="subtitle2" sx={{ color: 'red' }} >
                    <Iconify icon="fluent-emoji-flat:warning" mr={1} />
                    Not Available
                  </Typography>
                }
              </Stack>

            ))
          }

          {/* {product.isHotel === 1 ? (
            <>
              <Stack direction="row" pt={1}>
                <Iconify icon="lets-icons:date-fill" width={16} sx={{ mr: 1 }} />
                <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontSize: '11px' }}>
                  {' '}
                  {product.CheckinDate113} - {product.CheckoutDate113} ( {product.NumberOfNight}{' '}
                  night ){' '}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Iconify icon="mdi:guest-room" width={16} sx={{ mr: 1 }} />
                <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontSize: '11px' }}>
                  {' '}
                  {product.RoomType} Room{' '}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Iconify icon="raphael:parent" width={16} sx={{ mr: 1 }} />
                <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontSize: '11px' }}>
                  {' '}
                  {product.MaxAdultPax} adult{' '}
                  {product.MaxChildPax > 0 && `, ${product.MaxChildPax} child`}{' '}
                </Typography>
              </Stack>
            </>
          ) : ( 
            <Stack direction="row" pt={1}>
              <Iconify icon="lets-icons:date-fill" width={16} sx={{ mr: 1 }} />
              <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontSize: '11px' }}>
                {' '}
                {product.CheckinDate113} 
              </Typography>
            </Stack>
           )} */}

          {product.Discount !== 'false' && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ color: 'text.secondary' }}
            >
              <Box component="span" sx={{ color: 'text.disabled', textDecoration: 'line-through' }}>
                {fCurrency(product.OriProductPrice ? product.OriProductPrice : '0')}
              </Box>
            </Stack>
          )}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ color: 'text.secondary' }}
          >
            <Typography variant="subtitle2"> Quantity </Typography>
            <Typography variant="subtitle2"> X {product.ProductQuantity}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontSize: '13px' }}>
              {' '}
              {product.isHotel === 0 ? 'Variant Price' : 'Resort Price'}{' '}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
              {' '}
              {fCurrency(
                customizePrice ? customizePrice * product.ProductQuantity : '0'
              )}{' '}
            </Typography>
          </Stack>

          {isLast && (
            <>
              <Divider sx={{ borderStyle: 'dashed' }} />
              {/* {product.isHotel === 0 && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mt: 0.5 }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ color: 'text.secondary', fontSize: '13px' }}
                  >
                    {' '}
                    Shipping{' '}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                    {' '}
                    {fCurrency(
                      merchantData.merchantShippingCost !== undefined
                        ? merchantData.merchantShippingCost
                        : '0'
                    )}
                  </Typography>
                </Stack>
              )} */}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: 0.5, mb: 1.5 }}
              >
                <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontSize: '13px' }}>
                  {' '}
                  Discount{' '}
                  {merchantData.PromoCodeDiscount !== 0 && (
                    <Label color="success"> Applied </Label>
                  )}{' '}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                  {' '}
                  {`-${fCurrency(
                    merchantData.PromoCodeDiscount +
                    (userDiscount / 100) *
                    (customizePrice
                      ? customizePrice * product.ProductQuantity
                      : '0')
                  )}`}{' '}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>

      {product.ProductQuantity > product.ProductStock && (
        <Typography variant="subtitle2" color="red">
          Insufficient stock for you purchase
        </Typography>
      )}
    </>
  );
}

ProductItem.propTypes = {
  product: PropTypes.shape({
    ProductImage: PropTypes.string,
    ProductName: PropTypes.string,
    ProductPrice: PropTypes.number,
    ProductVariationValue: PropTypes.string,
    ProductStock: PropTypes.number,
    ProductQuantity: PropTypes.number,
    MerchantShopName: PropTypes.string,
    MerchantID: PropTypes.number,
    Discount: PropTypes.string,
    OriProductPrice: PropTypes.number,
    isHotel: PropTypes.number,
    NumberOfNight: PropTypes.number,
    CheckinDate113: PropTypes.string,
    CheckoutDate113: PropTypes.string,
    MaxChildPax: PropTypes.number,
    MaxAdultPax: PropTypes.number,
    RoomType: PropTypes.string,

    CartCustomization: PropTypes.string,
    RelatedID: PropTypes.number,
    RelatedTypeID: PropTypes.number,
    ChildName: PropTypes.string,
    ChildProfileImage: PropTypes.string,
    SchoolName: PropTypes.string,
    ClassName: PropTypes.string,
    IsAvailable: PropTypes.number,
    ProductID: PropTypes.number,
  }),
  setHotelInfoDialog: PropTypes.func,
  merchantData: PropTypes.object,
  isLast: PropTypes.bool,
};

// ----------------------------------------------------------------------

function Row({ label, value, sx, ...other }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ typography: 'subtitle2', ...sx }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'body2', maxWidth: "70%" }}>
        {label}
      </Box>
      {value}
    </Stack>
  );
}

Row.propTypes = {
  label: PropTypes.string,
  sx: PropTypes.object,
  value: PropTypes.string,
};
