import PropTypes from 'prop-types';
import { useRef } from 'react';
// @mui
import { Box, Container } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
// utils
import { bgGradient } from 'src/utils/cssStyles';
// _mock
// components
import Carousel, { CarouselDots } from 'src/components/carousel';
//
import { EcommerceProductBannerSkeleton } from '../product/item';
import MerchantProductItemHero from '../product/item/MerchantProductItemHero';

// ----------------------------------------------------------------------

MerchantBannerHero.propTypes = {
    landingBanner: PropTypes.array,
    loading: PropTypes.bool,
};

export default function MerchantBannerHero({ landingBanner, loading }) {

    const theme = useTheme();

    const carouselRef = useRef(null);

    const carouselSettings = {
        dots: true,
        fade: true,
        speed: 1000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
        rtl: Boolean(theme.direction === 'rtl'),
        ...CarouselDots({
            rounded: true,
            sx: {
                left: 0,
                right: 0,
                zIndex: 9,
                bottom: 40,
                mx: 'auto',
                position: 'absolute',
            },
        }),
    };

    return (
        <Container sx={{ mt: { xs: 2, md: 3 }, }} >
            <Box
                sx={{
                    ...bgGradient({ color: alpha(theme.palette.background.default, 0.9), imgUrl: '/assets/background/overlay_1.jpg', }),
                    borderRadius: 3,
                    overflow: 'hidden',
                    position: 'relative',
                    height: loading && '500px'
                }}
            >
                <Carousel ref={carouselRef} {...carouselSettings}>
                    {(loading ? [...Array(3)] : landingBanner).map((product, index) =>
                        product ? (
                            <MerchantProductItemHero key={index} product={product} />
                        ) : (
                            <EcommerceProductBannerSkeleton key={index} />
                        )
                    )}
                </Carousel>
            </Box>
        </Container>
    );
}
