import { createSlice } from '@reduxjs/toolkit';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';
import { setUser } from '../../auth/utils';

// ----------------------------------------------------------------------

const url = ServerConfiguration.ServerUrl;
const PROJECTID = ServerConfiguration.PROJECTID;

const initialState = {
  isLoading: false,
  error: null,
  orders: [],
  orderAction: null,
  orderDetail: [],
  orderStatus: [],
  shippingFees: null,
  shippingFeesByMerchant: null,
  voucher: null,
  voucherByMerchant: null,
  voucherList: null,
  orderStatusByID: null,
  orderShipment: null,
  orderStatusAction: null,
  orderResponseAction: null,
  cartProduct: [],
  isCartValid: null,
  paymentData: null
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORDERS
    getOrdersByUserIDSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    // GET ORDERS BY ORDER ID
    getOrdersByOrderIDSuccess(state, action) {
      state.isLoading = false;
      state.orderDetail = action.payload;
    },

    // GET ORDERS STATUS
    getOrdersStatusSuccess(state, action) {
      state.isLoading = false;
      state.orderStatus = action.payload;
    },

    // GET ORDERS STATUS
    updateOrderTrackingStatusSuccess(state, action) {
      state.isLoading = false;
      state.orderAction = action.payload;
    },

    addOrderSuccess(state, action) {
      state.isLoading = false;
      state.orderAction = action.payload;
    },

    addOrderMemberPointSuccess(state, action) {
      state.isLoading = false;
      state.orderAction = action.payload;
    },

    // GET PRODUCT
    getOrderShippingFeesSuccess(state, action) {
      state.isLoading = false;
      state.shippingFees = action.payload;
    },

    getOrderShippingFeesByMerchantSuccess(state, action) {
      state.isLoading = false;
      state.shippingFeesByMerchant = action.payload;
    },

    getPromoVoucherListSuccess(state, action) {
      state.isLoading = false;
      state.voucherList = action.payload;
    },

    getRedeemPromoVoucherSuccess(state, action) {
      state.isLoading = false;
      state.voucher = action.payload;
    },

    getValidatePromoCodeSuccess(state, action) {
      state.isLoading = false;
      state.voucher = action.payload;
    },

    getValidatePromoCodeByMerchantSuccess(state, action) {
      state.isLoading = false;
      state.voucherByMerchant = action.payload;
    },

    getOrderStatusSuccess(state, action) {
      state.isLoading = false;
      state.orderStatusByID = action.payload;
    },

    getOrderShipmentStatusSuccess(state, action) {
      state.isLoading = false;
      state.orderShipment = action.payload;
    },

    clearOrderDataSuccess(state, action) {
      
      state.isLoading = false;
      state.shippingFees = null;
      state.shippingFeesByMerchant = null;
      state.voucher = null;
      state.voucherByMerchant = null;
      state.orderAction = null;
      state.orderStatusByID = null;
      state.orderShipment = null;
      state.orderStatusAction = null;
      state.orderResponseAction = null;
      state.paymentData = null;
    },

    // UPDATE ORDER
    updateOrderSuccess(state, action) {
      state.isLoading = false;
      state.orderAction = action.payload;
    },

    updateOrderStatusSuccess(state, action) {
      state.isLoading = false;
      state.orderStatusAction = action.payload;
    },

    updateBankResponseDataSuccess(state, action) {
      state.isLoading = false;
      state.orderResponseAction = action.payload;
    },

    checkCartValiditySuccess(state, action) {
      state.isLoading = false;
      state.isCartValid = action.payload;
    },

    clearCartValidity(state, action) {
      state.isLoading = false;
      state.isCartValid = null;
    },

    setCartProduct(state, action) {
      state.isLoading = false;
      state.cartProduct = action.payload;
    },

    setPaymentData(state, action) {
      state.isLoading = false;
      state.paymentData = action.payload;
    },

    clearPaymentData(state, action) {
      state.isLoading = false;
      state.paymentData = null;
    },
  },
});

// Reducer
export default slice.reducer;
export const {
  setCartProduct,
  clearCartValidity,
  setPaymentData,
  clearPaymentData
} = slice.actions;
// ----------------------------------------------------------------------

export function getOrdersByUserID(trackingStatus, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Order_ViewOrderByUserID?TRACKINGSTATUS=${trackingStatus}&USERID=${userId}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getOrdersByUserIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrdersByOrderIDHash(orderId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Order_ViewOrderByOrderIDHash?ORDERID=${orderId}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getOrdersByOrderIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrdersByOrderID(orderId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Order_ViewOrderByOrderID?ORDERID=${orderId}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getOrdersByOrderIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrdersStatus() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Order_ViewOrderStatus`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getOrdersStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateOrderTrackingStatus(orderId, trackingStatusId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Order_UpdateTrackingStatus?OrderID=${orderId}&TrackingStatusID=${trackingStatusId}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateOrderTrackingStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function clearProductCartAction() {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       dispatch(slice.actions.clearProductCartActionSuccess([]));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// Actions
export const { onOpenModal, onCloseModal, selectUser, selectRange, startLoading } = slice.actions;

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

export function getOrderShippingFees(productId, quantity, poscode) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_CalculateOrderShipping?PRODUCTID=${productId}&PROJECTID=${PROJECTID}&PRODUCTQUANTITY=${quantity}&POSCODE=${poscode}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getOrderShippingFeesSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderShippingFeesByMerchant(productId, quantity, poscode) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_CalculateOrderShippingByMerchant?PRODUCTID=${productId}&PROJECTID=${PROJECTID}&PRODUCTQUANTITY=${quantity}&POSCODE=${poscode}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getOrderShippingFeesByMerchantSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function viewPromoVoucherListing(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_ViewPromoCode?ACTIVEIND=0&USERID=${userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getPromoVoucherListSuccess(JSON.parse(data.ReturnData)));
      } else {

        dispatch(slice.actions.getPromoVoucherListSuccess(JSON.parse(data.ReturnData)));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function redeemPromoVoucher(promoCodeId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_RedeemPromoCode?USERID=${userId}&PROMOCODEID=${promoCodeId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getRedeemPromoVoucherSuccess(JSON.parse(data.ReturnData)));
      } else {

        dispatch(slice.actions.getRedeemPromoVoucherSuccess(JSON.parse(data.ReturnData)));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getValidatePromoCode(promoCode) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_ViewPromoCodeByCode?PROMOCODE=${promoCode}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getValidatePromoCodeSuccess(JSON.parse(data.ReturnData)));
      } else {

        dispatch(slice.actions.getValidatePromoCodeSuccess(JSON.parse(data.ReturnData)));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getValidatePromoCodeByMerchant(promoCode, merchantId, productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_ViewPromoCodeByMerchantProduct?PROMOCODE=${promoCode}&MERCHANTID=${merchantId}&PRODUCTID=${productId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getValidatePromoCodeByMerchantSuccess(JSON.parse(data.ReturnData)));
      } else {

        dispatch(slice.actions.getValidatePromoCodeByMerchantSuccess(JSON.parse(data.ReturnData)));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addOrder(orderData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    
    return fetch(
      `${url}Order_AddOrderSenangPay`
      , {
        method: 'POST',
        redirect: 'follow',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          USERID: orderData.UserID,
          USERADDRESSID: orderData.UserAddressID,
          PROMOTIONID: 0,
          USERCARTID: orderData.UserCartID,
          PROMOTIONCODEID: orderData.PromotionCodeID,
          PAYMENTMETHODID: orderData.PaymentMethodID,
          USERPAYMENTMETHODID: orderData.UserPaymentMethodID,
          ORDERTOTALAMOUNT: orderData.OrderTotalAmount,
          ORDERPAIDAMOUNT: orderData.OrderPaidAmount,
          ORDERSHIPPINGFEEAMOUNT: orderData.OrderShippingFee,
          AGENTDISCOUNT: orderData.AgentDiscount,
          ORDERTAXAMOUNT: orderData.OrderTax,
          PRODUCTID: orderData.ProductID,
          PRODUCTQUANTITY: orderData.ProductQuantity,
          PRODUCTVARIATIONDETAILID: orderData.ProductVariationDetailID,
          TRACKINGSTATUSID: orderData.TrackingStatusID,
          PickUpInd: orderData.PickUpInd,
          TRANSACTIONUUID: orderData.TransactionUUID,
          ProjectID: PROJECTID,
          ACCESSCATEGORYID: orderData.ACCESSCATEGORYID,

          TOYYIBPAYBILLCODE: orderData.BillCode,

          UserContact_Hotel: orderData.UserContact_Hotel,
          UserFirstName_Hotel: orderData.UserFirstName_Hotel,
          UserLastName_Hotel: orderData.UserLastName_Hotel,
          UserEmail_Hotel: orderData.UserEmail_Hotel,
          UserCountryID_Hotel: orderData.UserCountryID_Hotel,
          UserFirstName_HotelGuest: orderData.UserFirstName_HotelGuest,
          UserLastName_HotelGuest: orderData.UserLastName_HotelGuest,
          UserContact_HotelGuest: orderData.UserContact_HotelGuest,
          PromoCodeID_Hotel: orderData.PromoCodeID_Hotel,

          ISHOTEL: orderData.isHotel,
          STARTDATE: orderData.hotelStartDate,
          ENDDATE: orderData.hotelEndDate,
          BEDQUANTITY: orderData.bedQuantity,
          BREAKFASTQUANTITY: orderData.breakfastQuantity,
          SPECIALREQUEST: orderData.specialRequest,

          BILLINGNAME: orderData.billingName,
          BILLINGADDRESS1: orderData.billingAdd1,
          BILLINGADDRESS2: orderData.billingAdd2,
          BILLINGCONTACT: orderData.billingContact,
          BILLINGEMAIL: orderData.billingEmail,

          UserCountryID_HotelGuest: orderData.UserCountryID_HotelGuest,
          Hotel_BookForOtherInd: orderData.Hotel_isBookForOtherInd,
          Hotel_isMalaysianInd: orderData.Hotel_isMalaysianInd,
          SMOKINGPREFERENCE: orderData.Hotel_smokingPreference,
          BEDPREFERENCE: orderData.Hotel_bedPreference,
          EXTRAREQUEST: orderData.Hotel_extraRequest,
          ISBILLINGREQUIRED: orderData.isBillRequiredInd,

          PROMOCODEDISCOUNTAMOUNT: orderData.PromoCodeDiscount,
          REMARK: orderData.Remarks,
          PROMOTIONCODE: orderData.PromoCode,

          ISEVENT: orderData.Event_isEvent,
          STARTTIME: orderData.Event_startTime,
          ENDTIME: orderData.Event_endTime,
          ISCONTAINEVENT: orderData.Event_isContainEvent,
          ORDERORIGIN: orderData.orderOrigin,          
          CUSTOMIZATION: orderData.Customization,
          RELATEDID: orderData.relatedId,
          RELATEDTYPE: orderData.relatedTypeId,
          TOPUPIND : orderData.TopUpInd,
          DATA: JSON.stringify({
            USERID: orderData.UserID,
            USERADDRESSID: orderData.UserAddressID,
            PROMOTIONID: 0,
            USERCARTID: orderData.UserCartID,
            PROMOTIONCODEID: orderData.PromotionCodeID,
            PAYMENTMETHODID: orderData.PaymentMethodID,
            USERPAYMENTMETHODID: orderData.UserPaymentMethodID,
            ORDERTOTALAMOUNT: orderData.OrderTotalAmount,
            ORDERPAIDAMOUNT: orderData.OrderPaidAmount,
            ORDERSHIPPINGFEEAMOUNT: orderData.OrderShippingFee,
            ORDERTAXAMOUNT: orderData.OrderTax,
            PRODUCTID: orderData.ProductID,
            PRODUCTQUANTITY: orderData.ProductQuantity,
            PRODUCTVARIATIONDETAILID: orderData.ProductVariationDetailID,
            TRACKINGSTATUSID: orderData.TrackingStatusID,
            PickUpInd: orderData.PickUpInd,
            TRANSACTIONUUID: orderData.TransactionUUID,
            ProjectID: PROJECTID,
           
            UserContact_Hotel: orderData.UserContact_Hotel,
            UserFirstName_Hotel: orderData.UserFirstName_Hotel,
            UserLastName_Hotel: orderData.UserLastName_Hotel,
            UserEmail_Hotel: orderData.UserEmail_Hotel,
            UserCountryID_Hotel: orderData.UserCountryID_Hotel,
            UserFirstName_HotelGuest: orderData.UserFirstName_HotelGuest,
            UserLastName_HotelGuest: orderData.UserLastName_HotelGuest,
            UserContact_HotelGuest: orderData.UserContact_HotelGuest,
            PromoCodeID_Hotel: orderData.PromoCodeID_Hotel,

            ISHOTEL: orderData.isHotel,
            STARTDATE: orderData.hotelStartDate,
            ENDDATE: orderData.hotelEndDate,
            BEDQUANTITY: orderData.bedQuantity,
            BREAKFASTQUANTITY: orderData.breakfastQuantity,
            SPECIALREQUEST: orderData.specialRequest,

            BILLINGNAME: orderData.billingName,
            BILLINGADDRESS1: orderData.billingAdd1,
            BILLINGADDRESS2: orderData.billingAdd2,
            BILLINGCONTACT: orderData.billingContact,
            BILLINGEMAIL: orderData.billingEmail,

            UserCountryID_HotelGuest: orderData.UserCountryID_HotelGuest,
            Hotel_BookForOtherInd: orderData.Hotel_isBookForOtherInd,
            Hotel_isMalaysianInd: orderData.Hotel_isMalaysianInd,
            SMOKINGPREFERENCE: orderData.Hotel_smokingPreference,
            BEDPREFERENCE: orderData.Hotel_bedPreference,
            EXTRAREQUEST: orderData.Hotel_extraRequest,
            ISBILLINGREQUIRED: orderData.isBillRequiredInd,

            PROMOCODEDISCOUNTAMOUNT: orderData.PromoCodeDiscount,
            REMARK: orderData.Remarks,
            PROMOTIONCODE: orderData.PromoCode,

            ISEVENT: orderData.Event_isEvent,
            STARTTIME: orderData.Event_startTime,
            ENDTIME: orderData.Event_endTime,
            ISCONTAINEVENT: orderData.Event_isContainEvent,
            ORDERORIGIN: orderData.orderOrigin,

            CUSTOMIZATION: orderData.Customization,
            
          RELATEDID: orderData.relatedId,
          RELATEDTYPE: orderData.relatedTypeId,
          TOPUPIND : orderData.TopUpInd,
          })
        })
      }
    )
      .then(response => response.json())
      .then(json => {
        const data = json[0];
        if (data && data.ReturnVal === 1) {
          dispatch(slice.actions.addOrderSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
        }
      })
      .catch(error => dispatch(slice.actions.hasError(error)));
  };
}

export function updateOrder(orderData) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_UpdateToyyibPayPendingOrder?OrderID=${orderData.OrderID}&PaymentMethodID=${orderData.PaymentMethodID}&UserPaymentMethodID=${orderData.UserPaymentMethodID}&OrderTotalAmount=${orderData.OrderTotalAmount}&OrderPaidAmount=${orderData.OrderPaidAmount}&PROMOTIONCODE=${orderData.PromoCode}&PROMOTIONCODEID=${orderData.PromotionCodeID}&PROMOCODEDISCOUNTAMOUNT=${orderData.PromoCodeDiscount}&ORDERSHIPPINGFEEAMOUNT=${orderData.OrderShippingFee}&TOYYIBPAYBILLCODE=${orderData.BillCode}&TOYYIBPAYREFERENCE=${orderData.TransactionUUID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateOrderSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function viewOrderStatusByUUID(transactionUUID, paymentType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_ViewPaymentDetailsByUUID?TRANSACTIONUUID=${transactionUUID}&PROJECTID=${PROJECTID}&PAYMENTTYPE=${paymentType}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getOrderStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getOrderStatusSuccess(JSON.parse(data.ReturnData)));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function viewOrderShipmentStatus(trackingNumber, type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_RequestOrderStatus?TRACKINGNUMBER=${trackingNumber}&PROJECTID=${PROJECTID}&TYPE=${type}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getOrderShipmentStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getOrderShipmentStatusSuccess(JSON.parse(data.ReturnData)));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function clearOrderData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearOrderDataSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function updateOrderStatus(transactionUUID, amount, txnId, paymentType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_UpdateOrderStatus?TRANSACTIONUUID=${transactionUUID}&ORDERPAIDAMOUNT=${amount}&TXNID=${txnId}&PAYMENTTYPE=${paymentType}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateOrderStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateOrderStatusSuccess(JSON.parse(data.ReturnData)));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateBankResponseData(transactionUUID, responseCode, txnId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_UpdateBankResponseData?TRANSACTIONUUID=${transactionUUID}&ResponseCode=${responseCode}&TXNID=${txnId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateBankResponseDataSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateBankResponseDataSuccess(JSON.parse(data.ReturnData)));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function checkCartValidity(promoData, variationData, productQuantity, startDate, endDate, accessData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_CheckCartAvailability?PROMOTIONID=${promoData}&PRODUCTVARIATIONID=${variationData}&PRODUCTQUANTITY=${productQuantity}&STARTDATE=${startDate}&ENDDATE=${endDate}&ACCESSCATEGORYID=${accessData}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.checkCartValiditySuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}