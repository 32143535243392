import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Container, Typography, Button, Unstable_Grid2 as Grid } from '@mui/material';

import { getComparator, stableSort } from 'src/sections/_e-commerce/account/orders';
import { EcommerceCartListSide, EcommerceCartSummary } from '../cart';

// ----------------------------------------------------------------------

export default function EcommerceCartViewSide({ productCart,ClearOrder, hotelCart , setDrawerClose}) {

  const [productCartByMerchant, setProductCartByMerchant] = useState([]);
  const [overallCart, setOverallCart] = useState([]);

  useEffect(() => {
    if (productCart || hotelCart) {
      const filterList = []
      const overallListing = [...productCart, ...hotelCart]
      const removeDuplicate = overallListing.length > 0 ? overallListing.filter((ele, ind) => ind === overallListing.findIndex(elem => elem.MerchantID === ele.MerchantID)) : []
      if (removeDuplicate.length > 0) {

        removeDuplicate.map((x) => {
          const details = productCart.filter((y) => y.MerchantID === x.MerchantID)
          const hotelDetails = hotelCart.filter((y) => y.MerchantID === x.MerchantID)

          const sortByOrderDate = stableSort(details, getComparator('asc', 'CheckinDate23'))
          const sortByChild = stableSort(sortByOrderDate, getComparator('asc', 'RelatedID'))

          filterList.push({
            ...x,
            cartList: [...sortByChild, ...hotelDetails]
          })

          return {}
        })
      }
      setOverallCart(overallListing)
      setProductCartByMerchant(filterList)
    }

  }, [productCart, hotelCart]);

  return (

    <Container
      sx={{
        overflow: 'hidden',
        pt: { xs: 1, md: 2 },
        pb: { xs: 5, md: 10 },
        // pl: { xs: 11, sm: 3 },
      }}
    >
      <Typography variant="h5" sx={{ mb: { xs: 0.5, md: 1 }, fontSize: { xs: 17, md: 20 } }}>
        Shopping Cart
      </Typography>

      <Grid container spacing={{ xs: 5, md: 8, }}>
        <Grid xs={12} md={12} sx={{}}>
          <EcommerceCartListSide merchantList={productCartByMerchant} ClearOrder={ClearOrder} productCart={overallCart} setDrawerClose={setDrawerClose} />
        </Grid>
      </Grid>
    </Container>

  );
}

EcommerceCartViewSide.propTypes = {
  productCart: PropTypes.array,
  hotelCart: PropTypes.array,
  ClearOrder: PropTypes.func,
  setDrawerClose: PropTypes.func

};
