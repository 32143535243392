import PropTypes from 'prop-types';
// @mui
import { Stack, Typography } from '@mui/material';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

RoomDetails.propTypes = {
  room: PropTypes.object,
  showPrice: PropTypes.bool,
};

export default function RoomDetails({ room, showPrice = false }) {
  return (
    <>
      <Typography variant='h6' mb={1}>{room.InternalRoomName}</Typography>

      {/* <Stack direction="row" spacing={2}>
        <Iconify icon={room.NumberOfBed === 1 ? "ic:baseline-bed" : "mdi:bed"} />
        <Typography variant='body2'>{room.NumberOfBed}  bed</Typography>
      </Stack> */}
      {
        room.RoomConfig && room.RoomConfig !== "[]" && JSON.parse(room.RoomConfig).map((details) => (
          <Stack direction="row" spacing={2} key={details.RoomConfigID}>
            <Iconify icon={details.Quantity === 1 ? "ic:baseline-bed" : "mdi:bed"} />
            <Typography variant='body2'>{details.Quantity} {details.BedType}</Typography>
          </Stack>
        ))
      }

      <Stack direction="row" spacing={2}>
        <Iconify icon="ic:round-person" />
        <Typography variant='body2'>Capacity : {room.MaxAdultPax}</Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Iconify icon="mdi:bathroom" />
        <Typography variant='body2'>Bathroom : {room.NumberOfBathroom}</Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Iconify icon="radix-icons:size" />
        <Typography variant='body2'>Room Size : {room.RoomSize} &#13217;</Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Iconify icon="mdi:bed" />
        <Typography variant='body2'>Extra Bed (1): MYR {room.ExtraBedCharges}</Typography>
      </Stack>

      
    </>
  )
}