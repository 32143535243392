import PropTypes from 'prop-types';
// @mui
import { Stack, Typography } from '@mui/material';
// components
import Iconify from 'src/components/iconify';
// ----------------------------------------------------------------------

export default function EcommerceFilterCategory({
  options,
  filterCategories,
  onChangeCategories,
  openExpand,
  isExpandLevel,
  handleCategoryChange,
  ...other
}) {

  const iconListing = (list, expand, status) => {

    let displayListing = ""
    if (list.HierarchyID < 3 && list.children.length > 0) {
      if (expand === list.ProductCategoryID && status === true)
        displayListing = <Iconify icon="carbon:chevron-down" width={12} sx={{ mr: 1 }} onClick={() => openExpand(list.HierarchyID, list.ProductCategoryID, false)} />
      else
        displayListing = <Iconify icon="carbon:chevron-right" width={12} sx={{ mr: 1 }} onClick={() => openExpand(list.HierarchyID, list.ProductCategoryID, true)} />
    }

    if (list.HierarchyID >= 3 || list.children === undefined || list.children.length === 0) {
      if (expand === list.ProductCategoryID && status === true)
        displayListing = <Iconify icon="carbon:chevron-down" width={12} sx={{ mr: 1 }} onClick={() => openExpand(list.HierarchyID, list.ProductCategoryID, false)} />
      else
        displayListing = <Iconify icon="carbon:chevron-right" width={12} sx={{ mr: 1 }} onClick={() => openExpand(list.HierarchyID, list.ProductCategoryID, true)} />
    }

    return displayListing
  }

  const userViewDisplay = (isBold, list, expand, status, padding) => (
    <Stack
      key={`UserView_${list.ProductCategoryID}`}
      direction="row"
      alignItems="center"
      sx={{
        typography: 'body2',
        paddingLeft: padding,
        fontWeight: isBold ? "bold" : "normal",
        cursor: 'pointer',
        ...(filterCategories === list.ProductCategory && {
          fontWeight: 'fontWeightBold',
        }),
      }}
    >
      {iconListing(list, expand, status)}
      <Typography variant='subtitle' onClick={() => {
        openExpand(list.HierarchyID, list.ProductCategoryID, true)
        onChangeCategories(list.ProductCategoryID)
      }} >
        {list.ProductCategory}
      </Typography>
    </Stack>
  )

  const ListingDisplay = (option, expandLevel, expandStatus) => {
    let returnDisplay = {}
    const isBold = expandLevel === option.ProductCategoryID && expandStatus === true
    returnDisplay =
      <Stack key={option.ProductCategoryID}>
        {userViewDisplay(isBold, option, expandLevel, expandStatus, "0px")}
        {
          option.children.length > 0 && expandLevel === option.ProductCategoryID && expandStatus === true &&
          <Stack spacing={1} style={{ paddingLeft: "20px" }}  {...other} key={option.ProductCategoryID}>
            {
              option.children.map((data) => (ListingDisplayLevel2(data, isExpandLevel.level2, isExpandLevel.level2Status, "5px")))
            }
          </Stack>
        }
      </Stack>
    return returnDisplay
  }

  const ListingDisplayLevel2 = (option, expandLevel, expandStatus, padding) => {
    let returnDisplay = {}
    const isBold = expandLevel === option.ProductCategoryID && expandStatus === true

    const checkIsBold = (data) => {
      let returnBold = false

      if (isExpandLevel.level3 === data.ProductCategoryID && isExpandLevel.level3Status === true)
        returnBold = true
      return returnBold
    }

    returnDisplay =
      <Stack key={`2ndLayerStack_${option.ProductCategoryID}`}>
        {userViewDisplay(isBold, option, expandLevel, expandStatus, padding)}
        {
          option.children.length > 0 && expandLevel === option.ProductCategoryID && expandStatus === true &&
          <Stack spacing={1} ml={2} alignItems="flex-start" {...other} key={`3rdLayerStack_${option.ProductCategoryID}`}>
            {
              option.children.map((data) => (userViewDisplay(checkIsBold(data), data, isExpandLevel.level3, isExpandLevel.level3Status, "15px")))
            }
          </Stack>
        }
      </Stack>
    return returnDisplay
  }
  return (
    <Stack spacing={1} alignItems="flex-start" {...other}>
      {options.map((option) => (ListingDisplay(option, isExpandLevel.level1, isExpandLevel.level1Status)))}
    </Stack>
  );
}

EcommerceFilterCategory.propTypes = {
  filterCategories: PropTypes.string,
  onChangeCategories: PropTypes.func,
  options: PropTypes.array,
  openExpand: PropTypes.func,
  isExpandLevel: PropTypes.object,
  handleCategoryChange: PropTypes.func,
};
