// @mui
import PropTypes from 'prop-types';
import { Stack, Grid, Typography, Box, Button, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import NoItemFound from 'src/layouts/components/NoItemFound';
import { fCurrency } from 'src/utils/formatNumber';

//
import EcommerceCartItem from './EcommerceCartItem';
import EcommerceWishlistItem from './EcommerceWishlistItem';
import EcommerceWishlistItemCard from './EcommerceWishlistItemCard';

import { useAuthContext } from '../../../auth/useAuthContext';
// import Typography from 'src/theme/overrides/Typography';

// ----------------------------------------------------------------------

export default function EcommerceCartList({
  orderID,
  handleRemark,
  products,
  merchantData,
  wishlist = false,
  handleDeleteWishlist,
  setHotelInfoDialog,
  handleVoucherCode,
  parsedData,
}) {
  const isXsUp = useResponsive('down', 'sm');
  const { user } = useAuthContext();
  const userDiscount = user?.AgentInd === 1 ? user?.AgentDiscount || 0 : 0
  return (
    <>
      {isXsUp ? (
        <Box
          gap={1}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          }}
        >
          {products.length > 0 ? (
            products &&
            products.map((product, index) =>
              wishlist ? (
                <EcommerceWishlistItemCard
                  key={product.UserWishlistID ?? index}
                  product={product}
                  wishlist={wishlist}
                  handleDeleteWishlist={handleDeleteWishlist}
                />
              ) : (
                <EcommerceCartItem
                  setHotelInfoDialog={setHotelInfoDialog}
                  key={product.ProductVariationDetailID ?? index}
                  product={product}
                  wishlist={wishlist}
                  merchantData={merchantData}
                  parsedData={parsedData}
                />
              )
            )
          ) : (
            <NoItemFound
              title={
                wishlist ? 'Temporary There is No Item In Wishlist' : 'Temporary There is No Item In Cart'
              }
            />
          )}
          {products.length > 0 && products[0].ProductID !== 3133 && (
            <>
              {/* {products.filter((x) => x.isHotel === 0).length > 0 && (
                <Stack direction="row" alignItems="center" sx={{ py: 1, typography: 'subtitle2' }}>
                  <Grid container>
                    <Grid item sm={5} xs={5}>
                      <Typography variant="subtitle2">Shipping Fees</Typography>
                    </Grid>
                    <Grid item sm={7} xs={7} pl={1} sx={{ textAlign: 'right' }}>
                      <Typography variant="subtitle2">
                        {fCurrency(
                          merchantData?.merchantShippingCost !== undefined
                            ? merchantData?.merchantShippingCost
                            : '0'
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              )} */}

              {userDiscount === 0 && !wishlist && (
                <Stack direction="row" alignItems="center" sx={{ pb: 1, typography: 'subtitle2' }}>
                  <Grid container>
                    <Grid item sm={5} xs={5}>
                      <Typography variant="subtitle2">Voucher Discount</Typography>
                    </Grid>
                    <Grid item sm={7} xs={7} pl={1} sx={{ textAlign: 'right' }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: merchantData?.PromoCodeID === 0 ? 'text.secondary' : 'green' }}
                        onClick={() => orderID === '' && handleVoucherCode(products, true)}
                      >
                        {merchantData?.PromoCodeID === 0 ? 'Enter  Code >' : merchantData?.PromoCode}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              )}

              {!wishlist &&
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    pb: 1,
                    typography: 'subtitle2',
                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  }}
                >
                  <Grid container>
                    <Grid item sm={5} xs={5}>
                      <Typography variant="subtitle2">Remarks</Typography>
                    </Grid>
                    <Grid item sm={7} xs={7} pl={1} sx={{ textAlign: 'right' }}>
                      <TextField
                        hiddenLabel
                        size="small"
                        sx={{ width: '100%' }}
                        placeholder="Remarks"
                        disabled={orderID !== ''}
                        value={merchantData?.Remarks}
                        onChange={(e) => handleRemark(merchantData?.MerchantID, e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              }
            </>
          )}
        </Box>
      ) : (
        <>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              py: 2,
              typography: 'subtitle2',
              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            <Grid container>
              <Grid item sm={8} xs={6}>
                <Typography variant="subtitle2" pl={2}>
                  Item
                </Typography>
              </Grid>

              {!wishlist && (
                <Grid item sm={2} xs={2}>
                  <Typography variant="subtitle2" textAlign="center">Qty</Typography>
                </Grid>
              )}

              <Grid item sm={2} xs={4}>
                <Typography variant="subtitle2" textAlign="center">{!wishlist ? 'Subtotal' : 'Price'}</Typography>
              </Grid>

              {wishlist && (
                <Grid item sm={2} xs={2}>
                  <Typography variant="subtitle2" textAlign="center">Action</Typography>
                </Grid>
              )}
            </Grid>



            {/* {wishlist && <Stack sx={{ width: 36 }} />} */}
          </Stack>
          {products.length > 0 ? (
            products &&
            products.map((product, index) =>
              wishlist ? (
                <EcommerceWishlistItem
                  key={product.UserWishlistID ?? index}
                  product={product}
                  wishlist={wishlist}
                  handleDeleteWishlist={handleDeleteWishlist}
                />
              ) : (
                <EcommerceCartItem
                  setHotelInfoDialog={setHotelInfoDialog}
                  key={product.ProductVariationDetailID ?? index}
                  product={product}
                  wishlist={wishlist}
                  parsedData={parsedData}
                />
              )
            )
          ) : (
            <NoItemFound
              title={
                wishlist ? 'Temporary There is No Item In Wishlist' : 'Temporary There is No Item In Cart'
              }
            />
          )}
          {!wishlist && products[0].ProductID !== 3133 && (
            <>
              {/* {products.filter((x) => x.isHotel === 0).length > 0 && (
                <Stack direction="row" alignItems="center" sx={{ py: 1, typography: 'subtitle2' }}>
                  <Grid container>
                    <Grid item sm={6.5} xs={6.5}>
                    </Grid>

                    <Grid item sm={3.5} xs={3.5}>
                      <Typography variant="subtitle2">Shipping Fees</Typography>
                    </Grid>
                    <Grid item sm={2} xs={2}>
                      <Typography variant="subtitle2">
                        {fCurrency(
                          merchantData?.merchantShippingCost !== undefined
                            ? merchantData?.merchantShippingCost
                            : '0'
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              )} */}

              {userDiscount === 0 && (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pb: 1, pt: 2, typography: 'subtitle2' }}
                >
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={6.5} sm={5.5} >
                      {/* <Typography variant="subtitle2" pl={2}>Item</Typography> */}
                    </Grid>

                    <Grid item xs={3.5} sm={3.5} >
                      <Typography variant="subtitle2" mt={1}>Voucher Discount</Typography>
                    </Grid>

                    <Grid item xs={2} sm={3}>
                      <Button
                        fullWidth
                        sx={{
                          color: merchantData?.PromoCodeID === 0 ? 'text.secondary' : 'green',
                          width: '100%',
                        }}
                        onClick={() => orderID === '' && handleVoucherCode(products, true)}
                      >
                        {merchantData?.PromoCodeID === 0 ? 'Enter  Code >' : merchantData?.PromoCode}
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              )}

              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  pb: 1,
                  pt: 1,
                  typography: 'subtitle2',
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                <Grid container>
                  <Grid item xs={6.5} sm={5.5}>
                    {/* <Typography variant="subtitle2" pl={2}>Item</Typography> */}
                  </Grid>

                  <Grid item xs={1.5} sm={3.5}>
                    <Typography variant="subtitle2" mt={1}>Remarks</Typography>
                  </Grid>

                  <Grid item xs={4} sm={3}>
                    <TextField
                      hiddenLabel
                      size="small"
                      sx={{ width: '100%' }}
                      placeholder="Remarks"
                      disabled={orderID !== ''}
                      value={merchantData?.Remarks}
                      onChange={(e) => handleRemark(merchantData?.MerchantID, e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </>
          )}
        </>
      )
      }
    </>
  );
}

EcommerceCartList.propTypes = {
  products: PropTypes.array,
  wishlist: PropTypes.bool,
  handleDeleteWishlist: PropTypes.func,
  setHotelInfoDialog: PropTypes.func,
  merchantData: PropTypes.object,
  handleVoucherCode: PropTypes.func,
  handleRemark: PropTypes.func,
  orderID: PropTypes.string,
  parsedData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
