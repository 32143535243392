import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom'; // routes

import { paths } from 'src/routes/paths';
// @mui
import { Stack, Dialog, Typography, DialogTitle, DialogContent, Button } from '@mui/material';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

RefreshDialog.propTypes = {
  refreshDialog: PropTypes.bool,
  isToEventPage: PropTypes.bool,
  setRefresh: PropTypes.func,
};

export default function RefreshDialog({ refreshDialog, isToEventPage, setRefresh }) {
  const navigate = useNavigate();

  const pageReloadNavigate = () => {
    setRefresh(false)
    if (window.location.pathname.toLowerCase().includes('hotel/landing')) window.location.reload();
    else{
      navigate(paths.landing);
      setTimeout( window.location.reload() , 2000)

    }
  };

  return (
    <Dialog open={refreshDialog} fullWidth maxWidth="xs">
      <DialogTitle textAlign="center">
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Oops! Something went wrong.
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          p: 5,
        }}
      >
        <Stack alignItems="center" spacing={2}>
          <Iconify icon="line-md:emoji-frown-open" width={200} sx={{ color: 'error.main' }} />
          <Typography textAlign="center" variant="body" mb={2}>
            The prices and availability you are viewing may not be up-to-date. Please refresh the
            page to see the latest prices.
          </Typography>
          <Button
            onClick={() => pageReloadNavigate()}
            size="large"
            color="inherit"
            variant="contained"
          >
            Refresh
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
