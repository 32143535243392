import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, Stack, Typography } from '@mui/material';
// path
import { paths } from 'src/routes/paths';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import ReloadDialog from '../../header/ReloadDialog';

// ----------------------------------------------------------------------

export default function BottomBar({ isOpen, onClick, onCancel, reloadProduct }) {
    return (
        <Box sx={{ position: 'relative', px: 2.5 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '58px', overflow: 'visible' }}>

                <MenuButton text="Order" icon="carbon:document" path={paths.eCommerce.account.orders} />
                <MenuButton text="Shop" icon="iconoir:shop" path={paths.eCommerce.products("Category", 0)} sx={{ marginLeft: 1 }} />

                <Box sx={{ width: 65, mb: 4, ml: 2 }}>
                    <Logo />
                </Box>

                <MenuButton text="Reload" icon="bi:coin" onClick={onClick} />
                <MenuButton text="Account" icon="carbon:user" path={paths.eCommerce.account.personal} />

            </Box>

            <ReloadDialog isOpen={isOpen} onClick={onClick} onCancel={onCancel} reloadProduct={reloadProduct} />
        </Box>
    );
}

BottomBar.propTypes = {
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
    reloadProduct: PropTypes.object,
};

function MenuButton({ path, text, icon, sx, ...others }) {
    return (
        <Link
            component={RouterLink}
            to={path}
            underline="none"
            color="inherit"
            {...others}
            sx={{ ...sx }}
        >
            <Stack spacing={0.5} direction="column" alignItems="center" justifyContent="center">
                <Iconify icon={icon} width={20} />
                <Typography variant='caption' fontSize={13}>{text}</Typography>
            </Stack>
        </Link>
    );
}

MenuButton.propTypes = {
    path: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    sx: PropTypes.object,
};