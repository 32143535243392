import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
// @mui
import {
  Popover,
  TableRow,
  MenuItem,
  TableCell,
  InputBase,
  Typography,
  IconButton,
  Stack, Grid, Box, Button, Card, CardContent
} from '@mui/material';
//  utils
// routes
import { paths } from 'src/routes/paths';
import Image from 'src/components/image';

import TextMaxLine from 'src/components/text-max-line';
import { fCurrency } from 'src/utils/formatNumber';
// components
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { checkAccessCategory } from 'src/utils/helpers';

import { useAuthContext } from '../../../../auth/useAuthContext';
import { addProductCart, updateProductCart } from '../../../../redux/slices/product';
import { useDispatch, useSelector } from '../../../../redux/store';
// ----------------------------------------------------------------------

export default function EcommerceAccountOrdersProduct({ row, onSelectRow, selected, onClickRow }) {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { productCart } = useSelector((state) => state.product);

  const [shopData, setShopData] = useState({ id: 0, name: "" });


  useEffect(() => {
    if (row && row.OrderProductDetail.length > 0) {
      setShopData({ id: row.OrderProductDetail[0].MerchantID, name: row.OrderProductDetail[0].MerchantShopName })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  const handleBuyAgain = () => {
    if (row.OrderProductDetail !== "[]" && row.OrderProductDetail.length > 0) {
      const filterList = []
      const processedPromoData = []

      row.OrderProductDetail.forEach((details) => {
        const oriPrice = details.ProductPrice
        let promoPrice = details.ProductPrice
        let discount = "false"
        let purchaseLimit = details.ProductStockAvailable
        let stockLimit = details.ProductStockAvailable
        let promoID = 0
        let promoTitle = ""
        const promotion = details.CurrentProductPromotion && details.CurrentProductPromotion !== "[]" ? details.CurrentProductPromotion : {}

        if (details.CurrentProductPromotion && details.CurrentProductPromotion !== "[]") {
          const promoData = JSON.parse(details.CurrentProductPromotion).filter((x) => x.ProductVariationDetailID === details.ProductVariationDetailID)
          if (promoData.length > 0 && promoData[0].ProductStockAvailable >= details.ProductQuantity) {
            promoPrice = promoData[0].PromotionPrice
            discount = (promoData[0].ProductDiscount).toString()
            purchaseLimit = promoData[0].ProductPurchaselimit
            stockLimit = promoData[0].ProductStockAvailable
            promoID = promoData[0].PromotionID
            promoTitle = promoData[0].PromotionTitle
          }
        }
        processedPromoData.push({
          ...details,
          UserCartID: 0,
          ProductPromotion: promotion,
          ProductPrice: promoPrice,
          OriProductPrice: oriPrice,
          Discount: discount,
          ProductPurchaselimit: purchaseLimit,
          ProductStockLimit: stockLimit,
          PromotionID: promoID,
          PromotionTitle: promoTitle,
          Remarks: "",
          PromoCode: "",
          PromoCodeID: 0,
          PromoCodeDiscount: 0,
          ACCESSCATEGORYID: checkAccessCategory(details.isHotel, details.isEvent),
        })
      })

      const removeDuplicate = processedPromoData.filter((ele, ind) => ind === processedPromoData.findIndex(elem => elem.MerchantID === ele.MerchantID))

      if (removeDuplicate.length > 0) {
        removeDuplicate.forEach((x) => {
          const filterDetails = processedPromoData.filter((y) => y.MerchantID === x.MerchantID)
          filterList.push({
            ...x,
            cartList: filterDetails
          })
        })
      }
      const obj = {
        merchantLising: filterList,
        cartLising: processedPromoData,
        orderData: null,
        isFromBookLah: false,
        isFromEmporiaApp: false,
      }

      navigate(paths.eCommerce.cart, { state: obj })
    }
  }

  const handleAddToCart = () => {

    if (user && user.UserID) {
      const productCartListing = productCart && productCart[0].ProductCart !== "[]" ? JSON.parse(productCart[0].ProductCart) : []
      const hotelCartListing = productCart && productCart[0].HotelCart !== "[]" ? JSON.parse(productCart[0].HotelCart) : []
      const eventCartListing = productCart && productCart[0].EventCart !== "[]" ? JSON.parse(productCart[0].EventCart) : []

      row.OrderProductDetail.map((data) => {

        let filterData = []
        let startdate = "-"
        let enddate = "-"
        if (data.isEvent === 0 && data.isHotel === 0)
          filterData = productCartListing.filter((x) => x.ProductVariationDetailID === data.ProductVariationDetailID && x.ProductID === data.ProductID)
        if (data.isEvent === 0 && data.isHotel === 1) {
          startdate = moment(data.StartDate).format('YYYY-MM-DD')
          enddate = moment(data.EndDate).format('YYYY-MM-DD')
          filterData = hotelCartListing.filter((x) => x.ProductVariationDetailID === data.ProductVariationDetailID && x.ProductID === data.ProductID
            && moment(x.CheckinDate).format('YYYY-MM-DD') === startdate && moment(x.CheckoutDate).format('YYYY-MM-DD') === enddate)
        }
        if (data.isEvent === 1 && data.isHotel === 0) {
          startdate = moment(data.StartDate).format('YYYY-MM-DD')
          enddate = moment(data.EndDate).format('YYYY-MM-DD')
          filterData = eventCartListing.filter((x) => x.ProductVariationDetailID === data.ProductVariationDetailID && x.ProductID === data.ProductID)
        }

        if (filterData.length > 0)
          dispatch(updateProductCart(user.UserID, filterData[0].UserCartID, 1 + Number(filterData[0].ProductQuantity), data.isHotel, data.isEvent))
        else
          dispatch(addProductCart(user.UserID, data.ProductID, Number(data.ProductQuantity), data.ProductVariationDetailID, 0, startdate, enddate, checkAccessCategory(data.isHotel, data.isEvent)))
        return {}
      })

    } else {
      navigate(paths.loginCover)
    }
  }

  const handleCheckAvailability = () => {
    let isNotAvailable = false
    if (row.OrderProductDetail && row.OrderProductDetail !== "[]" && row.OrderProductDetail.length > 0) {
      if (row.OrderProductDetail.filter((x) => x.isProductDeleted === 1 || x.isVariationDeleted === 1).length > 0)
        isNotAvailable = true
    }

    return isNotAvailable
  }

  return (
    <Card sx={{ my: 2 }}>
      <Stack key={row.OrderName}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          sx={{ py: 0.5, borderBottom: (theme) => `solid 1px ${theme.palette.divider}`, backgroundColor: "#f9f9f9" }}
        >
          <Stack direction="row" alignItems="center" flexGrow={1}>
            <IconButton>
              <Iconify icon="ant-design:shop-filled" />
            </IconButton>
            <Typography variant="subtitle2" >  {shopData.name}  </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent={{ xs: "flex-start", sm: "flex-end" }} flexGrow={1} pr={2} pl={{ xs: 1 }}>

            <Typography variant="subtitle2" pr={0.5}>  {row.OrderName} | </Typography>
            <Iconify icon={
              (row.TrackingStatus === 'Payment Confirm' && "icon-park-outline:transaction-order") ||
              (row.TrackingStatus === 'In Shipping' && 'akar-icons:shipping-box-v2') ||
              (row.TrackingStatus === 'Cancelled' && 'icomoon-free:cancel-circle') ||
              (row.TrackingStatus === 'Completed' && 'mdi:package-variant-closed-delivered') ||
              (row.TrackingStatus === 'Return Refund' && 'mdi:package-variant-closed-delivered') ||
              'grommet-icons:in-progress'}

              color={
                (row.TrackingStatus === 'Completed' && 'success') ||
                (row.TrackingStatus === 'Return Refund' && 'error') ||
                (row.TrackingStatus === 'Cancelled' && 'error') ||
                'text.secondary'
              }
              width={15} sx={{ color: 'text.secondary' }} />
            <Label
              ml={1}
              color={
                (row.TrackingStatus === 'Completed' && 'success') ||
                (row.TrackingStatus === 'In Shipping' && 'warning') ||
                (row.TrackingStatus === 'Return Refund' && 'success') ||
                (row.TrackingStatus === 'Payment Pending' && 'error') ||
                (row.TrackingStatus === 'Cancelled' && 'error') ||
                (row.TrackingStatus === 'Payment Confirm' && 'info') ||
                'default'
              }
            >
              {row.TrackingStatus}
            </Label>
          </Stack>
        </Stack>

        {
          row.OrderProductDetail && row.OrderProductDetail.length > 0 && row.OrderProductDetail.map((product) => (
            <Stack
              hover selected={selected} onClick={onClickRow}
              p={1.5}
              direction="row"
              // alignItems="center"
              sx={{ py: 1, borderBottom: (theme) => `solid 1px ${theme.palette.divider}`, cursor: "pointer" }}
              key={`detail_${product.OrderProductDetailID}`}
            >
              <Image
                src={product.ProductImage}
                sx={{
                  width: 90,
                  height: 90,
                  flexShrink: 0,
                  borderRadius: 1.5,
                  bgcolor: 'background.neutral',
                }}
              />

              <Stack direction='column' pl={1.5} sx={{ width: "100%" }} spacing={0.5}>
                <TextMaxLine line={2} variant="subtitle2" pr={1}  >{product.ProductName}</TextMaxLine>

                {
                  product.ProductID !== 3133 &&
                  <Stack direction='column' justifyContent="center">
                    <Stack direction="row" alignItems="center">
                      <Iconify icon="bx:child" width={16} sx={{ ml: -0.5, mr: 1 }} />
                      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        {product.RelatedName} ({product.RelatedItem})
                      </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center">
                      <Iconify icon="lets-icons:date-fill" width={12} sx={{ mr: 1 }} />
                      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        {' '}
                        {product.CheckinDate113}
                      </Typography>
                    </Stack>
                  </Stack>
                }

                <Stack direction={{ xs: "column", md: "row" }} spacing={1.5} justifyContent="space-between">
                  <Stack direction="column">
                    <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 0, md: 0.5 }}>
                      <Typography variant="caption" sx={{ display: { color: 'grey', border: 0, } }}>Variation: </Typography>
                      <Typography variant="caption" sx={{ color: 'text.secondary', border: 0 }}>{product.ProductVariationValue}  x {product.ProductQuantity}
                      </Typography>
                    </Stack>

                    {product?.ProductCustomization && JSON.parse(product.ProductCustomization).map((customize) => (
                      <Stack key={customize.UserCartCustomizationID} >
                        <Typography variant="caption" sx={{ color: 'text.secondary' }} >
                          + {customize.CustomizationDetails}
                        </Typography>
                      </Stack>
                    ))
                    }
                  </Stack>

                  <Stack direction="row" alignItems="center" justifyContent="flex-end" flexGrow={1} pr={1}>
                    <Stack direction="column" >
                      {
                        product.PromotionID !== 0 &&
                        <Typography variant="caption" sx={{ color: 'text.secondary', border: 0, textDecoration: 'line-through', fontWeight: "bold" }}>{fCurrency(product.ProductVariationPrice ? product.ProductVariationPrice * product.ProductQuantity : "0")}</Typography>
                      }
                      <Typography variant="subtitle2" sx={{ border: 0, color: 'primary.main', fontWeight: "bold" }}>{fCurrency(product.FinalOrderVariationPrice ? product.FinalOrderVariationPrice * product.ProductQuantity : 0)}  </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                {/* <Stack direction="row" pl={0.8}>
                  <Iconify icon={
                    (product.TrackingStatus === 'Payment Confirm' && "icon-park-outline:transaction-order") ||
                    (product.TrackingStatus === 'In Shipping' && 'akar-icons:shipping-box-v2') ||
                    (product.TrackingStatus === 'Cancelled' && 'icomoon-free:cancel-circle') ||
                    (product.TrackingStatus === 'Completed' && 'mdi:package-variant-closed-delivered') ||
                    (product.TrackingStatus === 'Return Refund' && 'mdi:package-variant-closed-delivered') ||
                    'grommet-icons:in-progress'}
                    width={15} sx={{ color: 'text.secondary' }} />
                  <Typography variant='caption' pl={1} sx={{ color: 'text.secondary' }}>{product.TrackingStatus}</Typography>
                </Stack> */}

                {
                  handleCheckAvailability() &&
                  <Stack direction="row"  >
                    <Iconify icon="ph:warning-fill" width={15} sx={{ color: 'red' }} />
                    <Typography variant='caption' pl={1} sx={{ color: 'red' }}>This product is no longer available</Typography>
                  </Stack>
                }

              </Stack>
            </Stack>
          ))
        }
        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          py={1}
        >
          {/* <Iconify icon='iconoir:delivery-truck' width={15} mt={0.5}  sx={{ color: 'text.secondary' }} /> */}
          <Typography variant='subtitle2' pl={1} sx={{ color: 'text.secondary' }}>Tax : </Typography>
          <Typography variant='subtitle2' pl={1} sx={{ color: 'text.secondary' }}>{fCurrency(row.OrderTaxAmount)} </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pb={2}
        >
          <Typography variant='subtitle2'>Order Total : </Typography>
          <Typography variant='body1' pl={1} sx={{ color: "primary.main", fontWeight: "bold" }}>{fCurrency(row.totalAmount)} </Typography>
        </Stack>

        {/* <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pb={2}
        >
          {
            row.TrackingStatus === 'In Purchasing' ?
              <Button variant='contained' size='small' onClick={() => navigate(paths.eCommerce.account.orderDetail(row.OrderID), { state: row.OrderName })}>Proceed Payment</Button>
              :
              <>
                <Button variant='outlined' size='small' disabled={handleCheckAvailability()} onClick={() => handleAddToCart()}>Add To Cart</Button> &nbsp; &nbsp;
                <Button variant='contained' size='small' disabled={handleCheckAvailability()} onClick={() => handleBuyAgain()}>Buy Again</Button> &nbsp; &nbsp;
                <Button variant='outlined' size='small'>Contact Seller</Button>
              </>
          }
        </Stack> */}
      </Stack>
      {/* <TableRow hover selected={selected} onClick={onClickRow} sx={{ cursor: "pointer" }}>
      

        <TableCell sx={{ px: 1 }}> {row.OrderID} </TableCell>

        <TableCell sx={{ px: 1 }}> {row.OrderName} </TableCell>

        <TableCell>{row.CreatedDate}</TableCell>

        <TableCell sx={{ px: 1 }}>
          <InputBase value={fCurrency(row.totalAmount)} readOnly />
        </TableCell>

        <TableCell>
          
        </TableCell>

      </TableRow>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { p: 1, width: 160 },
        }}
      >
        <MenuItem onClick={handleClose}>
          <Iconify icon="carbon:view" sx={{ mr: 1 }} /> View
        </MenuItem>

      </Popover> */}
    </Card>
  );
}

EcommerceAccountOrdersProduct.propTypes = {
  onClickRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.shape({
    CreatedDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    OrderProductDetail: PropTypes.array,
    OrderName: PropTypes.string,
    OrderID: PropTypes.number,
    totalAmount: PropTypes.number,
    OrderTaxAmount: PropTypes.number,
    TrackingStatus: PropTypes.string,
  }),
  selected: PropTypes.bool,
};
