import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.ServerUrl;

const initialState = {
    isLoading: false,
    error: null,
    updateShopDetails: [],
    updateMerchantProfile: [],
    viewMerchantProfile: [],
};

const slice = createSlice({
    name: 'registerMerchant',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // REGISTER MERCHANT SHOP
        UpdatedShopDetails(state, action) {
            state.isLoading = false;
            state.updateShopDetails = action.payload;
        },
        
        // REGISTER MERCHANT PROFILE
        UpdateMerchantProfile(state, action) {
            state.isLoading = false;
            state.updateMerchantProfile = action.payload;
        },

        clearMerchantRegistrationSuccess(state, action) {
            state.isLoading = false;
            state.updateMerchantProfile = [];
        },

        // VIEW MERCHANT PRODUCT
        ViewMerchantProfile(state, action) {
            state.isLoading = false;
            state.viewMerchantProfile = action.payload;
        },

    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function Shop_UpdateDetails(USERID,SHOPNAME, SHOPDESC, SHOPPOSCODE, SHOPCITY, SHOPSTATE, SHOPCOUNTRYID, SHOPCOVERIMAGE, SHOPIMAGE ) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(`${url}User_UpdateShopDetail?USERID=${USERID}&SHOPNAME=${SHOPNAME}&SHOPDESC=${SHOPDESC}&SHOPPOSCODE=${SHOPPOSCODE}&SHOPCITY=${SHOPCITY}&SHOPSTATE=${SHOPSTATE}&SHOPCOUNTRYID=${SHOPCOUNTRYID}&SHOPCOVERIMAGE=${SHOPCOVERIMAGE}&SHOPIMAGE=${SHOPIMAGE}`);
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.UpdatedShopDetails(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function User_UpdateMerchantProfile(USERID,FIRSTNAME, LASTNAME, USEREMAIL, USERGENDER, USERCONTACTNO, USERDOB, USERNRIC,SHOPBANK, SHOPBANKACCOUNTNAME, SHOPBANKACCOUNTNO, SHOPBANKACCOUNTHEADER) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(`${url}User_UpdateMerchantProfile?USERID=${USERID}&FIRSTNAME=${FIRSTNAME}&LASTNAME=${LASTNAME}&USEREMAIL=${USEREMAIL}&USERGENDER=${USERGENDER}&USERCONTACTNO=${USERCONTACTNO}&USERDOB=${USERDOB}&USERNRIC=${USERNRIC}&SHOPBANK=${SHOPBANK}&SHOPBANKACCOUNTNAME=${SHOPBANKACCOUNTNAME}&SHOPBANKACCOUNTNO=${SHOPBANKACCOUNTNO}&SHOPBANKACCOUNTHEADER=${SHOPBANKACCOUNTHEADER}`);
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.UpdateMerchantProfile(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function Product_ItemDetailByMerchantID(MERCHANTID,PROJECTID, PRODUCTPERPAGE, PAGE) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(`${url}Product_ItemDetailByMerchantID?MERCHANTID=${MERCHANTID}&PROJECTID=${PROJECTID}&PRODUCTPERPAGE=${PRODUCTPERPAGE}&PAGE=${PAGE}`);
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.ViewMerchantProfile(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function clearMerchantRegistration() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.clearMerchantRegistrationSuccess());
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}




// ----------------------------------------------------------------------
