import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from "react";
import { Waypoint } from "react-waypoint";
import "./ReactHorizontalDatePicker.css";
// @mui
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
// date-fns
import { addDays, addWeeks, format, isBefore, isSameDay, subDays, subWeeks } from "date-fns";
// hooks
import useResponsive from 'src/hooks/useResponsive';
// components
import { useTheme } from '@mui/material/styles';
import Iconify from 'src/components/iconify/Iconify';

// ----------------------------------------------------------------------

export default function ReactHorizontalDatePicker({ enableDays, enableScroll, selectedDay, availability, currentSelected, enableDaysBefore, pullProductData, }) {
  const theme = useTheme();
  const isSmUp = useResponsive('up', 'sm');
  const [selectedDate, setSelectedDate] = useState(currentSelected);
  const [headingDate, setHeadingDate] = useState(currentSelected);
  const [currentWeek, setCurrentWeek] = useState(currentSelected);
  const [currentDate] = useState(currentSelected);
  const scrollWidth = 200;
  enableScroll = enableScroll || false;
  enableDays = enableScroll === true ? enableDays || 90 : 7;
  const heading_dateFormat = "MMM yyyy";
  const checkDate = subDays(currentDate, enableDaysBefore);
  const combinedDates = { currentDate, checkDate };
  const containerRef = useRef(null);
  const selectedDateRef = useRef(null);

  useEffect(() => {
    scrollToSelectedDate();
  }, [selectedDate]);

  const applyStyles = (day) => {
    const styles = {
      borderRadius: 2,
      width: { xs: 50, md: 80 },
      py: 1,
      paddingTop: "0px",
      border: '1.5px solid rgb(0, 0, 0, 0.15)',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        border: `1.5px solid ${theme.palette.primary.main}`,
      }
    };

    if (isSameDay(day, selectedDate)) {
      styles.border = `1.5px solid ${theme.palette.primary.main}`;
      styles.backgroundColor = theme.palette.primary.main;
      styles.color = '#fff';
    }

    return styles;
  };

  const _handlePosition = (pos, date) => {
    const currentPosition = pos.currentPosition;
    const previousPosition = pos.previousPosition;

    if (previousPosition === "inside" && currentPosition === "above") {
      setHeadingDate(date);
    }
    if (previousPosition === "above" && currentPosition === "inside") {
      setHeadingDate(addDays(date, -1));
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const handleAvailability = (date) => {
    let product = 0
    if (availability && availability.length > 0) {
      availability.filter((x) => x.Date === formatDate(date)).forEach((data) => { product = data.TotalAvailable }
      )
    }
    return product
  }

  const verticalList = () => {
    const _dayFormat = "E";
    const _dateFormat = "dd";
    const _monthFormat = "MMM";
    const _verticalListItems = [];
    const today = new Date();
    let _startDay = new Date(selectedDate);

    if (isSameDay(today, selectedDate) || (isBefore(today, _startDay) && isBefore(today, addDays(_startDay, enableDays)))) {
      _startDay = today;
    }

    for (let i = 0; i < enableDays; i += 1) {
      const _day = format(addDays(_startDay, i), _dayFormat);
      const _date = format(addDays(_startDay, i), _dateFormat);
      const _month = format(addDays(_startDay, i), _monthFormat);

      const day = addDays(_startDay, i);

      _verticalListItems.push(
        <Waypoint
          key={i}
          horizontal
          onPositionChange={pos =>
            // _handlePosition(pos, addDays(_startDay, i))
            _date === 1 ? _handlePosition(pos, addDays(_startDay, i)) : ""
          }
        >
          <Box sx={{ padding: { xs: 0.8, md: 1 } }}>
            {format(addDays(_startDay, i), _dateFormat) === 1 &&
              <Box>
                <Typography variant="h6">{_month}</Typography>
              </Box>
            }

            <Stack
              onClick={() => onDateClick(day)}
              sx={
                applyStyles(addDays(_startDay, i))
              }
              ref={isSameDay(day, selectedDate) ? selectedDateRef : null}
            >
              <Tooltip title={`Available: ${handleAvailability(day)}`}>
                <Stack direction="column" alignItems="center" justifyContent="center" spacing={-0.4}>
                  <Typography variant="h4" fontSize={{ xs: 16, md: 16 }}>{_day}</Typography>
                  <Typography variant="h4" fontSize={{ xs: 14, md: 18 }}>{_date}</Typography>
                  <Typography variant="h6" fontSize={{ xs: 12, md: 14 }}>{_month}</Typography>
                  {/* <Typography variant="caption" fontSize={{ xs: 10, md: 12 }}>{`${handleAvailability(day)}`}</Typography> */}
                </Stack>
              </Tooltip>
            </Stack>
          </Box>
        </Waypoint >
      );
    }

    return (
      <div
        id="container"
        ref={containerRef}
        className={
          enableScroll === true
            ? " datepicker-datelist-scrollable"
            : " datepicker-dateList"
        }
      >
        {_verticalListItems}
      </div>
    );
  };

  const onDateClick = day => {
    setSelectedDate(day);
    setCurrentWeek(day);
    // selectedDay(day);
    pullProductData(day, day,5);

  };

  const nextScroll = () => {
    if (enableScroll) {
      containerRef.current.scrollLeft += scrollWidth;
    } else {
      setCurrentWeek(addWeeks(currentWeek, 2));
    }
  };

  const prevScroll = () => {
    if (enableScroll) {
      containerRef.current.scrollLeft -= scrollWidth;
    } else {
      setCurrentWeek(subWeeks(currentWeek, 1));
    }
  };

  const scrollToSelectedDate = () => {
    if (selectedDateRef.current) {
      selectedDateRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  };

  return (
    <>
      <Stack spacing={{ xs: 0.5, md: 1 }} direction="row" alignItems="center" justifyContent="center" pt={{ xs: 1, md: 2 }} >
        <IconButton
          onClick={() => prevScroll()}
          color="inherit"
          sx={{
            p: { xs: 0, md: 1 },
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
              color: '#fff',
            },
          }}
        >
          <Iconify icon="ic:baseline-chevron-left" />
        </IconButton>

        {verticalList()}

        <IconButton
          onClick={() => nextScroll()}
          color="inherit"
          sx={{
            p: { xs: 0, md: 1 },
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
              color: '#fff',
            }
          }}
        >
          <Iconify icon="ic:baseline-chevron-right" />
        </IconButton>

      </Stack>

      {/* {!isSmUp && */}
      <Stack
        spacing={{ xs: 1, md: 2 }}
        direction={{ xs: "row", md: "row" }}
        alignItems="center"
        justifyContent="center"
        sx={{
          '& > .MuiStack-root': {
            width: { xs: "100%", md: "unset" },
            maxWidth: { xs: "100%", md: "unset" },
          },
        }}
      >
        {/* <Stack
          spacing={0.5}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            // backgroundColor: 'rgb(0, 0, 0, 0.05)',
            border: `1.5px solid ${theme.palette.primary.main}`,
            py: 1,
            px: { xs: 1, md: 7 },
            borderRadius: 8
          }}
        >
          <Typography variant="body2" color={theme.palette.primary.main}>Date:</Typography>
          
          <Typography variant="subtitle2" color={theme.palette.primary.main}>{format(selectedDate, "dd MMM yyy")}</Typography>
        </Stack> */}

        {/* <Stack
          spacing={0.5}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            // backgroundColor: 'rgb(0, 0, 0, 0.05)',
            border: `1.5px solid ${theme.palette.primary.main}`,
            py: 1,
            px: { xs: 1, md: 8 },
            borderRadius: 8
          }}
        >
          <Typography variant="body2" color={theme.palette.primary.main}>Availability:</Typography>

          <Typography variant="subtitle2" color={theme.palette.primary.main}>{handleAvailability(selectedDate)}</Typography>
        </Stack> */}
      </Stack>
      {/* } */}
    </>
  );
}

ReactHorizontalDatePicker.defaultProps = {
  enableDays: 30,
  enableScroll: false,
  availability: [],
  enableDaysBefore: 0,
  currentSelected: new Date(),
};

ReactHorizontalDatePicker.propTypes = {
  enableDays: PropTypes.number,
  enableDaysBefore: PropTypes.number,
  enableScroll: PropTypes.bool,
  selectedDay: PropTypes.func,
  availability: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  pullProductData: PropTypes.func,
  currentSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};