import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Card, Divider, Link, Stack, Typography } from '@mui/material';
// routes
import useResponsive from 'src/hooks/useResponsive';
import { paths } from 'src/routes/paths';
// components
import Logo from 'src/assets/logo.png';
import Image from 'src/components/image';
import Label from 'src/components/label';
import TextMaxLine from 'src/components/text-max-line';
import { ProductPricing, ProductRating } from 'src/sections/_e-commerce/components';

// ----------------------------------------------------------------------

export default function TravelTourItem({ product, isMerchantPage }) {
  const {
    ProductName,
    // ShopState,
    ProductID,
    ProductImage,
    ProductPromotion,
    ProductPrice,
    ProductAvailability,
    MerchantID,
    MerchantShopName,
    ProductCategory,
  } = product;

  const navigate = useNavigate();
  const isMdUp = useResponsive('up', 'md');

  const [selectedVariationPrice, setSelectedVariationPrice] = useState({
    oriPrice: 0,
    promoPrice: 0,
    discount: 0,
    percent: null,
    isDiscount: false,
  });

  useEffect(() => {
    let obj = {
      oriPrice: ProductPrice,
      promoPrice: ProductPrice,
      discount: 0,
      percent: null,
      isDiscount: false,
    };
    if (product) {
      if (ProductPromotion !== undefined && ProductPromotion !== '[]') {
        const PromotionData = JSON.parse(ProductPromotion)[0];

        obj = {
          oriPrice: ProductPrice,
          promoPrice: PromotionData.PromotionPrice,
          discount: PromotionData.ProductDiscount,
          percent: false,
          isDiscount: true,
        };
      }
    }

    setSelectedVariationPrice(obj);
  }, [ProductPromotion, product, ProductPrice]);

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          pt: 1.5,
          pl: 2,
          pr: 1.5,
          top: 0,
          width: 1,
          zIndex: 9,
          position: 'absolute',
        }}
      >
        {selectedVariationPrice.discount ? (
          <Label color="error" sx={{ position: 'absolute', m: 1, top: 0, left: 0, zIndex: 9 }}>
            {selectedVariationPrice.discount} % OFF
          </Label>
        ) : (
          <></>
        )}
      </Stack>

      {/* <Stack sx={{ flexDirection:{xs:"row", sm:"column", md :"column" , } }} > */}
      <Image alt={ProductName} src={ProductImage || Logo} ratio="1/1" sx={{
      }} />

      <Stack>
        {!isMerchantPage && (
          <Label
            color={ProductAvailability === 'out_of_stock' ? 'error' : 'success'}
            sx={{ fontSize: { xs: '10px', sm: '13px' }, borderRadius: 0 }}
          >
            {ProductAvailability === 'out_of_stock' ? 'Out Of Stock' : 'In Stock'}
          </Label>
        )}

        <Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
          <Stack spacing={0.5} sx={{ p: { xs: 1, sm: 2.5 } }}>
            {isMerchantPage ? (
              <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: { xs: '12px', sm: '15px' } }}>
                {ProductCategory}
              </Typography>
            ) : (
              <Link component={RouterLink} to={paths.eCommerce.merchantProduct(MerchantID)} color="inherit">
                <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: { xs: '12px', sm: '15px' } }}>
                  {MerchantShopName}
                </Typography>
              </Link>
            )}

            <Link component={RouterLink} to={paths.eCommerce.product(ProductID)} color="inherit">
              <TextMaxLine
                variant={isMdUp ? "h6" : "subtitle2"}
                sx={{
                  fontSize: { xs: '13.5px', sm: '15px', md: '18px' },
                  overflow: 'hidden',
                  lineHeight: '1.5',
                  height: { xs: '40.5px', sm: '45px', md: '54px' },
                }}
                line={3}
              >
                {ProductName}
              </TextMaxLine>
            </Link>
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack direction="row" alignItems="flex-end" sx={{ pb: { xs: 2.8, sm: 2.5 }, pt: { xs: 1, sm: 2.5 }, pl: { xs: 1, sm: 2.5 } }}>
            <Stack>
              {Number(selectedVariationPrice.oriPrice) ? (
                <ProductPricing
                  price={Number(selectedVariationPrice.promoPrice)}
                  priceSale={Number(selectedVariationPrice.oriPrice)}
                  discount={selectedVariationPrice.discount}
                  isDiscount={selectedVariationPrice.isDiscount}
                  percent={selectedVariationPrice.percent}
                  sx={{ typography: 'h5', flexDirection: { xs: 'column', sm: 'row' }, fontSize: { xs: '13px', sm: '15px', md: '18px' } }}
                />
              ) : (
                <Typography sx={{ typography: 'subtitle2', fontSize: { xs: '12.5px', sm: '15px', md: '18px' } }}>
                  {' '}
                  RM {selectedVariationPrice.oriPrice}{' '}
                </Typography>
              )}

              {isMerchantPage && (
                <ProductRating rating={product.ProductRating} label={`${product.ProductSold} sold`} />
              )}
            </Stack>

          </Stack>
        </Stack>
      </Stack>
      {/* </Stack> */}



    </Card>
  );
}

TravelTourItem.propTypes = {
  product: PropTypes.shape({
    ProductImage: PropTypes.string,
    ProductName: PropTypes.string,
    ProductPromotion: PropTypes.string,
    ProductID: PropTypes.number,
    MerchantID: PropTypes.number,
    MerchantShopName: PropTypes.string,
    ProductAvailability: PropTypes.string,
    ProductPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ProductCategory: PropTypes.string,
    ProductRating: PropTypes.number,
    ProductSold: PropTypes.string,
  }),
  isMerchantPage: PropTypes.bool,
};
