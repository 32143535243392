import PropTypes from 'prop-types';
// @mui
import { LoadingButton } from '@mui/lab';
import useResponsive from 'src/hooks/useResponsive';
import TextMaxLine from 'src/components/text-max-line';
import {
  Typography, Button, Unstable_Grid2 as Grid, InputAdornment, Alert,
  Dialog, DialogContent, DialogTitle, Box, Tooltip, Stack, TextField, DialogActions
} from '@mui/material';
import Iconify from 'src/components/iconify';
// ----------------------------------------------------------------------

export default function EcommerceVoucherList({ voucherInfoDialog, checkPromoData, handleConfirmApplyVoucher, promoDataByMerchant
  , orderListing, promoCode, handlePromoCode, handleCheckPromo, handleDeleteVoucher, handleCloseVoucherDialog }) {

    const isXsUp = useResponsive('down', 'sm');
  return (
    <Dialog open={voucherInfoDialog.open} onClose={handleCloseVoucherDialog} maxWidth='sm' fullWidth >
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <DialogTitle >
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ color: 'text.secondary' }}>
            <Typography variant="h5" > {voucherInfoDialog.title} </Typography>
            <Tooltip title="Close">
              <Button
                endIcon={<Iconify icon="carbon:close-filled" />}
                onClick={handleCloseVoucherDialog}q
              />
            </Tooltip>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 3 }}>
          <TextField
            hiddenLabel
            sx={{ width: "100%", paddingTop: 1, paddingBottom: 2 }}
            placeholder="Discount Code"
            disabled={orderListing !== ""}
            value={promoCode}
            onChange={(e) => handlePromoCode(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {orderListing === "" && <Button onClick={handleCheckPromo}>Apply</Button>}
                </InputAdornment>
              ),
            }}
          />

          {checkPromoData(voucherInfoDialog.merchantID).beenCall && checkPromoData(voucherInfoDialog.merchantID).isValid === 0 && <Alert severity="error" sx={{ width: '100%' }}> Invalid Discount Code </Alert>}
          {checkPromoData(voucherInfoDialog.merchantID).beenCall && checkPromoData(voucherInfoDialog.merchantID).isValid === 1 && <Alert severity="success" sx={{ width: '100%' }}> Valid Discount Code </Alert>}
          {
            checkPromoData(voucherInfoDialog.merchantID).beenCall && checkPromoData(voucherInfoDialog.merchantID).isValid === 1 &&
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Stack
                mt={2}
                direction="row"
                position='relative'
                sx={{
                  width: !isXsUp ? "90%" : '100%',
                  borderRadius: 1,
                  overflow: 'hidden',
                  border: (theme) => `dashed 1px ${theme.palette.divider}`,
                }}
              >
                <Stack
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                  p={0.5}
                  sx={{
                    width: 70,
                    flexShrink: 0,
                    borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
                  }}
                >
                  <Iconify icon="carbon:star" width={25} />
                  <TextMaxLine variant="overline" line={2} sx={{ textAlign: 'center' }}>
                    Up To {checkPromoData(voucherInfoDialog.merchantID).promoData.PromoDiscount} % OFF
                  </TextMaxLine>
                </Stack>
                <Stack pl={1} pt={1} sx={{ flexDirection: { xs: "column", lg: "row" }, alignItems: "center" }} >
                  <Stack>
                    <TextMaxLine variant="overline"  line={1}>
                      {checkPromoData(voucherInfoDialog.merchantID).promoData.PromoCode}
                    </TextMaxLine>
                    <TextMaxLine variant="overline"  line={1} sx={{fontWeight:'normal'}} >
                     Deduct Up to RM{checkPromoData(voucherInfoDialog.merchantID).promoData.MaxDiscount}
                    </TextMaxLine>
                    {/* <Typography>{checkPromoData(voucherInfoDialog.merchantID).promoData.PromoCode}</Typography>
                  <Typography>{checkPromoData(voucherInfoDialog.merchantID).promoData.PromoDescription}</Typography> */}
                  </Stack>
                </Stack>
              </Stack>
                {
                  !isXsUp &&
                  <Stack direction="row" sx={{ textAlign: "center", width: "10%", alignItems: "center" }} >
                    <Button onClick={handleDeleteVoucher}>
                      <Iconify icon="mdi:delete" width={25} />
                    </Button>
                  </Stack>
                }
             
            </Stack>

          }

          <Stack direction="row" mt={2} sx={{ textAlign: "center" }} >
            <LoadingButton color="inherit" type="submit" variant="contained" sx={{ width: "100%" }} onClick={() => handleConfirmApplyVoucher(promoDataByMerchant)} disabled={!(checkPromoData(voucherInfoDialog.merchantID).beenCall && checkPromoData(voucherInfoDialog.merchantID).isValid === 1)}>
              Confirm Apply
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

EcommerceVoucherList.propTypes = {
  voucherInfoDialog: PropTypes.object,
  checkPromoData: PropTypes.func,
  handleConfirmApplyVoucher: PropTypes.func,
  orderListing: PropTypes.string,
  promoCode: PropTypes.string,
  handlePromoCode: PropTypes.func,
  handleCheckPromo: PropTypes.func,
  handleDeleteVoucher: PropTypes.func,
  handleCloseVoucherDialog: PropTypes.func,
  promoDataByMerchant: PropTypes.array,
};
