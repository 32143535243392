import PropTypes from 'prop-types';
// @mui
import { Stack, Typography } from '@mui/material';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

RoomFeatureDetails.propTypes = {
  feature: PropTypes.array,
  showPrice: PropTypes.bool,
};

export default function RoomFeatureDetails({ feature, showPrice = false }) {
  return (
    feature.map((data) =>
      <Stack key={data.ParentFeatureTypeID}>
        <Typography variant='body2' mb={1}>{data.ParentFeatureType}</Typography>
        {
          data.RoomFeatureDetails !== "[]" && JSON.parse(data.RoomFeatureDetails).map((details) =>
            <Stack direction="row" key={details.HotelFeatureID}>
              <Iconify icon="charm:circle-tick" mr={1}/>
              <Typography variant='subtitle2'>{details.FeatureType}</Typography>
            </Stack>
          )
        }
      </Stack>
    )
  )
}