import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// E-COMMERCE
export const EcommerceCartPage = Loadable(
  lazy(() => import('../pages/e-commerce/EcommerceCartPage'))
);
export const EcommerceCheckoutPage = Loadable(
  lazy(() => import('../pages/e-commerce/EcommerceCheckoutPage'))
);

export const EcommerceOrderCompletedPage = Loadable(
  lazy(() => import('../pages/e-commerce/EcommerceOrderCompletedPage'))
);
export const EcommerceWishlistPage = Loadable(
  lazy(() => import('../pages/e-commerce/EcommerceWishlistPage'))
);
export const EcommerceProductPage = Loadable(
  lazy(() => import('../pages/e-commerce/EcommerceProductPage'))
);
export const EcommerceProductsPage = Loadable(
  lazy(() => import('../pages/e-commerce/EcommerceProductsPage'))
);
export const EcommerceMerchantProductPage = Loadable(
  lazy(() => import('../pages/e-commerce/EcommerceMerchantProductPage'))
);
export const EcommerceAccountPersonalPage = Loadable(
  lazy(() => import('../pages/e-commerce/account/EcommerceAccountPersonalPage'))
);
export const EcommerceAccountChildrenPage = Loadable(
  lazy(() => import('../pages/e-commerce/account/EcommerceAccountChildrenPage'))
);
export const EcommerceAccountOrdersPage = Loadable(
  lazy(() => import('../pages/e-commerce/account/EcommerceAccountOrdersPage'))
);
export const EcommerceAccountOrdersDetailPage = Loadable(
  lazy(() => import('../pages/e-commerce/account/EcommerceAccountOrdersDetailPage'))
);
export const EcommerceAccountOrdersDetailPageExt = Loadable(
  lazy(() => import('../pages/e-commerce/account/EcommerceAccountOrdersDetailPageExt'))
);

// TRAVEL
export const TravelAboutPage = Loadable(lazy(() => import('../pages/travel/TravelAboutPage')));
export const TravelBlogPage = Loadable(lazy(() => import('../pages/travel/TravelBlogPage')));
export const TravelCheckoutPage = Loadable(
  lazy(() => import('../pages/travel/TravelCheckoutPage'))
);
export const TravelContactPage = Loadable(lazy(() => import('../pages/travel/TravelContactPage')));
export const TravelLandingPage = Loadable(lazy(() => import('../pages/travel/TravelLandingPage')));
export const TravelOrderCompletedPage = Loadable(
  lazy(() => import('../pages/travel/TravelOrderCompletedPage'))
);
export const TravelPostPage = Loadable(lazy(() => import('../pages/travel/TravelPostPage')));
export const TravelTourPage = Loadable(lazy(() => import('../pages/travel/TravelTourPage')));
export const TravelToursPage = Loadable(lazy(() => import('../pages/travel/TravelToursPage')));

// AUTH
export const LoginBackgroundPage = Loadable(
  lazy(() => import('../pages/auth/LoginBackgroundPage'))
);
export const LoginCoverPage = Loadable(lazy(() => import('../pages/auth/LoginCoverPage')));
export const LoginIllustrationPage = Loadable(
  lazy(() => import('../pages/auth/LoginIllustrationPage'))
);
export const RegisterBackgroundPage = Loadable(
  lazy(() => import('../pages/auth/RegisterBackgroundPage'))
);
export const RegisterCoverPage = Loadable(lazy(() => import('../pages/auth/RegisterCoverPage')));
export const RegisterIllustrationPage = Loadable(
  lazy(() => import('../pages/auth/RegisterIllustrationPage'))
);
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));

// COMMON
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const PaymentPage = Loadable(lazy(() => import('../pages/PaymentPage')));
export const Pricing01Page = Loadable(lazy(() => import('../pages/Pricing01Page')));
export const Pricing02Page = Loadable(lazy(() => import('../pages/Pricing02Page')));
export const SupportPage = Loadable(lazy(() => import('../pages/SupportPage')));
export const RulePage = Loadable(lazy(() => import('../pages/RulePage')));
export const TermPage = Loadable(lazy(() => import('../pages/TermPage')));
export const TreeHouseTerm = Loadable(lazy(() => import('../pages/TreeHouseTerm')));
export const HowToBuy = Loadable(lazy(() => import('../pages/HowToBuy')));



