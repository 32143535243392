import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Stack, Radio, RadioGroup, FormControlLabel } from '@mui/material';

// ----------------------------------------------------------------------

export default function ProductOptionPicker({ value, options, onChange, sx,
  optionLabel,
  optionValue,
  optionStock
}) {
  return (
    <RadioGroup row value={value}
    // onChange={onChange}
    >
      {options.map((option) => (
        <Stack
          key={option[`${optionValue}`]}
          alignItems="center"
          justifyContent="center"
          sx={{
            m: 1,
            px: 1.5,
            height: 44,
            borderRadius: 1,
            position: 'relative',
            typography: 'subtitle2',
            border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
            ...(value === option[`${optionValue}`] && {
              boxShadow: (theme) => `0 0 0 3px #ffab01`
              // boxShadow: (theme) => `0 0 0 2px #ffab01${theme.palette.text.primary}`,
            }),
            color: option.ISAVAILABLE === 0 && 'text.disabled',
            ...sx,
          }}
        >
          {option[`${optionLabel}`]} {
            // optionStock !== undefined && ` ( ${option[`${optionStock}`]} )`
          }

          <FormControlLabel
            value={option[`${optionValue}`]}
            control={<Radio sx={{ display: 'none' }} onClick={onChange} />}
            label=""
            sx={{
              m: 0,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              position: 'absolute',
            }}
            disabled={option.ISAVAILABLE === 0}
          />
        </Stack>
      ))}
    </RadioGroup>
  );
}

ProductOptionPicker.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  sx: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
  optionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
  optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
  optionStock: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
};
