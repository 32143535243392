import PropTypes from 'prop-types';
import moment from 'moment';
// @mui
import { Stack, Avatar, Divider, IconButton, Typography, Grid, Box, Tooltip } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import Logo from 'src/assets/logo.png';
// utils
import { fCurrency } from 'src/utils/formatNumber';
import Label from 'src/components/label';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';
import TextMaxLine from 'src/components/text-max-line';
import { checkDateDiff } from 'src/utils/helpers'

// ----------------------------------------------------------------------

// const CryptoJS = require("crypto-js");

export default function EcommerceCartItem({ product, wishlist, setHotelInfoDialog, parsedData }) {
  const navigate = useNavigate();

  const isSmUp = useResponsive('up', 'sm');

  const handleNavigate = (item) => {
    const { ProductID, isHotel, isTrip } = item;

    if (isHotel === 1) {
      navigate(paths.hotel.list(ProductID));
    }
    // else if (isTrip === 1) {
    //   const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(parsedData), strings.auth);
    //   const convertedText = encodeURIComponent(ciphertext);
    //   navigate(paths.tripPackage.package(convertedText));
    // }
    else {
      // navigate(paths.eCommerce.product(ProductID))
    }
  };

  const isInvalidDate =
    product &&
    product.CheckinDate113 &&
    moment(product.CheckinDate113).format('YYYY-MM-DD') <= moment(new Date()).format('YYYY-MM-DD');
  const customizePrice = product.CartCustomization ? JSON.parse(product.CartCustomization).reduce((subtotal, item) => subtotal + item.CustomizationDetailsPrice, 0) + product.ProductPrice : product.ProductPrice

  const overPreOrder = (product.CheckinDate113 && product?.PreOrderDay > 0) ? checkDateDiff(product.CheckinDate113, new Date()) < product.PreOrderDay - 1 : false

  return (
    <Stack
      sx={{
        py: 2,
        // minWidth: 720,
        overflow: 'hidden',
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
      key={product.UserCartID}
    >
      <Stack direction="row" alignItems="flex-start">
        <Grid container>
          <Grid item sm={8} xs={12} sx={{ display: { xs: 'flex', sm: 'block' } }}>
            <Stack sx={{ flexDirection: 'row' }} alignItems="flex-start" flexGrow={1}>
              <Grid item xs={3.5} sm={2.5}>
                <Image
                  src={product?.ProductImage || Logo}
                  onClick={() => handleNavigate(product)}
                  sx={{
                    width: 80,
                    height: 80,
                    flexShrink: 0,
                    borderRadius: 1.5,
                    bgcolor: 'background.neutral',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Stack spacing={1}>
                  <Stack direction="row" className="col-sm-12">
                    <TextMaxLine
                      line={2}
                      variant="subtitle2"
                      pr={1}
                      sx={{
                        color: isInvalidDate && 'grey',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleNavigate(product)}
                    >
                      {product.ProductName}
                    </TextMaxLine>
                    {product.isHotel === 1 && (
                      <Tooltip
                        title="Click Me For More Resort Information"
                        placement="top-start"
                        onClick={() => setHotelInfoDialog(product)}
                      >
                        <Iconify icon="mdi:information-outline" style={{ color: '#FF5630' }} />
                      </Tooltip>
                    )}
                  </Stack>

                  {/* Layout 1 */}
                  {
                    product.ProductID !== 3133 &&

                    <Stack direction='column'>
                      <Stack direction="row" alignItems="center">
                        <Iconify icon="bx:child" width={24} sx={{ mr: 0.5 }} />
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          {' '}
                          {product.ChildName} ({product.ClassName})
                        </Typography>
                      </Stack>

                      <Stack direction="row" alignItems="center">
                        <Iconify icon="lets-icons:date-fill" width={16} sx={{ ml: 0.6, mr: 1, mt: 0.5 }} />
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          {' '}
                          {product.CheckinDate113}
                        </Typography>
                      </Stack>

                      <Divider sx={{ my: 1.5 , borderStyle: 'dashed'}} />

                      <Stack direction="row">
                        <Typography
                          variant="subtitle2"
                          sx={{ display: { xs: 'contents', color: 'grey', border: 0 } }}
                        >
                          Variation:    {product.ProductVariationValue}{" "} ({fCurrency(product.ProductPrice || '0')})
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'text.secondary', border: 0 }}>
                          {product.Discount !== 'false' && (
                            <Label color="error" sx={{ mb: 0, ml: { xs: 0, sm: 3 } }}>
                              {' '}
                              {(product.Discount !== 'false'
                                ? parseFloat(product.Discount)
                                : 0
                              ).toFixed(2)}{' '}
                              % OFF{' '}
                            </Label>
                          )}
                        </Typography>
                      </Stack>

                      {
                        JSON.parse(product.CartCustomization).map((customize) => (
                          <Stack key={customize.UserCartCustomizationID}>
                            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} >
                              + {customize.CustomizationDetails} {" "} ({fCurrency(customize.CustomizationDetailsPrice || '0')})
                            </Typography>
                            {customize.ISAVAILABLE === 0 &&
                              <Typography variant="subtitle2" sx={{ color: 'red' }} >
                                <Iconify icon="fluent-emoji-flat:warning" mr={1} />
                                Not Available
                              </Typography>
                            }
                          </Stack>
                        ))
                      }
                    </Stack>
                  }

                  {/* <Stack direction="row">
                    <Typography variant="subtitle2" sx={{ display: { xs: 'contents', color: 'grey', border: 0 } }}  >
                      Date :  {product.CheckinDate113}
                    </Typography>
                  </Stack> */}





                  {isInvalidDate && (
                    <Stack direction="row">
                      <Iconify icon="ph:warning-fill" width={18} sx={{ mr: 1, color: 'red' }} />
                      <Typography variant="body2" sx={{ color: 'red', fontSize: '12px' }}>
                        The item is not available because it had exceed cut off order time.
                      </Typography>
                    </Stack>
                  )}
                  {/* {product.isHotel === 1 ? (
                    <>
                      <Stack direction="row">
                        <Iconify icon="lets-icons:date-fill" width={16} sx={{ mr: 1 }} />
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary', fontSize: '12px' }}
                        >
                          {' '}
                          {product.CheckinDate113} - {product.CheckoutDate113} <br /> ({' '}
                          {product.NumberOfNight} night ){' '}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Iconify icon="mdi:guest-room" width={16} sx={{ mr: 1 }} />
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary', fontSize: '12px' }}
                        >
                          {' '}
                          {product.RoomType} Room{' '}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Iconify icon="raphael:parent" width={16} sx={{ mr: 1 }} />
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary', fontSize: '12px' }}
                        >
                          {' '}
                          {product.MaxAdultPax} adult{' '}
                          {product.MaxChildPax > 0 && `, ${product.MaxChildPax} child`}{' '}
                        </Typography>
                      </Stack>
                    </>
                  ) : (
                    <Stack direction="row">
                      <Iconify icon="lets-icons:date-fill" width={16} sx={{ mr: 1 }} />
                      <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary', fontSize: '12px' }}
                      >
                        {' '}
                        {product.CheckinDate113}
                      </Typography>
                    </Stack>
                  )} */}

                  {product.Discount !== 'false' &&
                    product.ProductQuantity > product.ProductPurchaselimit && (
                      <Typography variant="subtitle2" color="red">
                        This promotion allow only {product.ProductPurchaselimit} item per
                        transaction
                      </Typography>
                    )}

                  {product.Discount !== 'false' &&
                    product.ProductQuantity > product.ProductStockLimit && (
                      <Typography variant="subtitle2" color="red">
                        Only {product.ProductStockLimit} Promotion item left{' '}
                      </Typography>
                    )}

                  {/* Layout 2 */}
                  {/* <Stack>
                    <Typography variant="body2" sx={{ color: 'text.secondary', pt: 1, textDecoration: 'underline' }}>
                      Child Details :
                    </Typography>
                    {
                      product.RelatedTypeID === 2 &&
                      <Stack direction='row' pb={1}>
                        <Avatar src={product?.ChildProfileImage} />
                        <Stack direction='column' justifyContent="center" pl={2} >
                          <Typography variant='subtitle2'>{product.ChildName}</Typography>
                          <Typography variant='subtitle2'>{product.ClassName}</Typography>
                        </Stack>
                      </Stack>
                    }

                    <Typography variant="body2" sx={{ color: 'text.secondary', pt: 1, textDecoration: 'underline' }}>
                      Order Details :
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Date :  {product.CheckinDate113}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Variation : {product.ProductVariationValue}
                    </Typography>
                    {
                      JSON.parse(product.CartCustomization).map((customize) => (
                        <Stack key={customize.UserCartCustomizationID}>
                          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} >
                            + {customize.CustomizationDetails} {" "} ({fCurrency(customize.CustomizationDetailsPrice || '0')})
                          </Typography>
                          {customize.ISAVAILABLE === 0 &&
                            <Typography variant="subtitle2" sx={{ color: 'red' }} >
                              <Iconify icon="fluent-emoji-flat:warning" mr={1} />
                              Not Available
                            </Typography>
                          }
                        </Stack>
                      ))
                    }
                  </Stack> */}

                  {/* {product.EventMerchandiseInd === 1 && (
                    <Stack direction="row">
                      <Iconify icon="ph:warning-fill" width={18} sx={{ mr: 1, color: 'red' }} />
                      <Typography variant="caption" sx={{ color: '#d53600' }}>
                        Remark : Kindly note that merchandise purchases can only be collected on the
                        actual event day
                      </Typography>
                    </Stack>
                  )} */}


                  {!isSmUp && (
                    <Grid>
                      <Stack
                        sx={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="subtitle2">x{product.ProductQuantity}</Typography>
                        <Stack sx={{ width: { sm: 120 }, typography: 'subtitle2' }}>
                          {' '}
                          {`RM ${parseFloat(
                            customizePrice || '0'
                          ).toFixed(2)}`}
                          {product.Discount !== 'false' && (
                            <Box
                              component="span"
                              sx={{
                                color: 'text.disabled',
                                textDecoration: 'line-through',
                                fontWeight: 'fontWeightMedium',
                              }}
                            >
                              {fCurrency(product.OriProductPrice ? product.OriProductPrice : '0')}
                            </Box>
                          )}
                        </Stack>

                        {wishlist && (
                          <IconButton>
                            <Iconify icon="carbon:shopping-cart-plus" />
                          </IconButton>
                        )}
                      </Stack>
                    </Grid>
                  )}
                </Stack>
              </Grid>
            </Stack>
          </Grid>
          {isSmUp && (
            <>
              <Grid item sm={2} xs={2}>
                <Stack sx={{ width: 120 }}>
                  <Typography variant="subtitle2">{product.ProductQuantity}</Typography>
                </Stack>
              </Grid>
              <Grid item sm={2} xs={4}>
                <Stack sx={{ width: 120, typography: 'subtitle2', color: isInvalidDate && 'grey' }}>
                  {' '}
                  {fCurrency(customizePrice || '0')}{' '}
                </Stack>
                {product.Discount !== 'false' && (
                  <Box
                    component="span"
                    sx={{
                      color: 'text.disabled',
                      textDecoration: 'line-through',
                      fontWeight: 'fontWeightMedium',
                    }}
                  >
                    {fCurrency(product.OriProductPrice ? product.OriProductPrice : '0')}
                  </Box>
                )}

                {wishlist && (
                  <IconButton>
                    <Iconify icon="carbon:shopping-cart-plus" />
                  </IconButton>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
      {product.ProductQuantity > product.ProductStock && (
        <Typography variant="subtitle2" color="red">
          Insufficient stock for you purchase
        </Typography>
      )}
    </Stack>
  );
}

EcommerceCartItem.propTypes = {
  product: PropTypes.shape({
    ProductVariationDetailID: PropTypes.number,
    EventMerchandiseInd: PropTypes.number,
    UserCartID: PropTypes.number,
    ProductID: PropTypes.number,
    ProductImage: PropTypes.string,
    ProductName: PropTypes.string,
    ProductPrice: PropTypes.number,
    OriProductPrice: PropTypes.number,
    ProductVariationValue: PropTypes.string,
    ProductStock: PropTypes.number,
    ProductQuantity: PropTypes.number,
    MerchantShopName: PropTypes.string,
    MerchantID: PropTypes.number,
    Discount: PropTypes.string,
    ProductPurchaselimit: PropTypes.number,
    ProductStockLimit: PropTypes.number,
    isHotel: PropTypes.number,
    NumberOfNight: PropTypes.number,
    CheckinDate113: PropTypes.string,
    CheckoutDate113: PropTypes.string,
    MaxChildPax: PropTypes.number,
    MaxAdultPax: PropTypes.number,
    RoomType: PropTypes.string,
    isTrip: PropTypes.number,
    PreOrderDay: PropTypes.number,
    CartCustomization: PropTypes.string,
    RelatedID: PropTypes.number,
    RelatedTypeID: PropTypes.number,
    ChildName: PropTypes.string,
    ChildProfileImage: PropTypes.string,
    SchoolName: PropTypes.string,
    ClassName: PropTypes.string,
    IsAvailable: PropTypes.number,
  }),
  wishlist: PropTypes.bool,
  setHotelInfoDialog: PropTypes.func,
  parsedData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
