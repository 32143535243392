// import sum from 'lodash/sum';
// import uniq from 'lodash/uniq';
// import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.ServerUrl;

const initialState = {
  isLoading: false,
  error: null,
  foodDirectory: null,
};

const slice = createSlice({
  name: 'directory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DIRECTORY
    getFoodDirectory(state, action) {
      state.isLoading = false;
      state.foodDirectory = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { clearProduct } = slice.actions;

// ----------------------------------------------------------------------

// type: "Merchant", "Category", "Keyword", "Tag", "Brand", "Status"

export function getFoodDirectory(googleId, googleAPI) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/place/details/json?placeid=${googleId}&fields=name,rating,reviews,photos,formatted_address,formatted_phone_number,opening_hours,user_ratings_total,geometry,url,website,types,photos,types&key=${googleAPI}`,
        {
          // method: 'GET',   
          // withCredentials: true,
          // crossorigin: true,
          // headers: { 'Access-Control-Allow-Origin': '*',  }
          mode: 'no-cors',
        }
      );
      const json = await response.json(); 
      // const json = await response.json();
      // const data = JSON.parse(json)[0];
      // if (data) {
      //   dispatch(slice.actions.getProductsSuccess(JSON.parse(data.ReturnData)));
      // } else {
      //   dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      // }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



