import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
// components
import { useAuthContext } from 'src/auth/useAuthContext';
import Notification from 'src/components/Notification/Notification';
import FormProvider, { RHFSelect, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import { getClasses, getSchool, setChildData } from 'src/redux/slices/general';
import { useDispatch, useSelector } from 'src/redux/store';
import { clearChildAction, getChildProfileByParentID, updateChildProfile } from 'src/redux/slices/profile';
import { paths } from 'src/routes/paths';
import { fData } from 'src/utils/formatNumber';
import { uploader } from 'src/utils/helpers';

// ----------------------------------------------------------------------
export default function ChildrenNewEditForm({ onClose, currentChild, isNewChild, ...other }) {
    const dispatch = useDispatch();
    const { schoolList, classes, } = useSelector((state) => state.general);
    const { childAction, childrenProfile } = useSelector((state) => state.profile);
    // const { childProfile } = useSelector((data) => data.user);

    const { user } = useAuthContext();

    const [notification, setNotification] = useState({
        open: false,
        msg: "",
        type: "success",
    });

    const [filteredClass, setFilteredClass] = useState(null);
    const [uploadImage, setUploadImage] = useState([]);
    const [uploadFile, setFile] = useState(null);
    const [uploadNewFilename, setNewFilename] = useState(null);

    const defaultValues = {
        childName: currentChild?.ChildName || "",
        schoolId: currentChild?.SchoolID || 0,
        classId: currentChild?.ClassID || 0,
        classNumber: currentChild?.ClassNumber || 1,
    }

    const NewReviewSchema = Yup.object().shape({
        childName: Yup.string().required('Child Name is required'),
        schoolId: Yup.number().required('School is required').min(1, "School is required"),
        classId: Yup.number().required('Class is required').min(1, "Class is required"),
        classNumber: Yup.number().required('Class Number is required'),
    });

    const methods = useForm({
        resolver: yupResolver(NewReviewSchema),
        defaultValues,
    });

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;

    const values = watch();

    useEffect(() => {
        dispatch(getSchool());
        dispatch(getClasses());
    }, [dispatch])

    useEffect(() => {
        if (currentChild) {
            setValue("childName", currentChild?.ChildName || "")
            setValue("schoolId", currentChild?.SchoolID || 0)
            setValue("classId", currentChild?.ClassID || 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChild]);


    useEffect(() => {
        if (!currentChild) {
            if (schoolList && schoolList.length > 0) {
                setValue("schoolId", schoolList[0].SchoolID)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolList]);


    useEffect(() => {
        if (!currentChild) {
            if (classes && classes.filter((x) => x.SchoolID === Number(values.schoolId)).length > 0) {
                setValue("classId", classes.filter((x) => x.SchoolID === Number(values.schoolId))[0].ClassID)
            } else {
                setValue("classId", 0)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, no-undef
    }, [values?.schoolId]);

    useEffect(() => {
        const updatedFilteredClass = classes.find((classObj) => classObj.ClassID === Number(values.classId));
        setFilteredClass(updatedFilteredClass);
    }, [values.classId, classes]);

    const numberOfStudentsArray = filteredClass
        ? Array.from({ length: filteredClass.NumberOfPax }, (_, index) => index + 1)
        : [];

    const onSubmit = async (data) => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            const files = uploadImage;
            let image = '';
            if (files.length > 0) {
                files.map(async (file) => {
                    const filename = new Date().valueOf();
                    const extension = file.name.split('.').pop();
                    const newFilename = `${filename}.${extension}`;
                    image = newFilename;
                    setFile(file);
                    setNewFilename(newFilename);

                    dispatch(updateChildProfile(currentChild?.ChildID || 0, data.childName, currentChild?.ParentID || user?.UserID, data.schoolId, data.classId, image, data.classNumber, 1, user?.UserID));
                })
            } else {
                image = (currentChild?.ChildProfileImage || '-').split('/').pop();
                dispatch(updateChildProfile(currentChild?.ChildID || 0, data.childName, currentChild?.ParentID || user?.UserID, data.schoolId, data.classId, image, data.classNumber, 1, user?.UserID))
            }
            reset();
            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (childAction !== null && childAction.length > 0) {
            setNotification({
                open: true,
                msg: isNewChild ? 'Added Successfully' : 'Updated Successfully',
                type: 'success',
            });
            if (uploadFile) {
                uploader([uploadFile], [uploadNewFilename], 'childProfile', childAction[0]?.ChildID);
            }
            dispatch(clearChildAction());
            console.log("Call child data")
            dispatch(getChildProfileByParentID(user?.UserID));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childAction]);

    useEffect(() => {
        if (user && childrenProfile) {
            if (!childrenProfile && childrenProfile.length > 0) {
                dispatch(setChildData(childrenProfile[0].ChildID))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childrenProfile]);

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setUploadImage(acceptedFiles)
                setValue('childProfile', newFile, { shouldValidate: true });
            }
        },
        [setValue]
    );

    return (
        <Dialog fullWidth maxWidth="md" onClose={onClose} {...other}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle sx={{ typography: 'h4', pb: 3 }}>{isNewChild ? "Add New Child" : "Edit Child Detail"}</DialogTitle>

                <DialogContent sx={{ py: 0 }}>
                    <RHFUploadAvatar
                        name="childProfile"
                        maxSize={3145728}
                        onDrop={handleDrop}
                        helperText={
                            <Typography
                                variant="caption"
                                sx={{
                                    mt: 2,
                                    mx: 'auto',
                                    display: 'block',
                                    textAlign: 'center',
                                    color: 'text.secondary',
                                }}
                            >
                                Allowed *.jpeg, *.jpg, *.png, *.gif
                                <br /> max size of {fData(3145728)}
                            </Typography>
                        }
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name="childName" label="Child Name *" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelect native name="schoolId" label="School *">
                                {schoolList.length > 0 && schoolList.map((option) => (
                                    <option key={option.SchoolID} value={option.SchoolID} >
                                        {option.SchoolName}
                                    </option>
                                ))}
                            </RHFSelect>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelect native name="classId" label="Class *">
                                {classes.length > 0 && classes.filter((x) => x.SchoolID === Number(values.schoolId)).map((option) => (
                                    <option key={option.ClassID} value={option.ClassID} >
                                        {option.ClassName}
                                    </option>
                                ))}
                            </RHFSelect>
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <RHFSelect native name="classNumber" label="Class Number*">
                                {numberOfStudentsArray.length > 0 && numberOfStudentsArray.map((option, index) => (
                                    <option key={index} value={option} >
                                        {option}
                                    </option>
                                ))}
                            </RHFSelect>
                        </Grid> */}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={onClose} color="inherit">
                        Cancel
                    </Button>

                    <LoadingButton color="inherit" type="submit" variant="contained" loading={isSubmitting}>
                        Submit
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
            <Notification notification={notification} setNotification={setNotification} />
        </Dialog>
    );
}

ChildrenNewEditForm.propTypes = {
    onClose: PropTypes.func,
    isNewChild: PropTypes.bool,
    currentChild: PropTypes.object,
};
