import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import moment from 'moment';
// @mui
import {
  AppBar,
  Badge,
  Box,
  Container,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Button,
  Typography,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// hooks
import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';

import { Link as RouterLink } from 'react-router-dom';
// date-fns
import {
  subDays,
} from "date-fns";
// utils
import { bgBlur } from 'src/utils/cssStyles';
import { fCurrency } from 'src/utils/formatNumber';
// routes
import { paths } from 'src/routes/paths';
// config
import { HEADER, NAV } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import { EcommerceCartViewSide } from 'src/sections/_e-commerce/view';
import { DialogAnimate } from 'src/components/animate';
//
import HeaderShadow from '../../components/HeaderShadow';
import { NavDesktop, NavMobile, navConfig, BottomBar } from '../nav';

// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// redux
import { getCity, getCountry, getState } from '../../../redux/slices/general';
import { viewProductCart, viewProductWishlist, getReloadProduct, getAvailability } from '../../../redux/slices/product';
// import { clearOrderData } from '../../../redux/slices/order';
// import { getChildProfile } from '../../../redux/slices/user';
import { getUserProfile, getRecheckSenangPay } from '../../../redux/slices/profile';
import { useDispatch, useSelector } from '../../../redux/store';
import AttentionBanner from '../../../sections/_travel/landing/AttentionBanner';
import ReloadDialog from './ReloadDialog';
import FeedbackForm from './FeedbackForm';

// ----------------------------------------------------------------------

export default function Header({ headerOnDark }) {
  const theme = useTheme();
  const isMdUp = useResponsive('up', 'md');
  const isSmUp = useResponsive('up', 'sm');
  const isOffset = useOffSetTop();
  const { user } = useAuthContext();
  const dispatch = useDispatch();

  // Global Variable
  const { productCart, productCartAction, productWishlist, productWishlistAction, reloadProduct } = useSelector(
    (state) => state.product
  );
  const { orderAction } = useSelector((state) => state.order);
  const { userCheckInDate } = useSelector((state) => state.general);
  const { userProfile } = useSelector((state) => state.profile);

  // const checkDateDiff = () => {
  //   const currentDate = userCheckInDate || new Date()
  //   const todayDate = new Date()

  //   // Calculate the difference in time
  //   const timeDifference = Math.abs(currentDate - todayDate);

  //   // Convert time difference from milliseconds to days
  //   const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  //   console.log(dayDifference)
  //   return dayDifference - 1;
  // }

  // const checkDate = subDays(new Date(userCheckInDate), checkDateDiff());
  const combinedDates = { userCheckInDate };

  // Local State
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openReload, setOpenReload] = useState(false);
  const [badgeNumber, setBadgeNumber] = useState('0');
  const [productCartListing, setProductCartListing] = useState([]);
  const [hotelCartListing, setHotelCartListing] = useState([]);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  useEffect(() => {
    if (productWishlistAction.length > 0) {
      dispatch(viewProductWishlist(user?.UserID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productWishlistAction]);

  useEffect(() => {
    if (user && user.UserID) {
      dispatch(viewProductCart(user?.UserID));
      dispatch(viewProductWishlist(user?.UserID));
      // dispatch(getChildProfile(user?.UserID))
      dispatch(getUserProfile('UserProfile', user?.UserID, 0, 999, 1, user?.UserID))
      dispatch(getRecheckSenangPay(user?.UserID))
      dispatch(getReloadProduct(3133, user?.UserID || 0, moment(userCheckInDate || new Date).format("YYYY-MM-DD")));
      dispatch(getAvailability(
        moment(combinedDates).format('YYYY-MM-DD'),
        moment(combinedDates).format('YYYY-MM-DD'),
        0))
    }
    dispatch(getCity());
    dispatch(getState());
    dispatch(getCountry());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user]);

  useEffect(() => {
    if (productCartAction.length > 0 || orderAction) {
      dispatch(viewProductCart(user?.UserID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productCartAction, orderAction]);

  useEffect(() => {
    if (productWishlistAction.length > 0) {
      dispatch(viewProductWishlist(user?.UserID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productWishlistAction]);

  useEffect(() => {
    let cartNumber = '0';

    if (productCart && productCart.length > 0) {
      const HotelCart =
        productCart[0].HotelCart !== '[]' ? JSON.parse(productCart[0].HotelCart) : [];
      const ProductCart =
        productCart[0].ProductCart !== '[]' ? JSON.parse(productCart[0].ProductCart) : [];
      cartNumber = ProductCart.length + HotelCart.length;

      setProductCartListing(ProductCart);
      setHotelCartListing(HotelCart);
      setBadgeNumber(cartNumber === 0 ? '0' : cartNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCart]);

  const ClearOrder = () => {
    console.log("clear data")
    // dispatch(clearOrderData())
    // const obj = {
    //   merchantLising: filterList,
    //   cartLising: processedPromoData,
    //   orderData: null,
    //   isFromBookLah: false,
    //   isFromEmporiaApp: false,
    //   isReload: true,
    //   step: 1
    // };
    // handleCancel()
    // navigate(paths.eCommerce.cart, { state: obj });
  };

  useEffect(() => {
    const onscroll = () => {
      const scrolledTo = window.scrollY + window.innerHeight;
      const isReachBottom = Math.abs(document.body.scrollHeight - scrolledTo) < 1;
      if (isReachBottom) {
        setIsFeedbackOpen(true);
      };
    };
    window.addEventListener("scroll", onscroll);
    return () => {
      window.removeEventListener("scroll", onscroll);
    };
  }, []);

  return (
    <>
      <AppBar color="transparent" sx={{ boxShadow: 'none' }}>
        <Toolbar
          disableGutters
          sx={{
            backgroundColor: '#ffab01',
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_MAIN_DESKTOP,
            },
            transition: theme.transitions.create(['height', 'background-color'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            ...(headerOnDark && {
              color: 'common.white',
            }),
            ...(isOffset && {
              ...bgBlur({ color: theme.palette.background.default }),
              color: 'text.primary',
              height: {
                md: HEADER.H_MAIN_DESKTOP,
              },
            }),
          }}
        >

          <Container sx={{ height: 1, display: 'flex', alignItems: 'center', }}>
            {isMdUp &&
              <Box sx={{ lineHeight: 0, position: 'relative', width: isMdUp ? 70 : 50 }}>
                <Logo />
              </Box>
            }

            {!isMdUp && <NavMobile data={navConfig} />}

            {isMdUp && <NavDesktop data={navConfig} />}

            <Stack
              spacing={isSmUp ? 2 : 1.5}
              flexGrow={1}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"

            >
              {/* <Stack spacing={1} direction="row" alignItems="center">
              <Searchbar />

              <SettingsDrawer />
            </Stack> */}

              {user && user.UserID && (
                <>
                  <Badge
                    badgeContent={productWishlist.length === 0 ? '0' : productWishlist.length}
                    color="info"
                    sx={{
                      '& .MuiBadge-badge': {
                        top: 0,
                        right: 0,
                        transform: 'translate(15%, -15%)',
                      },
                    }}
                  >
                    <IconButton
                      component={RouterLink}
                      to={paths.eCommerce.wishlist}
                      size="small"
                      color="inherit"
                      sx={{
                        p: 0.8,
                        border: isSmUp ? `1px solid ${headerOnDark && !isOffset ? 'rgb(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.2)'}` : 'none',
                      }}
                    >
                      <Iconify icon="carbon:favorite" width={24} />
                    </IconButton>
                  </Badge>

                  <Badge
                    badgeContent={badgeNumber}
                    color="error"
                    sx={{
                      '& .MuiBadge-badge': {
                        top: 0,
                        right: 0,
                        transform: 'translate(15%, -15%)',
                      },
                    }}
                  >
                    <IconButton
                      component={RouterLink}
                      // to={paths.eCommerce.cart}
                      onClick={() => setOpenDrawer(true)}
                      size="small"
                      color="inherit"
                      sx={{
                        p: 0.8,
                        border: isSmUp ? `1px solid ${headerOnDark && !isOffset ? 'rgb(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.2)'}` : 'none',
                      }}
                    >
                      <Iconify icon="carbon:shopping-cart" width={24} />
                    </IconButton>
                  </Badge>
                </>
              )}

              {isSmUp &&
                <IconButton
                  component={RouterLink}
                  to={user ? paths.eCommerce.account.personal : paths.loginCover}
                  size="small"
                  color="inherit"
                  sx={{
                    p: 0.8,
                    zIndex: 1,
                    border: isSmUp ? `1px solid ${headerOnDark && !isOffset ? 'rgb(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.2)'}` : 'none',
                  }}
                >
                  <Iconify icon="carbon:user" width={24} />
                </IconButton>
              }

              {/* {isMdUp &&
                <Button variant="contained" size="medium" onClick={() => setOpenReload(true)} sx={{ borderRadius: 50, py: 1 }}>
                  + Reload{" "}
                </Button>
              } */}

              <Box onClick={() => setOpenReload(true)} pl={{ xs: 1, md: 0 }} sx={{ cursor: 'pointer' }} >
                <Stack direction="row" alignItems="center"
                  sx={{
                    borderLeft: `1px solid ${headerOnDark && !isOffset ? 'rgb(255, 255, 255, 0.5)' : 'rgb(0, 0, 0, 0.4)'}`,
                    pl: 1.5,
                  }}
                >
                  <Iconify icon="bi:coin" width={{ xs: 16, md: 18 }} pr={0.5} />
                  <Typography variant="subtitle2" fontSize={{ xs: 12, md: 14 }}>{fCurrency(userProfile?.MembershipPoint || "0")}</Typography>
                </Stack>
              </Box>

              <Drawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                ModalProps={{ keepMounted: true }}
                anchor="right"
                PaperProps={{
                  sx: {
                    width: { xs: NAV.W_DRAWER, sm: NAV.W_CARTDRAWER },
                    bgcolor: 'background.default',
                  },
                }}
              >
                <Scrollbar>
                  <EcommerceCartViewSide
                    productCart={productCartListing}
                    hotelCart={hotelCartListing}
                    setDrawerClose={() => setOpenDrawer(false)}
                    ClearOrder={ClearOrder}
                  />
                </Scrollbar>
              </Drawer>
            </Stack>

            <ReloadDialog isOpen={openReload} ClearOrder={ClearOrder} onClick={() => setOpenReload(true)} onCancel={() => setOpenReload(false)} reloadProduct={reloadProduct} />
          </Container>
        </Toolbar>

        {isOffset && <HeaderShadow />}

        <AttentionBanner />

        <FeedbackForm isOpen={isFeedbackOpen} onClose={() => setIsFeedbackOpen(false)}/> 
      </AppBar >

      {!isSmUp &&
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: theme.zIndex.appBar - 1,
            boxShadow: '2px 0 10px 0 rgb(0, 0, 0, 0.2)',
            backgroundColor: '#fff'
          }}
        >
          <BottomBar onClick={() => setOpenReload(true)} onCancel={() => setOpenReload(false)} reloadProduct={reloadProduct} />
        </Box>

      }
    </>
  );
}

Header.propTypes = {
  headerOnDark: PropTypes.bool,
};
